// @ts-nocheck
import { Box, Grid, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "../../assets/images/btn-close.png";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputBase from "@mui/material/InputBase";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ArenaRankingComponent from "components/ArenaRankingComponent";

export default function SearchArenaRankingModal({
  open,
  onClose,
  setOpenModalInfo,
  valueScope,
  valueIndex,
  switchCallApi = "",
  paramId,
  isEmployeeList,
  keyword,
  isAwardInven = false,
}) {
  const [value, setValue] = useState("");
  const [displayValue, setDisplayValue] = useState("");
  const [listRank, setListRank] = useState([]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setDisplayValue(value), 700);
    return () => clearTimeout(timeOutId);
  }, [value]);

  useEffect(() => {
    setValue(keyword);
  }, [open]);

  const onChange = (valueSearch = "") => {
    setValue((value) => (value = valueSearch));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setValue("");
      }}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          maxHeight: "95%",
          transform: "translate(-50%, -50%)",
          width: isEmployeeList ? "40%" : "60%",
          bgcolor: "white",
          background: "#302A20",
          boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
          border: "2px solid #D0A674",
          borderRadius: "6.32136px",
          boxSizing: "border-box",
          paddingTop: 2,
          paddingBottom: 2,
          outline: 0,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
          onClick={onClose}
        >
          <img src={CloseIcon} alt='...' />
        </Box>
        <Grid container direction={"column"}>
          <TitleCustomComponent
            title={"Tìm kiếm bảng xếp hạng"}
            fontSize='22px'
            style={{ textAlign: "center", marginBottom: 1 }}
          />
          <Grid item xs paddingLeft={2} paddingRight={2}>
            <Box
              sx={{
                backgroundColor: "rgb(18, 18, 18)",
                color: "rgb(255, 255, 255)",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                borderRadius: "4px",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                position: "sticky",
                top: "80px",
                padding: "2px 4px",
                display: "flex",
                WebkitAlignItems: "center",
                alignItems: "center",
                marginbottom: "16px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  webkitBoxAlign: "center",
                  alignItems: "center",
                  webkitBoxPack: "center",
                  justifyContent: "center",
                  position: "relative",
                  boxSizing: "border-box",
                  webkitTapHighlightColor: "transparent",
                  backgroundColor: "transparent",
                  outline: "0px",
                  border: "0px",
                  margin: "0px",
                  cursor: "pointer",
                  userSelect: "none",
                  verticalAlign: "middle",
                  appearance: "none",
                  textDecoration: "none",
                  textAlign: "center",
                  flex: "0 0 auto",
                  fontSize: "1.5rem",
                  borderRadius: "50%",
                  overflow: "visible",
                  color: "rgb(255, 255, 255)",
                  transition:
                    "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  padding: "10px",
                }}
              >
                <SearchOutlinedIcon style={{ color: "white", width: "100%" }} />
              </Box>
              <InputBase
                sx={{ flex: 1, color: "white", marginTop: "4px" }}
                placeholder='Tìm kiếm'
                inputProps={{
                  "aria-label": "Tìm kiếm",
                }}
                onChange={(e) => onChange(e.target.value)}
                value={value}
              />
              {value ? (
                <Box
                  sx={{
                    display: "inline-flex",
                    webkitBoxAlign: "center",
                    alignItems: "center",
                    webkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    webkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    textDecoration: "none",
                    textAlign: "center",
                    flex: "0 0 auto",
                    fontSize: "1.5rem",
                    borderRadius: "50%",
                    overflow: "visible",
                    color: "rgb(255, 255, 255)",
                    transition:
                      "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    padding: "10px",
                  }}
                  onClick={() => onChange()}
                >
                  <ClearOutlinedIcon
                    style={{ color: "white", width: "100%" }}
                  />
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs>
            <Box sx={{ paddingLeft: 1, paddingRight: 1 }}>
              <ArenaRankingComponent
                refList={null}
                isEmployeeList={isEmployeeList ?? false}
                listRank={listRank}
                setListRank={setListRank}
                isModalCenter={true}
                setOpenModalInfo={setOpenModalInfo}
                paramSearchScope={valueScope}
                isJoinable={true}
                paramSearchIndex={valueIndex}
                paramSearchText={displayValue}
                switchCallApi={switchCallApi}
                paramId={paramId}
                isAwardInven={isAwardInven}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
