import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Modal, Typography, Container } from "@mui/material";
import ArenaIcon from "assets/images/arena/arena-icon.svg";
import Arena1 from "assets/images/arena/arena1-icon.svg";
import InfoIcon from "assets/images/icon-information.svg";
import ArenaComponent from "components/Arena/ArenaComponent";
import ArenaRankingComponent from "components/ArenaRankingComponent";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import InfoUserModal from "components/Modal/InfoUserModal";
import TextComponent from "components/Texts/TextComponent";
import { EVENT_LEADERBOARD } from "config/constants";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListEvents } from "redux/events/events.actions";
import {
  setCompareToFirstInfoUser,
  setIndexInfoUserAction,
} from "redux/users/user.slice";
import { getBorderBox } from "../Home";
import { indexInfoRankUserSelector } from "redux/users/user.selectors";
import { setSrcMusic } from "redux/musics/music.slice";
import { asyncGetMusic } from "redux/users/user.actions";
const backgroundColor = "#1E1E1E";

export default function Arena() {
  const [open, setOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [listRank, setListRank] = useState([]);
  const [listEvents, setListEvents] = useState([]);
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const [id, setId] = useState();
  const [arenaName, setArenaName] = useState("");
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const infoUserSelectDetail = useSelector(indexInfoRankUserSelector);

  useEffect(() => {
    dispatch(asyncGetMusic({ page: "arena" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
    asyncGetListEvent();
  }, []);

  const switchApiEvent = () => {
    let params = {
      type: 2,
      time: 2,
    };
    return getListEvents(params);
  };

  const asyncGetListEvent = async () => {
    await dispatch(switchApiEvent())
      .unwrap()
      .then((originalPromiseResult) => {
        if (originalPromiseResult?.length > 0) {
          setListEvents(originalPromiseResult);
        } else {
          setListEvents([]);
        }
      })
      .catch((rejectedValueOrSerializedError) => {});
  };

  const onShowLeaderboard = (item) => {
    setOpen(true);
    setId(item?.id);
    setArenaName(item?.name);
  };

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor, flex: 1, paddingBottom: 1 }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", margin: 3 }}>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            border: "2px solid #000",
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40%",
              minWidth: "450px",
              bgcolor: "white",
              p: 4,
              background: "#302A20",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
              border: "1px solid #FFEEA3",
              borderRadius: "5px",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "50px",
                display: "flex",
              }}
            >
              <img src={Arena1} width={70} alt="..." />
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  fontFamily: "SVN-Gotham",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "25px",
                  textAlign: "center",
                  textTransform: "uppercase",
                  color: "#FFF8E0",
                  alignSelf: "center",
                }}
              >
                XẾP HẠNG {arenaName}
              </Typography>
            </Box>
            <Button
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                borderBottom: "1px solid #FFEEA3",
                borderLeft: "1px solid #FFEEA3",
                borderRadius: "5px",
                height: "30px",
              }}
            >
              <CloseIcon color="warning" />
            </Button>
            <ArenaRankingComponent
              refList={listRef}
              isEmployeeList={false}
              isJoinable={false}
              listRank={listRank}
              setListRank={setListRank}
              setOpenModalInfo={setOpenModalInfo}
              switchCallApi={EVENT_LEADERBOARD}
              paramId={id}
              isModalCenter={true}
            />
            {infoUserSelectDetail ? (
              <InfoUserModal
                onClose={() => {
                  dispatch(setIndexInfoUserAction(null));
                  dispatch(setCompareToFirstInfoUser(null));
                  setOpenModalInfo(false);
                }}
                onOpen={openModalInfo}
                infoUser={infoUserSelectDetail}
              />
            ) : null}
          </Box>
        </Modal>
        <BoxWrapperComponent
          onClickArena={() => setShowInfo(true)}
          background={undefined}
          isArena={true}
          headerTitle={
            <Box
              sx={{
                display: "grid",
                gap: 0,
                gridTemplateColumns: "repeat(1, 1fr)",
                marginBottom: "20px",
                marginLeft: "15px",
                width: "100%",
                // backgroundImage: `url(${AmbientLight})`,
              }}
            >
              <img src={ArenaIcon} width={"95%"} alt="..." />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TextComponent content={""} />
              </Box>
            </Box>
          }
          headerIcon={undefined}
          borderImageBox={getBorderBox(0)}
          headerBtnRight={undefined}
          bottomLeftBtn={undefined}
          bottomRightBtn={undefined}
          boxStyle={{}}
          // arenaInfo={
          //   <Box display="flex" sx={{ marginRight: "5px" }}>
          //     <img src={InfoIcon} width="20px" alt="..." />
          //     <TextComponent content={"Thông tin"} />
          //   </Box>
          // }
          jointBtn={undefined}
        >
          {
            <Box
              sx={{
                width: "100%",
                overflow: "",
                marginTop: "70px",
              }}
            >
              <Grid
                container
                flexDirection="row"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
                spacing={3}
              >
                {listEvents.map((item, i) => {
                  return (
                    <Grid item key={i}>
                      <ArenaComponent
                        title={item?.name}
                        icon={item?.image}
                        applicants={item?.participants}
                        joined={item?.joined}
                        bg_color1={item?.codeColor}
                        bg_color2={"#352E21"}
                        bd_color={"rgba(0, 0, 0, 0.5)"}
                        tooltip={item?.rule}
                        onClick={() => onShowLeaderboard(item)}
                        id={item?.id}
                        object={"Thành viên"}
                        revenueMin={item.revenueMin}
                        circleAvatar={item?.circleAvatar}
                        circleFrame={item?.circleFrame}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          }
        </BoxWrapperComponent>
      </Box>
    </Container>
  );
}
