import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import IconInfo from "../../assets/images/icon-information.svg";
import Arena1Icon from "../../assets/images/arena/arena1-icon.svg";
import ArenaHousehold from "../../assets/images/arena/arena-household.svg";
import ArenaLeaderboad from "../../assets/images/arena/arena-leaderboard.svg";
import VIPIcon from "../../assets/images/arena/arena-vip-icon.svg";
import { Link } from "react-router-dom";

export default function ArenaComponent({
  title,
  icon,
  applicants,
  joined,
  bg_color1,
  bg_color2,
  bd_color,
  tooltip,
  onClick,
  id,
  object,
  revenueMin,
  circleAvatar,
  circleFrame,
}) {
  return (
    <Box
      sx={{
        display: "grid",
        gap: 0,
        gridAutoRows: "1fr",
        height: "220px",
        width: "350px",
        overflow: "hidden",
        boxSizing: "border-box",
        border: `3px solid ${bd_color}`,
        boxShadow: "0px 8px 1px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        marginBottom: "20px",
      }}
    >
      <Box sx={{ backgroundColor: bg_color1, gridRow: "span 3" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: joined ? "space-between" : "end",
            margin: "5px",
          }}
        >
          {joined ? (
            <Tooltip
              title={`Bạn đang tham gia ${title}`}
              arrow
              placement="top-end"
            >
              <div style={{position: "relative"}}>
                <img src={circleAvatar} alt="..." width={20} />
                <img src={circleFrame} alt="..." width={20} style={{position: "absolute", top: "2px"}}/>
              </div>
            </Tooltip>
          ) : null}
          <Tooltip title={tooltip} arrow placement="bottom-end">
            <img src={IconInfo} alt="..." style={{}} />
          </Tooltip>
        </Box>
        <Link to={`/events/${id}`}>
          <Box
            sx={{
              display: "grid",
              gap: 0,
              gridTemplateColumns: "repeat(1, 1fr)",
              height: "132px",
            }}
          >
            <Box sx={{ height: "100px", overflow: "hidden" }}>
              <img
                src={icon === undefined ? Arena1Icon : icon}
                height={"100%"}
                // width={"110px"}
                style={{ margin: "auto" }}
                alt="..."
              />
            </Box>
            <Box
              sx={{
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: "SVN-Gotham",
                  color: "white",
                  textAlign: "center",
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
      <Box
        sx={{
          backgroundColor: bg_color1,
          gridColumn: "span 1",
          opacity: 0.6,
        }}
      >
        <Box sx={{ display: "grid", gridAutoColumns: "1fr", height: "100%" }}>
          <Box
            sx={{
              gridColumn: "span 4",
              gridRow: "1",
              display: "flex",
              paddingLeft: "10px",
            }}
          >
            <img src={ArenaHousehold} width={25} alt="..." />
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 700,
                fontFamily: "SVN-Gotham",
                color: "white",
                textAlign: "center",
                alignSelf: "center",
                marginLeft: "5px",
                textTransform: "lowercase",
              }}
            >
              {applicants} {object} {`(${revenueMin})`}
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: "span 1",
              gridRow: "1",
              alignSelf: "center",
              justifySelf: "end",
              marginRight: "10px",
            }}
          >
            <div onClick={onClick} style={{ cursor: "pointer" }}>
              <img src={ArenaLeaderboad} width={30} alt="..." />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
