import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "api";
import {
  awardInven,
  detailInven,
  exchange,
  exchangeDetail,
  getCurrency,
  listInven,
  scheduleUsage,
} from "api/inventory";
import { setCurrency, setDetailInven, setListInven } from "./inven.slice";

export const getListInven = createAsyncThunk(
  "inven/list",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await listInven(params);
      const { data, success } = response.data;
      if (success) {
        dispatch(setListInven(data));
        return data;
      }
      return null;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getDetailInven = createAsyncThunk(
  "inven/:id",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await detailInven(id);
      const { data, success } = response.data;
      if (success) {
        dispatch(setDetailInven(data));
        return data;
      }
      return null;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const postAwardInven = createAsyncThunk(
  "inven/awarded",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await awardInven(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncExchangeDetail = createAsyncThunk(
  "exchange",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await exchangeDetail(id);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
      return [];
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncExchange = createAsyncThunk(
  "exchange",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await exchange(params);
      const { success } = response.data;
      return success;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncScheduleUsage = createAsyncThunk(
  "schedule/usage",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await scheduleUsage(params);
      const { success } = response.data;
      return success;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchUserCurrency = createAsyncThunk(
  "currency",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCurrency();
      const { data, success } = response.data;
      if (success) {
        dispatch(setCurrency(data));
        return data;
      }
      return null;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
