import { Box, Container, Grid } from "@mui/material";
import BorderImageBlue from "assets/images/border-blue.png";
import BorderImage from "assets/images/icon-border-1.png";
import IconEvent from "assets/images/icon-event.svg";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";

import SampleEventComponent from "components/event/SampleEventComponent";
import {
  TIME_EVENT_HAPPENNING,
  TYPE_EVENT,
  TIME_EVENT_UPCOMING,
  TIME_EVENT_ACCOMPLISHED,
} from "config/constants";
import { hoverBgBlue } from "config/styles/common";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSrcMusic } from "redux/musics/music.slice";
import { asyncGetMusic } from "redux/users/user.actions";

export default function Event() {
  const backgroundColor = "#1E1E1E";
  const dispatch = useDispatch();
  const paramEvent = [
    {
      type: TYPE_EVENT,
      time: TIME_EVENT_HAPPENNING,
    },
    {
      type: TYPE_EVENT,
      time: TIME_EVENT_UPCOMING,
    },
    {
      type: TYPE_EVENT,
      time: TIME_EVENT_ACCOMPLISHED,
    },
  ];
  const getHeaderTitleBox = (index) => {
    switch (index) {
      case 0:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"SỰ KIỆN ĐANG DIỄN RA"} />
          </Box>
        );
      case 1:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"SỰ KIỆN SẮP DIỄN RA"} />
          </Box>
        );
      default:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"SỰ KIỆN ĐÃ KẾT THÚC"} />
          </Box>
        );
    }
  };

  const getBorderBox = (index) => {
    switch (index) {
      case 0:
        return BorderImage;
      case 1:
        return BorderImageBlue;
      default:
        return BorderImage;
    }
  };

  useEffect(() => {
    dispatch(asyncGetMusic({ page: "events" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor, flex: 1, paddingBottom: 1 }}
    >
      <Grid container columnSpacing={3}>
        {paramEvent.map((params, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              onClickArena={undefined}
              headerTitle={getHeaderTitleBox(index)}
              headerIcon={<img src={IconEvent} alt="..." />}
              borderImageBox={getBorderBox(index)}
              headerBtnRight={undefined}
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              styleHoverBg={index === 1 ? hoverBgBlue : {}}
              background={undefined}
              isArena={false}
              arenaInfo={undefined}
              jointBtn={false}
            >
              <SampleEventComponent paramsQuery={params} />
            </BoxWrapperComponent>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
