import { Box } from "@mui/material";
import React from "react";
import TextComponent from "./Texts/TextComponent";
import TitleCustomComponent from "./Texts/TitleCustomComponent";
import BgPercent from "../assets/images/bg-box-percent.png";

export const DetailBoxSevenComponent = (props) => {
  const { icon, percent, title, money, widthIcon } = props;
  return (
    <Box
      sx={{
        bgcolor: "#514533",
        width: "100%",
        height: "180px",
        border: " 2.85208px solid rgba(0, 0, 0, 0.52)",
        boxShadow: "0px 7.60555px 0.950693px rgba(0, 0, 0, 0.15)",
        borderRadius: "7.60555px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "43px",
          height: "43px",
          backgroundImage: `url(${BgPercent})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          right: 2,
          top: 2,
        }}
      >
        <TextComponent
          content={`${percent}%`}
          styleText={{
            textAlign: "center",
            fontSize: "14px",
            color: "#5A3A1A",
            fontWeight: 700,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "75%",
        }}
      >
        <img src={icon} alt="..." style={{ width: widthIcon ?? "auto" }} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "8px",
          left: 0,
          right: 0,
          display: "grid",
          justifyContent: "center",
        }}
      >
        <TitleCustomComponent
          title={`${title ?? ""}`}
          style={{ textAlign: "center" }}
        />
        <TextComponent
          content={`${money ?? ""}`}
          styleText={{
            fontSize: "14px",
            fontFamily: "sans-serif",
            fontWeight: "400",
            color: "#FFFFFF",
            letterSpacing: "1.1px",
            lineHeight: "18px",
            textAlign: "center",
          }}
        />
      </Box>
    </Box>
  );
};
