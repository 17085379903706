import { Box, Container, Grid, Typography } from "@mui/material";
import BackgroundNvImage from "assets/images/background-nv.svg";
import BackgroundImage from "assets/images/background.svg";
import CupIcon from "assets/images/cup.svg";
import FacebookIcon from "assets/images/facebook.png";
import InfoIcon from "assets/images/info.svg";
import SaveIcon from "assets/images/save.png";
import {
  default as IconWarning,
  default as WarningIcon,
} from "assets/images/warning-icon.png";
import ArenaRankingComponent from "components/ArenaRankingComponent";
import { AvatarRank } from "components/AvatarRank";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { colors } from "config/colors/colors";
import { STAFF } from "config/constants";
import {
  configIndexsSelector,
  indexInfoRankUserSelector,
  rankCurrentSelector,
  userSelector,
} from "redux/users/user.selectors";
import { SelectByScope, scrollParentToChild } from "utils/common";
import { getBorderBox } from "../Home";
import { useEffect, useRef, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FacebookShareButton } from "react-share";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SearchArenaRankingModal from "components/Modal/SearchArenaRankingModal";
import InfoUserModal from "components/Modal/InfoUserModal";
import {
  setCompareToFirstInfoUser,
  setIndexInfoUserAction,
} from "redux/users/user.slice";
import SelectItemComponent from "components/Selects/SelectItemComponent";
import { setSrcMusic } from "redux/musics/music.slice";
import { setMessageError, setOpen } from "redux/popup/popup.slice";
import { asyncGetConfigIndex, asyncGetMusic } from "redux/users/user.actions";
import "../../../config/styles/jump.css";
import * as htmlToImage from "html-to-image";

export default function Profile() {
  const profileUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const [listRank, setListRank] = useState([]);
  const rankCurrent = useSelector(rankCurrentSelector);
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [valueScope, setValueScope] = useState(SelectByScope[0].value);
  const [valueIndex, setValueIndex] = useState(1);
  const infoUserSelectDetail = useSelector(indexInfoRankUserSelector);
  const SelectByIndex = useSelector(configIndexsSelector);
  const scrollIndexMyRank = () => {
    const findIdUser = listRank.findIndex(
      (value) => value.id === profileUser?.id
    );
    if (findIdUser === -1) {
      listRef?.current?.props?.next(true, true);
      return;
    }

    if (
      listRef?.current &&
      listRef?.current?.el &&
      listRef?.current?.el?.children?.length > 0
    ) {
      const element =
        listRef?.current?.el?.children[findIdUser > 3 ? findIdUser - 3 : 0];
      if (element) {
        scrollParentToChild(listRef?.current?.el, element);
      }
    }
  };
  const domEl = useRef(null);

  const htmlImageAvatar = () => {
    return (
      <BoxWrapperComponent
        jointBtn={false}
        onClickArena={undefined}
        arenaInfo={undefined}
        background={
          profileUser?.type === STAFF ? BackgroundNvImage : BackgroundImage
        }
        htmlRef={domEl}
        headerTitle={
          <Box
            sx={{
              display: "grid",
              gap: 0,
              gridTemplateColumns: "repeat(1, 1fr)",
            }}
          >
            <Typography
              style={{
                fontSize: 10,
                fontWeight: 700,
                fontFamily: "SVN-Gotham",
                color: "white",
                textTransform: "uppercase",
              }}
            >
              {profileUser?.rank?.name ?? ""}
            </Typography>
            <Typography
              style={{
                fontSize: 20,
                fontWeight: 700,
                fontFamily: "SVN-Gotham",
                color: "white",
              }}
            >
              {profileUser?.name ?? ""}
            </Typography>
          </Box>
        }
        headerIcon={
          <img
            src={profileUser?.rank?.icon ?? IconWarning}
            width={50}
            alt="..."
          />
        }
        borderImageBox={getBorderBox()}
        headerBtnRight={undefined}
        bottomLeftBtn={
          <FacebookShareButton
            url={profileUser?.rank?.avatar ?? ""}
            quote={"quat"}
            hashtag={"#OBD"}
          >
            <img src={FacebookIcon} width={100} alt="..." />
          </FacebookShareButton>
        }
        bottomRightBtn={
          <Box onClick={handleDownload} sx={{ cursor: "pointer" }}>
            <img src={SaveIcon} width={130} alt="..." />
          </Box>
        }
        boxStyle={{
          borderImageSlice: "21 21",
          borderImageWidth: "20px",
          borderImageOutset: "11px",
          borderStyle: "solid",
          position: "relative",
          borderRadius: "9px",
          border: "1px solid transparent",
        }}
        isArena={false}
      >
        <div id="avatar">
          <Box
            sx={{
              margin: "auto",
              padding: "auto",
            }}
          >
            <img
              src={profileUser?.rank?.avatar ?? ""}
              style={{
                objectFit: "inherit",
                maxHeight: 520,
                margin: "auto",
              }}
              className="up-down"
              alt="..."
            />
          </Box>
        </div>
      </BoxWrapperComponent>
    );
  };

  function filter(node) {
    if (node.className) {
      return !node.className.includes("download-avatar");
    }
    return true;
  }

  const handleDownload = async () => {
    const dataUrl = await htmlToImage.toPng(domEl.current, { filter: filter });
    const link = document.createElement("a");
    link.download = "rank-user.png";
    link.href = dataUrl;
    link.click();
  };

  useEffect(() => {
    dispatch(asyncGetMusic({ page: "profile" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: colors.bg_black,
          flex: 1,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Grid container columnSpacing={3} sx={{}}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            {htmlImageAvatar()}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              arenaInfo={undefined}
              isArena={false}
              background={undefined}
              headerTitle={
                <Box paddingX={1}>
                  <Link to="/next-rank">
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        fontFamily: "SVN-Gotham",
                        color: "white",
                      }}
                    >
                      THÔNG TIN
                    </Typography>
                  </Link>
                </Box>
              }
              headerIcon={<img src={InfoIcon} alt="..." />}
              borderImageBox={getBorderBox(1)}
              headerBtnRight={undefined}
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              boxStyle={{ maxHeight: "500px" }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: "repeat(2, 1fr)",
                  overflow: "scroll",
                  maxHeight: 520,
                  padding: "10px",
                }}
              >
                <Box
                  sx={{
                    margin: "auto",
                  }}
                >
                  {profileUser?.rank?.circleAvatar ? (
                    <AvatarRank
                      srcAvatar={profileUser?.rank?.circleAvatar ?? ""}
                      circleFrame={profileUser?.rank?.circleFrame ?? ""}
                      isStaff={profileUser?.type === STAFF}
                      isHousehold={false}
                    />
                  ) : (
                    <div style={{ position: "relative" }}>
                      <img src={IconWarning} width={"100px"} alt="..." />
                    </div>
                  )}
                  <Typography
                    style={{
                      fontSize: 17,
                      fontWeight: 700,
                      fontFamily: "SVN-Gotham",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {profileUser?.name ?? ""}
                  </Typography>
                  <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item display="flex">
                      <img
                        src={profileUser?.rank?.icon ?? ""}
                        width={20}
                        alt="..."
                      />
                      <Typography
                        color={"#59A6FD"}
                        style={{
                          marginLeft: 5,
                          fontFamily: "SVN-Gotham",
                          color: "white",
                        }}
                      >
                        {profileUser?.rank?.name ?? ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container sx={{}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Điện thoại
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {profileUser?.phone ?? ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Email:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {profileUser?.email ?? ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Sinh nhật:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {profileUser?.dob ?? ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {profileUser?.household?.name ? (
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontFamily: "Be-Vietnam-Pro",
                              color: "#9C7F5C",
                              fontSize: 16,
                            }}
                          >
                            Hộ kinh doanh:
                          </Typography>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Grid item display="flex">
                              <img
                                src={
                                  profileUser?.household?.rank?.icon ??
                                  WarningIcon
                                }
                                width={20}
                                alt="..."
                              />
                              <Link to={{ pathname: "/household" }}>
                                <Typography
                                  sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    fontWeight: 700,
                                    fontFamily: "Be Vietnam Pro",
                                    color: "#DBB487",
                                    fontSize: 16,
                                    marginLeft: 1,
                                  }}
                                >
                                  {profileUser?.household?.name ?? ""}
                                </Typography>
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : profileUser?.type !== 3 ? (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Hộ kinh doanh:
                        </Typography>
                      ) : (
                        <Link to={{ pathname: "/ranking-all" }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontFamily: "Be-Vietnam-Pro",
                              fontSize: 14,
                              cursor: "pointer",
                              display: "inline-block",
                              fontStyle: "normal",
                              color: "#FFEEA3",
                              textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                              flex: "none",
                              lineHeight: "16px",
                              order: 0,
                              flexGrow: 0,
                              boxShadow: "0px 2px 0px 0px #00000040",
                              background:
                                "radial-gradient(50% 50% at 50% 50%, #564934 0%, #362F24 100%), linear-gradient(89.9deg, rgba(204, 90, 41, 0.7) 0.08%, rgba(246, 175, 26, 0.7) 99.91%)",
                            }}
                          >
                            Hộ kinh doanh
                          </Typography>
                        </Link>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {profileUser?.system?.name ? (
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontFamily: "Be-Vietnam-Pro",
                              color: "#9C7F5C",
                              fontSize: 16,
                            }}
                          >
                            Hệ thống:
                          </Typography>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Grid item display="flex">
                              <img
                                src={
                                  profileUser?.system?.rank?.icon ?? WarningIcon
                                }
                                width={20}
                                alt="..."
                              />
                              <Link to={{ pathname: "/system" }}>
                                <Typography
                                  sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    fontWeight: 700,
                                    fontFamily: "Be Vietnam Pro",
                                    color: "#DBB487",
                                    fontSize: 16,
                                    marginLeft: 1,
                                  }}
                                >
                                  {profileUser?.system?.name ?? ""}
                                </Typography>
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : profileUser?.type !== 3 ? (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Hệ thống:
                        </Typography>
                      ) : (
                        <Link to={{ pathname: "/ranking-all" }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontFamily: "Be-Vietnam-Pro",
                              fontSize: 14,
                              cursor: "pointer",
                              display: "inline-block",
                              fontStyle: "normal",
                              color: "#FFEEA3",
                              textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                              flex: "none",
                              lineHeight: "16px",
                              order: 0,
                              flexGrow: 0,
                              boxShadow: "0px 2px 0px 0px #00000040",
                              background:
                                "radial-gradient(50% 50% at 50% 50%, #564934 0%, #362F24 100%), linear-gradient(89.9deg, rgba(204, 90, 41, 0.7) 0.08%, rgba(246, 175, 26, 0.7) 99.91%)",
                            }}
                          >
                            Hệ thống
                          </Typography>
                        </Link>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {profileUser?.community?.name ? (
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontFamily: "Be-Vietnam-Pro",
                              color: "#9C7F5C",
                              fontSize: 16,
                            }}
                          >
                            Cộng đồng
                          </Typography>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Grid item display="flex">
                              <img
                                src={
                                  profileUser?.community?.rank?.icon ??
                                  WarningIcon
                                }
                                width={20}
                                alt="..."
                              />
                              <Link to={{ pathname: "/community" }}>
                                <Typography
                                  sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    fontWeight: 700,
                                    fontFamily: "Be Vietnam Pro",
                                    color: "#DBB487",
                                    fontSize: 16,
                                    marginLeft: 1,
                                  }}
                                >
                                  {profileUser?.community?.name ?? ""}
                                </Typography>
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : profileUser?.type !== 3 ? (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Cộng đồng
                        </Typography>
                      ) : (
                        <Link to={{ pathname: "/ranking-all" }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontFamily: "Be-Vietnam-Pro",
                              fontSize: 14,
                              cursor: "pointer",
                              display: "inline-block",
                              fontStyle: "normal",
                              color: "#FFEEA3",
                              textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                              flex: "none",
                              lineHeight: "16px",
                              order: 0,
                              flexGrow: 0,
                              boxShadow: "0px 2px 0px 0px #00000040",
                              background:
                                "radial-gradient(50% 50% at 50% 50%, #564934 0%, #362F24 100%), linear-gradient(89.9deg, rgba(204, 90, 41, 0.7) 0.08%, rgba(246, 175, 26, 0.7) 99.91%)",
                            }}
                          >
                            Cộng đồng
                          </Typography>
                        </Link>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridAutoColumns: "1fr",
                          margin: "0 20px 0 20px ",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundImage: `linear-gradient(to right, #ffffff, #f7eaf4, #f9d3da, #f4bfb2, #dbb487)`,
                            clipPath: `polygon(100% 95%, 0% 100%, 100% 100%)`,
                            marginBottom: "10px",
                            gridColumn: "span 3",
                            gridRow: "1",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            gridRow: "1",
                            gridColumn: "span 2",
                            fontWeight: 700,
                            fontFamily: "SVN-Gotham",
                            color: "#FFF8E0",
                            fontSize: 16,
                            textAlign: "center",
                          }}
                        >
                          CHỈ SỐ
                        </Typography>
                        <Box
                          sx={{
                            backgroundImage: `linear-gradient(to left, #ffffff, #f7eaf4, #f9d3da, #f4bfb2, #dbb487)`,
                            clipPath: `polygon(0 95%, 0% 100%, 100% 100%)`,
                            marginBottom: "10px",
                            gridColumn: "span 3",
                            gridRow: "1",
                          }}
                        ></Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Doanh số tích lũy{" "}
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {profileUser?.indexes?.revenueReceived ?? 0}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Doanh số xác nhận
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {profileUser?.indexes?.revenueConfirmed > 0
                            ? profileUser?.indexes?.revenueConfirmed
                            : 0}{" "}
                          (
                          {profileUser?.indexes?.revenueConfirmed > 0
                            ? (
                                (profileUser?.indexes?.revenueConfirmed /
                                  profileUser?.indexes?.revenueReceived) *
                                100
                              ).toFixed(2)
                            : 0}
                          %)
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Doanh số hoàn
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {profileUser?.indexes?.revenueReturned > 0
                            ? profileUser?.indexes?.revenueReturned
                            : 0}{" "}
                          (
                          {profileUser?.indexes?.revenueReturned > 0
                            ? (
                                (profileUser?.indexes?.revenueReturned /
                                  profileUser?.indexes?.revenueReceived) *
                                100
                              ).toFixed(2)
                            : 0}
                          %)
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Doanh số hủy
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {profileUser?.indexes?.revenueCanceled > 0
                            ? profileUser?.indexes?.revenueCanceled
                            : 0}{" "}
                          (
                          {profileUser?.indexes?.revenueCanceled > 0
                            ? (
                                (profileUser?.indexes?.revenueCanceled /
                                  profileUser?.indexes?.revenueReceived) *
                                100
                              ).toFixed(2)
                            : 0}
                          %)
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </BoxWrapperComponent>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              arenaInfo={undefined}
              isArena={false}
              background={undefined}
              headerTitle={
                <Box paddingX={1}>
                  <TitleCustomComponent
                    title={"BẢNG XẾP HẠNG"}
                    fontSize="13px"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => scrollIndexMyRank()}
                  >
                    <TextComponent
                      content={`Vị trí của bạn trên bảng xếp hạng:`}
                      maxLine="1"
                    />
                    <TextComponent
                      content={`${rankCurrent?.position ?? ""}`}
                      styleText={{
                        fontSize: "20px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        marginBottom: "7px",
                        width: "100px",
                      }}
                      maxLine="1"
                    />
                  </Box>
                </Box>
              }
              headerIcon={<img src={CupIcon} alt="..." />}
              borderImageBox={getBorderBox()}
              headerBtnRight={
                <Box
                  key={0}
                  sx={{
                    justifyContent: "center",
                    display: "grid",
                    alignContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    onClick={() => setOpenModalSearch(true)}
                  >
                    <SearchOutlinedIcon
                      style={{ color: "white", marginRight: "4px" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      marginBottom: 1,
                      paddingTop: "2px",
                    }}
                  >
                    <SelectItemComponent
                      items={SelectByScope}
                      value={valueScope}
                      setValue={setValueScope}
                    />
                    <SelectItemComponent
                      items={SelectByIndex}
                      value={valueIndex}
                      setValue={setValueIndex}
                    />
                  </Box>
                </Box>
              }
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
            >
              <ArenaRankingComponent
                refList={listRef}
                isShowMyRank={true}
                isEmployeeList={false}
                setListRank={setListRank}
                listRank={listRank}
                setOpenModalInfo={setOpenModalInfo}
                paramSearchScope={valueScope}
                paramSearchIndex={valueIndex}
              />
              {infoUserSelectDetail ? (
                <InfoUserModal
                  onClose={() => {
                    dispatch(setIndexInfoUserAction(null));
                    dispatch(setCompareToFirstInfoUser(null));
                    setOpenModalInfo(false);
                  }}
                  onOpen={openModalInfo}
                  infoUser={infoUserSelectDetail}
                />
              ) : null}

              <SearchArenaRankingModal
                open={openModalSearch}
                onClose={() => setOpenModalSearch(false)}
                setOpenModalInfo={setOpenModalInfo}
                valueScope={valueScope}
                valueIndex={valueIndex}
              />
            </BoxWrapperComponent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
