import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  challengeLeaderboard,
  challengeRoomsList,
  challengeRoomsStore,
  challengesJoin,
  challengesLeave,
  challengesPrize,
  challengeRoomConfigs,
  confirmChallengeRoom,
  detailChallengeRoom,
  updateBetRatio,
  challengesRemoveParticipant,
} from "api/challenge";
import { getErrorMessage } from "api";
import { setDetailRoom, setLeaderboard } from "./challenges.slice";
import { setMessageError, setOpen } from "redux/popup/popup.slice";

export const asyncChallengeRoomsStore = createAsyncThunk(
  "challenge/store",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await challengeRoomsStore(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncListChallengeRooms = createAsyncThunk(
  "challenge/list",
  async (params, { dispatch, rejectWithValue }) => {
    const response = await challengeRoomsList(params);
    if (response?.data?.success) {
      return response?.data?.data?.data ?? [];
    }
    return [];
  }
);

export const asyncChallengesPrize = createAsyncThunk(
  "challenge/prize",
  async (params, { dispatch, rejectWithValue }) => {
    const response = await challengesPrize(params);
    if (response?.data?.success) {
      return response?.data?.data;
    }
    return [];
  }
);

export const asyncChallengeRoomsJoin = createAsyncThunk(
  "challenge/join",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await challengesJoin(id);
      if (!response?.data?.success) {
        dispatch(setMessageError(response?.data?.message));
        dispatch(setOpen(true));
        return response?.data?.success;
      }
      return response?.data?.success;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncRemoveParticipant = createAsyncThunk(
  "challenge-rooms/:id/remove/:participantId",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await challengesRemoveParticipant(data.idJoin, data.participantIdRemove);
      if (!response?.data?.success) {
        dispatch(setMessageError(response?.data?.message));
        dispatch(setOpen(true));
        return response?.data?.success;
      }
      return response?.data?.success;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncDetailRoom = createAsyncThunk(
  "challenge-rooms/:id",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await detailChallengeRoom(id);
      const { data, success } = response?.data;
      if (success) {
        dispatch(setDetailRoom(data));
        return data;
      }
      return null;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncRoomConfirm = createAsyncThunk(
  "challenge-rooms/:id/change-status",
  async ({ id, isConfirmed }, { dispatch, rejectWithValue }) => {
    try {
      const response = await confirmChallengeRoom({ id, isConfirmed });
      const { data, success } = response;
      if (success) {
        return data;
      } else {
        dispatch(setMessageError(data?.message));
        dispatch(setOpen(true));
        return null;
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncUpdateBetRatio = createAsyncThunk(
  "challenge-rooms/:id/update",
  async ({ id, participants }, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateBetRatio({ id, participants });
      const { data, success, message } = response?.data;
      if (success) {
        dispatch(setMessageError("Cập nhật tỷ lệ kèo thành công"));
        dispatch(setOpen(true));
        return data;
      }
      dispatch(setMessageError(message));
      dispatch(setOpen(true));
      return null;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncChallengeRoomsLeave = createAsyncThunk(
  "challenge/leave",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await challengesLeave(id);
      const { success, message } = response?.data;
      if (success) {
        return success;
      }
      dispatch(setMessageError(message));
      dispatch(setOpen(true));
      return null;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
export const asyncChallengeLeaderboard = createAsyncThunk(
  "challenge/:id/leaderboard",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await challengeLeaderboard(id);
      const { success, data } = response.data;
      if (success) {
        dispatch(setLeaderboard(data));
        return true;
      }
      return false;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncChallengeRoomConfigs = createAsyncThunk(
  "challenge-room-configs",
  async (params, { dispatch, rejectWithValue }) => {
    const response = await challengeRoomConfigs(params);
    if (response?.data?.success) {
      return response?.data?.data ?? [];
    }
    return [];
  }
);
