import {
  Box,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "assets/images/btn-close.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncChallengeLeaderboard,
  asyncChallengeRoomsLeave,
  asyncDetailRoom,
  asyncRoomConfirm,
  asyncUpdateBetRatio,
} from "redux/challenges/challenges.actions";
import {
  challengeSelector,
  idChallengeJoinSelector,
} from "redux/challenges/challenges.selectors";
import {
  setIdChallengeJoin,
  setTypeSearch,
} from "redux/challenges/challenges.slice";
import { showPopup } from "redux/popup/popup.action";
import {
  setChallengeRoomId,
  setMessageError,
  setOpen,
  setOpenLeaderboardModal,
  setOpenChallengRoomModal,
} from "redux/popup/popup.slice";
import ConfirmIcon from "assets/images/icon-done.svg";
import {
  challengeRoomIdSelector,
  popupSelector,
} from "redux/popup/popup.selector";
import BtnCancel from "../../assets/images/btn-cancel.png";

function LeaderboardChallenge({ open, onClose }) {
  const dispatch = useDispatch();
  const { leaderboard } = useSelector(challengeSelector);
  const challengeId = useSelector(challengeRoomIdSelector);
  useEffect(() => {
    if (!challengeId) {
      return;
    }
    if (open) {
      dispatch(asyncChallengeLeaderboard(challengeId));
    }
  }, [open, challengeId]);

  return (
    <Modal
      open={open}
      onClose={() => {
        dispatch(setIdChallengeJoin(null));
        onClose();
        dispatch(setChallengeRoomId(null));
      }}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        border: "2px solid #000",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 655,
          bgcolor: "white",
          p: 4,
          background: "#302A20",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
          border: "1px solid #FFEEA3",
          borderRadius: "5px",
          height: 400,
        }}
      >
        <Box
          onClick={() => {
            dispatch(setIdChallengeJoin(null));
            onClose();
            dispatch(setChallengeRoomId(null));
          }}
          sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
        >
          <img src={CloseIcon} alt="..." />
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{
            fontFamily: "SVN-Gotham",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "22.9001px",
            lineHeight: "32px",
            textAlign: "center",
            textTransform: "uppercase",
            color: "#FFF8E0",
          }}
        >
          Bảng xếp hạng thách đấu
        </Typography>
        <Box sx={{ marginTop: "20px" }}></Box>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ marginTop: "" }}>
            <TableContainer component={Paper} sx={{ maxHeight: "294px" }}>
              <Table
                sx={{ minWidth: 470, bgcolor: "#6E5D43" }}
                aria-label="simple table"
                size="small"
                stickyHeader={true}
              >
                <TableHead
                  sx={{
                    "& .MuiTableCell-stickyHeader": {
                      backgroundColor: "#6E5D43",
                      color: "white",
                      fontFamily: "Be-Vietnam-Pro",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>XH</TableCell>
                    <TableCell align="center">Tên nhân viên</TableCell>
                    <TableCell align="center">Tỷ lệ kèo</TableCell>
                    <TableCell align="center">Doanh số tích lũy</TableCell>
                    <TableCell align="center">Doanh số cuối</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "white",
                    },
                  }}
                >
                  {leaderboard?.data?.map((data, index) => {
                    return (
                      <TableRow
                        key={data?.rnum}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{data?.position}</TableCell>

                        <TableCell component="th" scope="row">
                          {data?.fullname}
                        </TableCell>
                        <TableCell align="center">{data?.odds}</TableCell>
                        <TableCell align="center">{data?.score}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textAlign: "-webkit-center" }}
                        >
                          {data?.calculateScore}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setChallengeRoomId(challengeId));
                    dispatch(setOpenLeaderboardModal(false));
                    dispatch(setOpenChallengRoomModal(true));
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      padding: "7px 17px",
                      height: "36px",
                      background:
                        "radial-gradient(50% 50% at 50% 50%, #EB4C4C 0%, #AD0606 100%)",
                      boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.35)",
                      borderRadius: "9px",
                      fontFamily: "SVN-Gotham",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "14px",
                      textAlign: "right",
                      color: "#FFEEA3",
                      border: "1px solid #AC4120",
                      textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    Chi tiết thách đấu
                  </Typography>
                </Box>
              </Box>
        </Grid>
      </Box>
    </Modal>
  );
}

export default LeaderboardChallenge;
