import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { srcMusicSelector } from "redux/musics/music.selectors";
import {
  firstClickSelector,
  isPlayMusicSelector,
} from "redux/popup/popup.selector";
import { setFirstClickWeb, setPlayMusic } from "redux/popup/popup.slice";

export default function AudioPlayer() {
  const srcMusic = useSelector(srcMusicSelector);
  const audioMusicEvent = useRef(new Audio());
  const isPlayAudio = useSelector(isPlayMusicSelector);
  const firstClick = useSelector(firstClickSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    function handleEvent() {
      const audioEvent = audioMusicEvent;
      if (isPlayAudio) {
        audioEvent.current.loop = true;
        audioEvent.current.play();
      } else {
        audioEvent.current.pause();
      }
    }

    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  }, [isPlayAudio]);

  useEffect(() => {
    function playMusic() {
      if (!firstClick) {
        dispatch(setPlayMusic(true));
        dispatch(setFirstClickWeb());
      }
      const audioEvent = audioMusicEvent;

      if (isPlayAudio && srcMusic) {
        if (audioEvent.current.paused) {
          if (audioEvent.current?.src !== srcMusic) {
            audioEvent.current.src = srcMusic;
          }
          audioEvent.current.loop = true;
          audioEvent.current.play();
        }
      } else {
        audioEvent.current.pause();
      }
    }

    window.addEventListener("click", playMusic);

    return () => {
      window.removeEventListener("click", playMusic);
    };
  }, [srcMusic, isPlayAudio]);

  useEffect(() => {
    const audioEvent = audioMusicEvent;
    if (srcMusic && isPlayAudio) {
      if (audioEvent.current.src !== srcMusic) {
        audioEvent.current.src = srcMusic;
      }
      if (isPlayAudio && audioEvent.current.paused) {
        audioEvent.current.loop = true;
        audioEvent.current.play();
      }
    } else {
      audioEvent.current.pause();
    }
  }, [srcMusic, isPlayAudio]);
}
