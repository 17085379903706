export const hoverBg = {
  "&:hover": {
    boxSizing: "border-box",
    background: "#302A20",
    boxShadow: `0px 0px 28px rgba(255, 6, 6, 0.65)`,
    borderRadius: "4px",
  },
};

export const hoverBgBlue = {
  "&:hover": {
    boxShadow: `0px 0px 20px rgba(6, 195, 255, 0.65)`,
  },
};

export const hoverBgButton = {
  "&:hover": {
    boxSizing: "border-box",
    background: "#302A20",
    boxShadow: `0px 0px 10px rgba(255, 6, 6, 0.65)`,
    borderRadius: "10px",
  },
};

export const hoverBgButtonOran = {
  "&:hover": {
    boxSizing: "border-box",
    background: "#F6AF1A",
    boxShadow: `0px 0px 6px rgba(255, 6, 6, 0.65)`,
    borderRadius: "10px",
  },
};
