import React from "react";
import { Typography } from "@mui/material";
import { colors } from "../../config/colors/colors";

function TitleCustomComponent({
  title = "",
  fontSize = "18px",
  style = {},
  maxLine = "0",
}) {
  return (
    <Typography
      sx={{
        color: colors.textTitle,
        fontWeight: 700,
        fontSize: fontSize,
        fontFamily: "SVN-Gotham",
        textTransform: "uppercase",
        background: `linear-gradient(180deg, #FFF8E0 0%, #FFEEA3 100%)`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "#FFEEA3",
        backgroundClip: "text",
        fontStyle: "normal",
        textShadow: `0px 3px 0px rgba(0, 0, 0, 0.25)`,
        // WebkitTextStroke: "0.587182px transparent",
        // WebkitTextStrokeColor: "#6C5227",
        display: "-webkit-box",
        lineClamp: 1,
        textOverflow: "ellipsis",
        whiteSpace: "initial",
        overflow: "hidden",
        WebkitLineClamp: maxLine,
        WebkitBoxOrient: "vertical",
        ...style,
      }}
    >
      {title}
    </Typography>
  );
}

export default TitleCustomComponent;
