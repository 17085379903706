export const popupSelector = (state) => state.popup;

export const isModalInfoUserCenterSelector = (state) =>
  state.popup.isModalInfoUserCenter;

export const popupListStaffSelector = (state) =>
  state.popup.isOpenModalListStaff;

export const popupEquipmentDetailSelector = (state) =>
  state.popup.isOpenModalEquipmentDetail;

export const popupAwardedSelector = (state) => state.popup.isOpenModalAwarded;

export const popupSearchRankingSelector = (state) =>
  state.popup.isOpenModalSearchRanking;

export const infoEquipmentDetailSelector = (state) =>
  state.popup.infoEquipmentDetail;

export const popupGiftBoxSevenDetailSelector = (state) =>
  state.popup.isModalGiftBoxDetailSeven;

export const popupGiftBoxSelector = (state) => state.popup.isOpenGiftBoxModal;

export const popupGiftBoxSevenSelector = (state) =>
  state.popup.isOpenGiftBoxSeventModal;

export const infoGiftBoxSelector = (state) => state.popup.infoGiftBox;

export const isPlayMusicSelector = (state) => state.popup.isPlayMusic;

export const messageErrorSelector = (state) => state.popup.messageError;

export const tabShowInfoSelector = (state) => state.popup.tabShowInfo;

export const popupUseItemSelector = (state) => state.popup.isOpenUseItemModal;

export const popupABetSelector = (state) => state.popup.isOpenABet;

export const popupRemoveParticipantSelector = (state) => state.popup.isOpenRemoveParticipant;

export const firstClickSelector = (state) => state.popup.firstClickWeb;

export const challengeRoomIdSelector = (state) => state.popup.challengeRoomId;

export const openChallengRoomModalSelector = (state) =>
  state.popup.isOpenChallengRoomModal;

export const openUpRankModalSelector = (state) => state.popup.isOpenUpRankModal;

export const infoNotificationSelector = (state) => state.popup.infoNotification;

export const openLeaderboardModalSelector = (state) =>
  state.popup.isOpenLeaderboard;
