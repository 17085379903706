import { Box, CircularProgress, Popover, Typography } from "@mui/material";
import CloseIcon from "assets/images/btn-close.png";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  asyncReadNotification,
  getListNotification,
  setReadAllNotification,
} from "redux/events/events.actions";
import { popupSelector } from "redux/popup/popup.selector";
import {
  setChallengeRoomId,
  setInfoNotification,
  setOpenArenaWinModal,
  setOpenChallengRoomModal,
  setOpenNotification,
  setUnread,
} from "redux/popup/popup.slice";

function ListNotificationModal({ open, onClose }) {
  const dispatch = useDispatch();
  const { notiAnchor } = useSelector(popupSelector);

  const NotificationItem = () => {
    const [page, setPage] = useState(1);
    const [listNoti, setListNoti] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (open) {
        setLoading(true);
        asyncGetListNotification(1);
      }
    }, [open]);

    const asyncGetListNotification = async (pageNow) => {
      await dispatch(getListNotification(pageNow))
        .unwrap()
        .then((res) => {
          if (res?.data?.length > 0) {
            if (pageNow === 1) {
              setListNoti(res?.data);
              dispatch(setUnread(res?.unread));
            } else {
              const newList = [...listNoti, ...res?.data];
              setListNoti(newList);
              dispatch(setUnread(res?.unread));
              setPage(pageNow);
            }
          } else {
            setHasMore(false);
            if (pageNow === 1) {
              setListNoti([]);
              dispatch(setUnread(res?.unread));
            }
          }
          setLoading(false);
        })
        .catch((rejectedValueOrSerializedError) => {
          setLoading(false);
        });
    };

    const fetchMoreData = () => {
      setHasMore(true);
      const pageLoadMore = page + 1;
      const idTimeOut = setTimeout(
        () => asyncGetListNotification(pageLoadMore),
        500
      );
      return () => clearTimeout(idTimeOut);
    };

    const setReadNotification = (isRead, id) => {
      if (!isRead) {
        dispatch(asyncReadNotification(id));
        asyncGetListNotification(1);
      }
    };

    return (
      <InfiniteScroll
        dataLength={listNoti?.length}
        next={fetchMoreData}
        hasMore={true}
        loader={
          hasMore ? (
            <Box
              key={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              <CircularProgress size={"16px"} style={{ color: "white" }} />
            </Box>
          ) : null
        }
        height={516}
      >
        {listNoti?.map((data, i) => (
          <Box sx={{ margin: "7px", position: "relative" }} key={i}>
            {data?.data?.eventId !== undefined ? (
              <Link to={`/events/${data?.data?.eventId}`}>
                <Box
                  sx={{
                    display: "flex",
                    bgcolor: "#514533",
                    padding: "5px",
                    borderRadius: "5px",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    setReadNotification(data?.isRead, data?.id);
                    onClose();
                  }}
                >
                  <Box
                    sx={{
                      display: " flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "SVN-Gotham",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#EEBA3A",
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                        }}
                      >
                        {data?.data?.title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "SVN-Gotham",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#fff",
                          marginLeft: "5px",
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {data?.data?.prize?.quantity}
                        {` `}
                        {data?.data?.prize?.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#fff",
                          marginLeft: "5px",
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {data?.data?.timestamp}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  bgcolor: "#514533",
                  padding: "5px",
                  borderRadius: "5px",
                  flexDirection: "row",
                  cursor: "pointer",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  if (data?.data?.challengeRoomId) {
                    dispatch(setChallengeRoomId(data?.data?.challengeRoomId));
                    dispatch(setOpenChallengRoomModal(true));
                  }
                  if (data?.data?.origin === 6) {
                    dispatch(setInfoNotification(data));
                    dispatch(setOpenArenaWinModal(true));
                  }
                  setReadNotification(data?.isRead, data?.id);
                  onClose();
                }}
              >
                <Box
                  sx={{
                    display: " flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "SVN-Gotham",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#EEBA3A",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {data?.data?.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "SVN-Gotham",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#fff",
                        marginLeft: "5px",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {data?.data?.prize?.quantity}
                      {` `}
                      {data?.data?.prize?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#fff",
                        marginLeft: "5px",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {data?.data?.timestamp}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {data?.isRead ? null : (
              <Box
                sx={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  backgroundColor: "#FFEEA3",
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                }}
              ></Box>
            )}
          </Box>
        ))}
      </InfiniteScroll>
    );
  };

  return (
    <Popover
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={notiAnchor}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          width: "400px",
          bgcolor: "white",
          padding: "25px 10px",
          background: "#302A20",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
          border: "1px solid #FFEEA3",
          borderRadius: "5px",
        }}
      >
        <Box
          onClick={() => {
            onClose();
          }}
          sx={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
        >
          <img src={CloseIcon} alt="..." />
        </Box>
        <Box
          sx={{
            gridRow: "1",
            gridColumn: "span 2",
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "SVN-Gotham",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "22.9001px",
              textAlign: "start",
              textTransform: "uppercase",
              color: "#FFF8E0",
              alignSelf: "center",
            }}
          >
            THÔNG BÁO
          </Typography>
        </Box>
        <Box
          onClick={() => {
            dispatch(setReadAllNotification());
            dispatch(setUnread(0));
            onClose();
          }}
        >
            <Typography style={{
              fontFamily: "SVN-Gotham",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "end",
              color: "#FFF8E0",
              alignSelf: "center",
              cursor: "pointer"
            }}
          >
            Đọc tất cả
            </Typography>
          </Box>
        <NotificationItem />
      </Box>
    </Popover>
  );
}

export default React.memo(ListNotificationModal);
