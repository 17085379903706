import { Box } from "@mui/material";
import React from "react";
import ProgressiveImage from "./ProgressiveImage";
import TextComponent from "./Texts/TextComponent";

export const IconTextComponent = ({
  icon = "",
  title = "",
  styleBox = {},
  styleText = {},
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ...styleBox,
      }}
    >
      <ProgressiveImage
        src={icon ?? ""}
        styleImg={{ marginTop: "3.5px", marginRight: "2px" }}
      />
      <TextComponent
        content={title ?? ""}
        styleText={{
          textTransform: "uppercase",
          fontSize: "16px",
          color: "#59A6FD",
          ...styleText,
        }}
        maxLine="1"
      />
    </Box>
  );
};
