// @ts-nocheck
/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import { createSlice } from "@reduxjs/toolkit";

const attendanceSlice = createSlice({
  name: "attendance",
  initialState: {
    error: false,
    loading: false,
    success: false,
    isAttendance: true,
  },
  reducers: {
    setIsAttendance: (state, { payload }) => {
      state.isAttendance = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setIsAttendance } = attendanceSlice.actions;
export default attendanceSlice;
