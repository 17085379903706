import { Box, Grid, Modal } from "@mui/material";
import React from "react";
import BtnRightBoxSeven from "../../assets/images/icon-right-box-qua-7.png";
import BtnBoxSeven from "../../assets/images/box-qua-7.png";
import IconMoney from "../../assets/images/money.png";
import TuongVang from "../../assets/images/tuong-vang.png";
import IconParty from "../../assets/images/party-1.png";
import IconKiNghi from "../../assets/images/ky-nghi1.png";
import IconRuongKhoBau from "../../assets/images/ruong-kho-bau.png";
import IconCloseModal from "../../assets/images/close-modal.png";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { DetailBoxSevenComponent } from "components/DetailBoxSevenComponent";

export default function GiftBoxSevenDetailModal(props) {
  const { open, onClose } = props;
  return (
    <div>
      <Modal open={open} onClose={onClose} disableAutoFocus={true}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "white",
            background: "#2C271D",
            boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
            border: "2px solid #D0A674",
            borderRadius: "6.32136px",
            boxSizing: "border-box",
            paddingTop: 2,
            paddingBottom: 2,
            outline: 0,
            height: 520,
            maxHeight: "95%",
          }}
        >
          <Box
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "-45px",
              right: 0,
              cursor: "pointer",
            }}
          >
            <img src={IconCloseModal} alt="..." />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              mixBlendMode: "lighten",
            }}
          >
            <img src={BtnRightBoxSeven} alt="..." />
          </Box>
          <Grid container paddingLeft={"14px"} marginBottom={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={BtnBoxSeven}
                alt="..."
                style={{ width: 86, marginRight: 16 }}
              />
              <TitleCustomComponent title={`Box quà 7`} fontSize="23px" />
            </Box>
          </Grid>
          <Grid
            container
            paddingLeft={"14px"}
            paddingRight={"14px"}
            marginBottom={2}
            spacing={2}
            sx={{ position: "absolute", paddingTop: 1 }}
          >
            <Grid item xs={4}>
              <DetailBoxSevenComponent
                icon={IconMoney}
                title={"Tiền mặt"}
                money={"30 triệu đồng"}
                percent={"25"}
              />
            </Grid>
            <Grid item xs={4}>
              <DetailBoxSevenComponent
                icon={TuongVang}
                title={"Tượng vàng"}
                money={"30 triệu đồng"}
                percent={"25"}
                widthIcon={"50px"}
              />
            </Grid>
            <Grid item xs={4}>
              <DetailBoxSevenComponent
                icon={IconParty}
                title={"Party 5 sao"}
                money={"60 triệu đồng"}
                percent={"25"}
              />
            </Grid>
          </Grid>
          <Grid
            container
            paddingLeft={"14px"}
            paddingRight={"14px"}
            marginBottom={2}
            spacing={3}
            sx={{ position: "absolute", bottom: 0, paddingBottom: "8px" }}
          >
            <Grid item xs={6}>
              <DetailBoxSevenComponent
                icon={IconKiNghi}
                title={"Du lịch 5 sao"}
                money={"120 triệu đồng"}
                percent={"24"}
              />
            </Grid>
            <Grid item xs={6}>
              <DetailBoxSevenComponent
                icon={IconRuongKhoBau}
                title={"Giải đặc biệt"}
                money={"1.200 triệu đồng"}
                percent={"1"}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
