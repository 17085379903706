import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "assets/images/btn-close.png";
import { useDispatch, useSelector } from "react-redux";
import { messageErrorSelector } from "redux/popup/popup.selector";
import { setMessageError, setOpen } from "redux/popup/popup.slice";

export default function NotifiModal({ open, onClose }) {
  const dispatch = useDispatch();
  const messageError = useSelector(messageErrorSelector);
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          dispatch(setMessageError(null));
          onClose();
        }}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          border: "2px solid #000",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            p: 4,
            background: "#302A20",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
            border: "1px solid #FFEEA3",
            borderRadius: "5px",
          }}
        >
          <Box
            onClick={() => {
              dispatch(setMessageError(null));
              dispatch(setOpen(false));
            }}
            sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
          >
            <img src={CloseIcon} alt="..." />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              fontFamily: "SVN-Gotham",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "22.9001px",
              lineHeight: "32px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "#FFF8E0",
            }}
          >
            Thông báo
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              fontFamily: "Be Vietnam Pro",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "22px",
              textAlign: "center",
              color: "#C9C9C9",
            }}
          >
            {typeof messageError === "string"
              ? messageError
              : "Tính năng đang phát triển, vui lòng quay lại sau"}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
