import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { useState } from "react";
import BtnCancel from "../../assets/images/btn-cancel.png";
import CloseIcon from "../../assets/images/btn-close.png";
import BtnExchange from "../../assets/images/inventory/btn-exchange.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncExchange,
  asyncExchangeDetail,
  asyncScheduleUsage,
} from "redux/inventory/inven.action";
import { invenSelector } from "redux/inventory/inven.selector";
import {
  setMessageError,
  setOpenModalEquipmentDetail,
} from "redux/popup/popup.slice";
import { showPopup } from "redux/popup/popup.action";
import { setDetailInven, setReloadInven } from "redux/inventory/inven.slice";
import { TitleComponent } from "components/titles/TitleComponent";
import { KeyValueComponent } from "components/titles/KeyValueComponent";
import BtnUsed from "../../assets/images/btn-use.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../config/styles/calendar.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import vi from "date-fns/locale/vi";
import moment from "moment";
import { formatDate } from "utils/common";
registerLocale("vi", vi);
setDefaultLocale("vi");

export default function UseItemsModal({ open, onClose }) {
  const [number, setNumber] = useState("");
  const dispatch = useDispatch();
  const { detailInventory } = useSelector(invenSelector);
  const [startDate, setStartDate] = useState(moment().toDate());

  const postExchange = () => {
    if (number === "") {
      return;
    }
    if (number == 0) {
      dispatch(setMessageError("Số lượng sử dụng phải lớn hơn 0"));
      dispatch(showPopup(true));
      return;
    }
    if (!detailInventory?.permission) {
      dispatch(setMessageError("Bạn không có quyền sử dụng vật phẩm này"));
      dispatch(showPopup(true));
      return;
    }
    const params = {
      quantity: number,
      inventoryId: detailInventory?.id ?? "",
      scheduledUsageDay: formatDate(startDate),
    };
    dispatch(setReloadInven(false));
    dispatch(asyncScheduleUsage(params))
      .unwrap()
      .then((result) => {
        if (result) {
          dispatch(setOpenModalEquipmentDetail(false));
          onClose();
          dispatch(setMessageError("Sử dụng vật phẩm thành công"));
          dispatch(showPopup(true));
          dispatch(setReloadInven(true));
          dispatch(setDetailInven({}));
          setNumber("");
        }
      })
      .catch((error) => {
        dispatch(
          setMessageError(error ?? "Đã có lỗi xảy ra, vui lòng thử lại sau.")
        );
        dispatch(showPopup(true));
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setNumber("");
      }}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "white",
          background: "#302A20",
          boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
          border: "2px solid #D0A674",
          borderRadius: "6.32136px",
          boxSizing: "border-box",
          paddingTop: 2,
          paddingBottom: 2,
          outline: 0,
          maxHeight: "95%",
        }}
      >
        <Box
          sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
          onClick={() => {
            onClose();
            setNumber("");
          }}
        >
          <img src={CloseIcon} alt="..." />
        </Box>
        <Grid container direction={"column"}>
          <TitleCustomComponent
            title={"sử dụng"}
            fontSize="22px"
            style={{
              textAlign: "center",
              marginBottom: "4px",
            }}
          />
          <Grid
            item
            xs
            paddingLeft={"14px"}
            paddingRight={"14px"}
            marginBottom={2}
            container
            direction={"row"}
          >
            <Grid
              xs={7}
              item
              paddingRight={2}
              justifyContent={"nomal"}
              display={"grid"}
            >
              <TitleComponent title="Thông tin" />
              <KeyValueComponent
                title={"Tên vật phẩm:"}
                value={`${detailInventory?.name ?? ""}`}
              />
              <KeyValueComponent
                title={"Tổng số lượng:"}
                value={`${detailInventory?.quantity ?? 0}`}
              />
              <KeyValueComponent
                title={"Được sử dụng:"}
                value={`${detailInventory?.quantityUse ?? 0}`}
              />
              <KeyValueComponent
                title={"Chờ sử dụng:"}
                value={`${detailInventory?.quantityRequest ?? 0}`}
              />
              <KeyValueComponent
                title={"Sắp hết hạn:"}
                value={`${detailInventory?.quantityUseExpired ?? 0}`}
              />
              <KeyValueComponent
                title={"Ngày bắt đầu:"}
                value={`${detailInventory?.useStartAt ?? ""}`}
              />
              <KeyValueComponent
                title={"Hạn sử dụng:"}
                value={`${detailInventory?.useExpiredAt ?? ""}`}
              />
            </Grid>
            <Grid xs={5} item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    alignContent: "space-between",
                    display: "grid",
                    marginLeft: 1,
                    width: "100%",
                  }}
                >
                  <FormControl fullWidth>
                    <TextComponent
                      content="Số lượng"
                      styleText={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        color: "#C9C9C9",
                        letterSpacing: "1px",
                        lineHeight: "17.5px",
                        marginBottom: "2px",
                      }}
                    />
                    <TextField
                      size="small"
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      placeholder="Nhập số lượng"
                      id="mui-theme-provider-outlined-input"
                      sx={{
                        ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "white",
                          fontSize: "15px",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          letterSpacing: "2px",
                          lineHeight: "17.5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "white",
                          "& fieldset": {
                            borderColor: "#D6B88A",
                            borderRadius: "8px",
                          },
                          "&:hover fieldset": {
                            borderColor: "#D6B88A",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D6B88A",
                          },
                        },
                      }}
                      value={number}
                      onChange={(e) =>
                        setNumber(e.target.value.replace(/\D/g, ""))
                      }
                    />
                    <TextComponent
                      content="Chọn ngày muốn sử dụng"
                      styleText={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        color: "#C9C9C9",
                        letterSpacing: "1px",
                        lineHeight: "17.5px",
                        marginTop: "8px",
                      }}
                    />
                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          right: "4px",
                          top: "7px",
                        }}
                      >
                        <EventIcon
                          style={{ color: "#d6b88a", width: "100%" }}
                        />
                      </Box>
                      <DatePicker
                        selected={startDate}
                        minDate={moment().toDate()}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        showIcon={true}
                        formatWeekDay={(nameOfDay) => {
                          switch (nameOfDay) {
                            case "Thứ Hai":
                              return "T2";
                            case "Thứ Ba":
                              return "T3";
                            case "Thứ Tư":
                              return "T4";
                            case "Thứ Năm":
                              return "T5";
                            case "Thứ Sáu":
                              return "T6";
                            case "Thứ Bảy":
                              return "T7";
                            case "Chủ Nhật":
                              return "CN";
                            default:
                              break;
                          }
                        }}
                      />
                    </Box>

                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker />
                    </LocalizationProvider> */}
                    {/* <DatePicker
                      onChange={onChange}
                      value={value}
                      locale="vi"
                      clearIcon={null}
                      minDate={new Date()}
                    /> */}
                  </FormControl>
                  <Box
                    sx={{
                      paddingTop: "12px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      onClick={() => {
                        postExchange();
                      }}
                      sx={{
                        cursor: number ? "pointer" : "auto",
                        opacity: number ? 1 : 0.5,
                      }}
                    >
                      <img src={BtnUsed} alt="..." />
                    </Box>

                    <Box sx={{ width: "16px" }}></Box>
                    <div onClick={onClose} style={{ cursor: "pointer" }}>
                      <img src={BtnCancel} alt="..." />
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs sx={{ borderTop: "1px solid #E9C896" }}>
            <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <Box sx={{ height: "8px" }}></Box>
              <TextComponent
                content="Vui lòng đợi xét duyệt sau khi bạn sử dụng."
                styleText={{
                  fontSize: "12px",
                  fontFamily: "sans-serif",
                  fontWeight: "400",
                  color: "#81838E",
                  letterSpacing: "1.1px",
                  lineHeight: "15px",
                  fontStyle: "Italic",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
