import {
  Box,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import CoinIcon from "assets/images/coin-icon.svg";
import CloseIcon from "../../assets/images/btn-close.png";
import MoneyIcon from "assets/images/inventory/money-icon.svg";
import BtnExchange from "../../assets/images/inventory/btn-exchange.png";
import BtnCancel from "../../assets/images/btn-cancel.png";

export default function CoinExchangeModal({ open, onClose }) {
  return (
    <Modal open={open} onClose={onClose} disableAutoFocus={true}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 450,
          bgcolor: "white",
          background: "#302A20",
          boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
          border: "2px solid #D0A674",
          borderRadius: "6.32136px",
          boxSizing: "border-box",
          paddingTop: 2,
          paddingBottom: 2,
          outline: 0,
          maxHeight: "95%",
        }}
      >
        <Box
          sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
          onClick={onClose}
        >
          <img src={CloseIcon} alt='...' />
        </Box>
        <Grid container direction={"column"}>
          <Grid
            item
            xs
            paddingLeft={"14px"}
            paddingRight={"14px"}
            marginBottom={2}
          >
            <TitleCustomComponent
              title={"ĐỔI COIN"}
              fontSize='22px'
              style={{
                textAlign: "center",
                marginBottom: "8px",
                marginTop: 1,
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box display='flex' sx={{ flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                  }}
                >
                  Bạn đang có
                </Typography>
                <Typography
                  sx={{
                    color: "#FFA200",
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  : 2000 Coin
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridAutoColumns: "1fr",
                  gap: 1,
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{ gridRow: "1", gridColumn: "span 3", display: "flex" }}
                >
                  <TextField
                    id='standard-basic'
                    size='small'
                    value='1500'
                    sx={{
                      bgcolor: "#493E2D",
                      borderRadius: "5px",
                      border: "1px solid #D6B88A",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img src={CoinIcon} width='40px' alt='...' />
                        </InputAdornment>
                      ),
                      style: {
                        fontSize: 15,
                        fontWeight: 700,
                        color: "white",
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    gridRow: "1",
                    gridColumn: "span 1",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      alignSelf: "center",
                      fontSize: 15,
                      fontWeight: 700,
                      fontFamily: "Be Vietnam Pro",
                      color: "white",
                    }}
                  >
                    =
                  </Typography>
                </Box>
                <Box sx={{ gridRow: "1", gridColumn: "span 3" }}>
                  <TextField
                    id='standard-basic'
                    size='small'
                    disabled
                    value='30.000.000'
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "white",
                      bgcolor: "#493E2D",
                      borderRadius: "5px",
                      border: "1px solid #D6B88A",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img src={MoneyIcon} width='40px' alt='...' />
                        </InputAdornment>
                      ),
                      style: {
                        fontSize: 15,
                        fontWeight: 700,
                        color: "rgb(50, 50, 50)",
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    gridRow: "1",
                    gridColumn: "span 1",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      alignSelf: "center",
                      fontSize: 15,
                      fontWeight: 700,
                      color: "white",
                    }}
                  >
                    VNĐ
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs sx={{ borderTop: "1px solid #E9C896" }}>
            <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <Box sx={{ height: "8px" }}></Box>
              <TextComponent
                content='1 OBD Coin tương đương với 20.000 VNĐ. Bạn được đổi Coin vào ngày 15 và ngày 28 hàng tháng. Sau khi đổi, kế toán sẽ chuyển tiền tới tài khoản của bạn sau 24 giờ.'
                styleText={{
                  fontSize: "12px",
                  fontFamily: "sans-serif",
                  fontWeight: "400",
                  color: "#81838E",
                  letterSpacing: "1.1px",
                  lineHeight: "15px",
                  fontStyle: "Italic",
                }}
              />
            </Box>
            <Box
              sx={{
                paddingTop: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div onClick={() => {}} style={{ cursor: "pointer" }}>
                <img src={BtnExchange} alt='...' />
              </div>
              <Box sx={{ width: "16px" }}></Box>
              <div onClick={onClose} style={{ cursor: "pointer" }}>
                <img src={BtnCancel} alt='...' />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
