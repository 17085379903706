/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import { createSlice } from "@reduxjs/toolkit";

const invenSlice = createSlice({
  name: "inven",
  initialState: {
    listInventory: {
      recordsTotal: 0,
      data: [],
    },
    detailInventory: {},
    awardedUser: {},
    reloadInven: false,
    tabInven: 0,
    currency: {},
  },
  reducers: {
    setListInven: (state, { payload }) => {
      state.listInventory = payload;
    },
    setDetailInven: (state, { payload }) => {
      state.detailInventory = payload;
    },
    setAwardedUser: (state, { payload }) => {
      state.awardedUser = payload;
    },
    setReloadInven: (state, { payload }) => {
      state.reloadInven = payload;
    },
    setTabInven: (state, { payload }) => {
      state.tabInven = payload ?? 0;
    },
    setCurrency: (state, { payload }) => {
      state.currency = payload;
    },
  },
});

export const {
  setListInven,
  setDetailInven,
  setAwardedUser,
  setReloadInven,
  setTabInven,
  setCurrency,
} = invenSlice.actions;

export default invenSlice;
