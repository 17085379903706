import EventIcon from "@mui/icons-material/Event";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DuoAxe from "assets/images/challengeBtn.svg";
import ProgressiveImage from "components/ProgressiveImage";
import vi from "date-fns/locale/vi";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {
  asyncChallengeRoomsStore,
  asyncChallengesPrize,
} from "redux/challenges/challenges.actions";
import { setTypeSearch } from "redux/challenges/challenges.slice";
import { showPopup } from "redux/popup/popup.action";
import { setMessageError } from "redux/popup/popup.slice";
import { formatDate } from "utils/common";
import CloseIcon from "../../assets/images/btn-close.png";
import "../../config/styles/calendar.css";
registerLocale("vi", vi);
setDefaultLocale("vi");

export default function AddRoomModal({ open, onClose }) {
  const [type, setType] = useState(1);
  const [number, setNumber] = useState("");
  const [titleChallenge, setTitleChallenge] = useState("");
  const [startDate, setStartDate] = useState(moment().add(1, "days").toDate());
  const [endDate, setEndDate] = useState(null);
  const [prizes, setPrizes] = useState([]);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const clearData = () => {
    onClose();
    setStartDate(moment().add(1, "days").toDate());
    setEndDate(null);
    setType(1);
    setTitleChallenge("");
    setNumber("");
  };

  const handleCreateChallengeRoom = () => {
    const params = {
      objectParticipant: type,
      name: titleChallenge,
      quantity: number,
      beginAt: formatDate(startDate),
      endAt: formatDate(endDate),
    };
    if (!titleChallenge) {
      dispatch(setMessageError("Tên phòng không được để trống"));
      dispatch(showPopup(true));
      return;
    }
    if (!number) {
      dispatch(setMessageError("Số lượng tham gia không được để trống"));
      dispatch(showPopup(true));
      return;
    }

    if (number <= 1) {
      dispatch(setMessageError("Số lượng tham gia phải lớn hơn 1"));
      dispatch(showPopup(true));
      return;
    }
    if (!endDate) {
      dispatch(setMessageError("Ngày kết thúc không được để trống"));
      dispatch(showPopup(true));
      return;
    }
    dispatch(asyncChallengeRoomsStore(params))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          clearData();
          dispatch(
            setMessageError(res?.message ?? "Tạo phòng thách đấu thành công")
          );
          dispatch(showPopup(true));
          dispatch(setTypeSearch(type));
        } else {
          dispatch(setMessageError(res?.message));
          dispatch(showPopup(true));
        }
      })
      .catch((error) => {
       dispatch(
         setMessageError(error ?? "Đã có lỗi xảy ra, vui lòng thử lại sau.")
       );
        dispatch(showPopup(true));
      });
  };

  useEffect(() => {
    if (open) {
      const params = {
        participant: number !== "" ? number : 0,
        type: type,
      };

      dispatch(asyncChallengesPrize(params))
        .unwrap()
        .then((res) => {
          if (res?.challengePrize?.length) {
            setPrizes(res?.challengePrize ?? []);
          } else {
            setPrizes([]);
          }
        })
        .catch((error) => {
         dispatch(
           setMessageError(error ?? "Đã có lỗi xảy ra, vui lòng thử lại sau.")
         );
          dispatch(showPopup(true));
        });
    }
  }, [open, number, type]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          clearData();
        }}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "white",
            background: "#302A20",
            boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
            border: "2px solid #D0A674",
            borderRadius: "6.32136px",
            boxSizing: "border-box",
            outline: 0,
            maxHeight: "95%",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              height: "30px",
              display: "flex",
              justifyContent: "end",
            }}
            onClick={() => {
              clearData();
            }}
          >
            <img
              src={CloseIcon}
              alt='...'
              style={{
                position: "absolute",
                top: "-1px",
                right: "-1px",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box sx={{ gridRow: "1", gridColumn: "span 1" }}>
              <Box>
                <img src={DuoAxe} alt='...' width='80px' />
              </Box>
            </Box>
            <Box
              sx={{ gridRow: "1", gridColumn: "span 2", alignSelf: "center" }}
            >
              <Typography
                style={{
                  fontFamily: "SVN-Gotham",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "22.9001px",
                  textAlign: "start",
                  textTransform: "uppercase",
                  color: "#FFF8E0",
                  alignSelf: "center",
                }}
              >
                Tạo phòng thách đấu
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              margin: "10px 20px",
            }}
          >
            <Box sx={{ marginTop: "10px" }}>
              <FormControl fullWidth>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "#ffffff",
                      }}
                    >
                      Tên phòng thách đấu
                    </Typography>
                    <TextField
                      size='small'
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      placeholder='Nhập tên phòng'
                      sx={{
                        width: "100%",
                        ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "white",
                          fontSize: "15px",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          letterSpacing: "2px",
                          lineHeight: "17.5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "white",
                          "& input": {
                            padding: "7px 13px",
                          },
                          "& input::placeholder": {
                            color: "#9ca3af",
                          },
                          "& fieldset": {
                            borderColor: "#D6B88A",
                            borderRadius: "8px",
                          },
                          "&:hover fieldset": {
                            borderColor: "#D6B88A",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D6B88A",
                          },
                        },
                      }}
                      value={titleChallenge}
                      onChange={(e) => {
                        setTitleChallenge(e.target.value);
                      }}
                    />
                  </Box>
                </Box>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <FormControl fullWidth>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "#ffffff",
                      }}
                    >
                      Loại phòng
                    </Typography>
                    <Select
                      value={type}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      placeholder='chọn loại phòng'
                      sx={{
                        height: "36px",
                        fontSize: 14,
                        width: "100%",
                        fontWeight: 500,
                        fontFamily: "Be Vietnam Pro",
                        color: "white",
                        bgcolor: "#493E2D",
                        borderRadius: "8px",
                        border: "1px solid #D6B88A",
                        boxShadow: "none",
                        paddingLeft: "5px",
                        "& .MuiSvgIcon-root": {
                          color: "#FFF0B1",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: "#493E2D",
                            color: "blue",
                            "& .MuiMenuItem-root": {
                              fontSize: "14px",
                              fontWeight: 500,
                              fontFamily: "Be Vietnam Pro",
                              color: "white",
                            },
                          },
                        },
                      }}
                      variant='standard'
                      disableUnderline
                    >
                      <MenuItem value={1}>Cá nhân</MenuItem>
                      <MenuItem value={3}>Hộ Kinh Doanh</MenuItem>
                      <MenuItem value={4}>Hệ Thống</MenuItem>
                      <MenuItem value={5}>Cộng đồng</MenuItem>
                    </Select>
                  </Box>

                  <Box sx={{ width: "40px" }}></Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "#ffffff",
                      }}
                    >
                      Số lượng tham gia
                    </Typography>
                    <TextField
                      size='small'
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      placeholder='Nhập số lượng'
                      sx={{
                        width: "100%",
                        ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "white",
                          fontSize: "15px",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          letterSpacing: "2px",
                          lineHeight: "17.5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "white",
                          "& input": {
                            padding: "7px 13px",
                          },
                          "& input::placeholder": {
                            color: "#9ca3af",
                          },
                          "& fieldset": {
                            borderColor: "#D6B88A",
                            borderRadius: "8px",
                          },
                          "&:hover fieldset": {
                            borderColor: "#D6B88A",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D6B88A",
                          },
                        },
                      }}
                      value={number}
                      onChange={(e) => {
                        setNumber(e.target.value.replace(/\D/g, ""));
                      }}
                    />
                  </Box>
                </Box>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <FormControl fullWidth>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "#ffffff",
                      }}
                    >
                      Ngày bắt đầu
                    </Typography>

                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          right: "4px",
                          top: "7px",
                        }}
                      >
                        <EventIcon
                          style={{ color: "#d6b88a", width: "100%" }}
                        />
                      </Box>
                      <DatePicker
                        selected={startDate}
                        minDate={moment().add(1, "days").toDate()}
                        placeholderText='Chọn ngày bắt đầu'
                        maxDate={endDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat='dd/MM/yyyy'
                        showIcon={true}
                        formatWeekDay={(nameOfDay) => {
                          switch (nameOfDay) {
                            case "Thứ Hai":
                              return "T2";
                            case "Thứ Ba":
                              return "T3";
                            case "Thứ Tư":
                              return "T4";
                            case "Thứ Năm":
                              return "T5";
                            case "Thứ Sáu":
                              return "T6";
                            case "Thứ Bảy":
                              return "T7";
                            case "Chủ Nhật":
                              return "CN";
                            default:
                              break;
                          }
                        }}
                      />
                    </Box>
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: "Be Vietnam Pro",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#ffffff",
                      marginLeft: "4px",
                      marginRight: "4px",
                      marginTop: "22px",
                    }}
                  >
                    ~
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "#ffffff",
                      }}
                    >
                      Ngày kết thúc
                    </Typography>

                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          right: "4px",
                          top: "7px",
                        }}
                      >
                        <EventIcon
                          style={{ color: "#d6b88a", width: "100%" }}
                        />
                      </Box>
                      <DatePicker
                        selected={endDate}
                        minDate={startDate ?? moment().add(1, "days").toDate()}
                        placeholderText='Chọn ngày kết thúc'
                        onChange={(date) => setEndDate(date)}
                        dateFormat='dd/MM/yyyy'
                        showIcon={true}
                        formatWeekDay={(nameOfDay) => {
                          switch (nameOfDay) {
                            case "Thứ Hai":
                              return "T2";
                            case "Thứ Ba":
                              return "T3";
                            case "Thứ Tư":
                              return "T4";
                            case "Thứ Năm":
                              return "T5";
                            case "Thứ Sáu":
                              return "T6";
                            case "Thứ Bảy":
                              return "T7";
                            case "Chủ Nhật":
                              return "CN";
                            default:
                              break;
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              {prizes?.length > 0 ? (
                <>
                  <Typography
                    sx={{
                      fontFamily: "Be Vietnam Pro",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "#ffffff",
                    }}
                  >
                    Giải thưởng
                  </Typography>
                  <Box
                    sx={{
                      padding: "10px",
                      width: "fit-content",
                      minHeight: "110px",
                      overflowY: "scroll",
                      display: "grid",
                      gap: 2,
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      bgcolor: "#493E2D",
                      borderRadius: "5px",
                      border: "1px solid #D6B88A",
                    }}
                  >
                    {prizes.map((item, i) => {
                      return (
                        <Box
                          key={i.toString()}
                          sx={{
                            width: "100px",
                            height: "100px",
                            boxSizing: "border-box",
                            bgcolor: "#2B2112",
                            border: "1px solid #443B2B",
                            borderRadius: "4px",
                            display: "flex",
                            flexDirection: "column",
                            padding: "5px",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <Box onClick={() => {}}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "15px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            ></Box>
                            <Box
                              sx={{
                                width: "100%",
                                height: "45px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ProgressiveImage
                                src={item?.icon ?? ""}
                                styleImg={{
                                  objectFit: "contain",
                                  textAlign: "center",
                                  width: "100%",
                                  height: "45px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                height: "28px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "SVN-Gotham",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  color: "#FFFFFF",
                                  position: "absolute",
                                  top: 0,
                                  right: 5,
                                }}
                              >
                                {item?.prizeQuantity ?? ""}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "SVN-Gotham",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "13px",
                                  lineHeight: "17px",
                                  textAlign: "center",
                                  color: "#FFFFFF",
                                }}
                              >
                                {item?.prizeName ?? ""}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </>
              ) : null}

              {/* <Typography
                sx={{
                  fontFamily: "Be Vietnam Pro",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  color: "#ffffff",
                }}
              >
                Bạn đã chọn: 100 OBD Coin
              </Typography> */}
            </Box>
            <Box display='flex' sx={{ justifyContent: "center" }}>
              <Button
                onClick={handleCreateChallengeRoom}
                style={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "7px 17px",
                    height: "36px",
                    background:
                      "radial-gradient(50% 50% at 50% 50%, #EB4C4C 0%, #AD0606 100%)",
                    boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.35)",
                    borderRadius: "9px",
                    fontFamily: "SVN-Gotham",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "14px",
                    textAlign: "right",
                    color: "#FFEEA3",
                    border: "1px solid #AC4120",
                    textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  Tạo phòng
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
