export const STAFF = 1;
export const HOUSE_HOLD = 2;

export const NV = "NV";
export const HKD = "HKD";
export const HT = "HT";
export const CD = "CD";
export const TYPE_EVENT = 1;
export const TIME_EVENT_ACCOMPLISHED = 1;
export const TIME_EVENT_HAPPENNING = 2;
export const TIME_EVENT_UPCOMING = 3;
export const EVENT_PARTICIPANT = "EVENT_PARTICIPANT";
export const EVENT_LEADERBOARD = "EVENT_LEADERBOARD";
export const NOT_STARTED = 0;
export const STARTED = 1;
export const FINISHED = 2;
export const CANCELLED = 3;
