/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import { createSlice } from "@reduxjs/toolkit";

const musicSlice = createSlice({
  name: "music",
  initialState: {
    src: "",
  },
  reducers: {
    setSrcMusic: (state, { payload }) => {
      state.src = payload ?? "";
    },
  },
  extraReducers: (builder) => {},
});

export const { setSrcMusic } = musicSlice.actions;

export default musicSlice;
