/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import { createSlice } from "@reduxjs/toolkit";
import { loginAction, logoutAction } from "./auth.actions";
import { LOCALE_KEY, getStorageValue } from "../localStorage/useLocalStorage";

const tokenStorage = getStorageValue(LOCALE_KEY.token);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: "idle",
    user: null,
    token: tokenStorage ?? '',
    error: null,
  },
  reducers: {
    setTokenAction: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.loading = "pending";
        state.token = null;
        state.user = null;
        state.error = null;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error?.message;
      })
      .addCase(logoutAction, (state) => {
        state.loading = "idle";
        state.token = null;
        state.user = null;
      });
  },
});

export const { setTokenAction } = authSlice.actions;
export default authSlice;
