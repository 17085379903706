// @ts-nocheck
import {Box, Container, Grid, Typography} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import CupIcon from "assets/images/cup.svg";
import ArenaRankingComponent from "components/ArenaRankingComponent";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { CD, HKD, HT, NV } from "config/constants";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetMusic, checkAuthenAction } from "redux/users/user.actions";
import { SearchOutlined } from "@mui/icons-material";
import InfoUserModal from "components/Modal/InfoUserModal";
import SearchArenaRankingModal from "components/Modal/SearchArenaRankingModal";
import {
  configIndexsSelector,
  indexInfoRankUserSelector,
  userSelector,
} from "redux/users/user.selectors";
import {
  setCompareToFirstInfoUser,
  setIndexInfoUserAction,
} from "redux/users/user.slice";
import {SelectByScope, SelectByScopeCD} from "utils/common";
import { getBorderBox } from "../Home";
import { setSrcMusic } from "redux/musics/music.slice";
import SelectItemComponent from "components/Selects/SelectItemComponent";
import EventIcon from "@mui/icons-material/Event";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import InputBase from "@mui/material/InputBase";

export default function RankingTableAll() {
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const [listRankHKD, setListRankHKD] = useState([]);
  const [listRankHT, setListRankHT] = useState([]);
  const [listRankCD, setListRankCD] = useState([]);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const infoUserSelectDetail = useSelector(indexInfoRankUserSelector);
  const [valueCallApi, setValueCallApi] = useState();
  const profileUser = useSelector(userSelector);
  const SelectByIndex = useSelector(configIndexsSelector);
  const [valueIndexHKD, setValueIndexHKD] = useState(1);
  const [valueIndexHT, setValueIndexHT] = useState(1);
  const [valueIndexCD, setValueIndexCD] = useState(1);
  const [valueIndexSearch, setValueIndexSearch] = useState(1);
  const [valueScope, setValueScope] = useState(SelectByScope[0].value);
  const [startDateNV, setStartDateNV] = useState(null);
  const [endDateNV, setEndDateNV] = useState(null);
  const [startDateHKD, setStartDateHKD] = useState(null);
  const [endDateHKD, setEndDateHKD] = useState(null);
  const [startDateHT, setStartDateHT] = useState(null);
  const [endDateHT, setEndDateHT] = useState(null);
  const [listRankNV, setListRankNV] = useState([]);
  const [valueIndexNV, setValueIndexNV] = useState(1);
  const [textSearchNV, setTextSearchNV] = useState('');
  const [textSearchHKD, setTextSearchHKD] = useState('');
  const [textSearchHT, setTextSearchHT] = useState('');
  const [scopeNV, setScopeNV] = useState(SelectByScope.slice(0)[0].value);
  const [scopeHKD, setScopeHKD] = useState(SelectByScope.slice(1)[0].value);
  const [scopeHT, setScopeHT] = useState(SelectByScope.slice(2)[0].value);
  const [selectScope, setSelectScope] = useState(SelectByScope);


  const tabRanking = [
    {
      title: "NHÂN VIÊN",
      value: NV,
      listRank: listRankNV,
      setListRank: setListRankNV,
      tabShowInfo: 1,
      valueSelected: valueIndexNV,
      setValueSelected: setValueIndexNV,
      startDate: startDateNV,
      endDate: endDateNV,
      textSearch: textSearchNV,
      paramSearchScope: scopeNV,
    },
    {
      title: "HỘ KINH DOANH",
      value: HKD,
      listRank: listRankHKD,
      setListRank: setListRankHKD,
      tabShowInfo: 1,
      valueSelected: valueIndexHKD,
      setValueSelected: setValueIndexHKD,
      startDate: startDateHKD,
      endDate: endDateHKD,
      textSearch: textSearchHKD,
      paramSearchScope: scopeHKD,
    },
    {
      title: "HỆ THỐNG",
      value: HT,
      listRank: listRankHT,
      setListRank: setListRankHT,
      tabShowInfo: 2,
      valueSelected: valueIndexHT,
      setValueSelected: setValueIndexHT,
      startDate: startDateHT,
      endDate: endDateHT,
      textSearch: textSearchHT,
      paramSearchScope: scopeHT,
    },
  ];

  const setStartDateChoose = (type, value, key) => {
    switch (type) {
      case NV:
        setStartDateNV(value);
        break;
      case HKD:
        setStartDateHKD(value);
        break;
      case HT:
        setStartDateHT(value);
        break;
      default:
        return null;
    }
  }

  const setEndDateChoose = (type, value) => {
    switch (type) {
      case NV:
        setEndDateNV(value);
        break;
      case HKD:
        setEndDateHKD(value);
        break;
      case HT:
        setEndDateHT(value);
        break;
      default:
        return null;
    }
  }

  const getStartDateChoose = (type) => {
    switch (type) {
      case NV:
        return startDateNV;
      case HKD:
        return startDateHKD
      case HT:
        return startDateHT
      default:
        return null;
    }
  }

  const getEndDateChoose = (type) => {
    switch (type) {
      case NV:
        return endDateNV;
      case HKD:
        return endDateHKD;
      case HT:
        return endDateHT;
      default:
        return null;
    }
  }

  const getTextSearch = (type) => {
    switch (type) {
      case NV:
        return textSearchNV;
      case HKD:
        return textSearchHKD;
      case HT:
        return textSearchHT;
      default:
        return '';
    }
  }

  const setTextSearch = useMemo(() => (type, value) => {
    switch (type) {
      case 'NV':
        setTextSearchNV(value);
        break;
      case 'HKD':
        setTextSearchHKD(value);
        break;
      case 'HT':
        setTextSearchHT(value);
        break;
      default:
        return null;
    }
  }, [setTextSearchNV, setTextSearchHKD, setTextSearchHT]);

  const setScopeChoose = (type, value) => {
    switch (type) {
      case NV:
        setScopeNV(value);
        break;
      case HKD:
        setScopeHKD(value);
        break;
      case HT:
        setScopeHT(value);
        break;
      default:
        return null;
    }
  }

  const getScopeChoose = (type) => {

    switch (type) {
      case NV:
        return scopeNV;
      case HKD:
        return scopeHKD;
      case HT:
        return scopeHT;
      default:
        return scopeNV;
    }
  }

  const selectScopeArr = {
    'NV': SelectByScope.slice(0),
    'HKD': SelectByScope.slice(1),
    'HT': SelectByScope.slice(2),
  };

  useEffect(() => {
    dispatch(checkAuthenAction());
    dispatch(asyncGetMusic({ page: "profile" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
  }, []);

  return (
    <Container maxWidth={false} sx={{ flex: 1, backgroundColor: "#1E1E1E" }}>
      <Grid container columnSpacing={3}>
        {tabRanking.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              background={undefined}
              arenaInfo={undefined}
              headerTitle={
                <Box paddingX={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                  }}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <TitleCustomComponent title={item.title} />
                    <InputBase
                      sx={{ flex: 1, color: "white", marginTop: "4px", border: '1px solid #eba43f', borderRadius: '4px', paddingLeft: '4px' }}
                      placeholder='Tìm kiếm'
                      inputProps={{
                        "aria-label": "Tìm kiếm",
                      }}
                      onChange={(e) => setTextSearch(item.value, e.target.value)}
                      value={getTextSearch(item.value)}
                    />
                    <SelectItemComponent
                      items={selectScopeArr[item.value]}
                      value={getScopeChoose(item.value)}
                      setValue={(value) => {setScopeChoose(item.value, value)}}
                    />
                  </Grid>


                  <Grid
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '8px',
                    }}
                  >
                    <Grid
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: '4px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ position: "relative" }}>
                            <Box
                              sx={{
                                position: "absolute",
                                right: "4px",
                                top: "7px",
                              }}
                            >
                              <EventIcon
                                style={{ color: "#d6b88a", width: "100%" }}
                              />
                            </Box>
                            <DatePicker
                              selected={getStartDateChoose(item.value)}
                              placeholderText='Từ ngày'
                              onChange={(date) => setStartDateChoose(item.value, date)}
                              dateFormat='dd/MM/yyyy'
                              showIcon={true}
                              formatWeekDay={(nameOfDay) => {
                                switch (nameOfDay) {
                                  case "Thứ Hai":
                                    return "T2";
                                  case "Thứ Ba":
                                    return "T3";
                                  case "Thứ Tư":
                                    return "T4";
                                  case "Thứ Năm":
                                    return "T5";
                                  case "Thứ Sáu":
                                    return "T6";
                                  case "Thứ Bảy":
                                    return "T7";
                                  case "Chủ Nhật":
                                    return "CN";
                                  default:
                                    break;
                                }
                              }}
                            />
                          </Box>
                        </Box>

                        <Typography
                          sx={{
                            fontFamily: "Be Vietnam Pro",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: "20px",
                            color: "#ffffff",
                          }}
                        >
                          ~
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ position: "relative" }}>
                            <Box
                              sx={{
                                position: "absolute",
                                right: "4px",
                                top: "7px",
                              }}
                            >
                              <EventIcon
                                style={{ color: "#d6b88a", width: "100%" }}
                              />
                            </Box>
                            <DatePicker
                              selected={getEndDateChoose(item.value)}
                              placeholderText='Đến ngày'
                              onChange={(date) => setEndDateChoose(item.value, date)}
                              dateFormat='dd/MM/yyyy'
                              showIcon={true}
                              formatWeekDay={(nameOfDay) => {
                                switch (nameOfDay) {
                                  case "Thứ Hai":
                                    return "T2";
                                  case "Thứ Ba":
                                    return "T3";
                                  case "Thứ Tư":
                                    return "T4";
                                  case "Thứ Năm":
                                    return "T5";
                                  case "Thứ Sáu":
                                    return "T6";
                                  case "Thứ Bảy":
                                    return "T7";
                                  case "Chủ Nhật":
                                    return "CN";
                                  default:
                                    break;
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <SelectItemComponent
                      items={SelectByIndex}
                      value={item.valueSelected}
                      setValue={item.setValueSelected}
                    />
                  </Grid>
                </Box>
              }
              headerIcon={<img src={CupIcon} alt="..." />}
              borderImageBox={getBorderBox()}
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              isArena={undefined}
            >
              <ArenaRankingComponent
                refList={listRef}
                isShowMyRank={false}
                isEmployeeList={false}
                listRank={item?.listRank}
                setListRank={item?.setListRank}
                setOpenModalInfo={setOpenModalInfo}
                switchCallApi={item?.value}
                paramSearchScope={item.paramSearchScope}
                tabShowInfo={item?.tabShowInfo}
                paramSearchIndex={item?.valueSelected}
                startDate={item.startDate}
                endDate={item.endDate}
                isHasDate={true}
                paramSearchText={item.textSearch}
              />
            </BoxWrapperComponent>
          </Grid>
        ))}
        {infoUserSelectDetail ? (
          <InfoUserModal
            onClose={() => {
              dispatch(setIndexInfoUserAction(null));
              dispatch(setCompareToFirstInfoUser(null));
              setOpenModalInfo(false);
            }}
            onOpen={openModalInfo}
            infoUser={infoUserSelectDetail}
          />
        ) : null}
        <SearchArenaRankingModal
          open={openModalSearch}
          onClose={() => setOpenModalSearch(false)}
          setOpenModalInfo={setOpenModalInfo}
          valueScope={SelectByScopeCD[0].value}
          valueIndex={valueIndexSearch}
          switchCallApi={valueCallApi}
        />
      </Grid>
    </Container>
  );
}
