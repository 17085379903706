import { getAxiosClientWithToken } from "api";

export const listInven = (params) => {
  return getAxiosClientWithToken().get(`inventories`, { params });
};

export const detailInven = (id) => {
  return getAxiosClientWithToken().get(`inventories/${id}`);
};

export const awardInven = (params) => {
  return getAxiosClientWithToken().post("inventories/awarded", params);
};

export const exchangeDetail = (id) => {
  return getAxiosClientWithToken().get(
    `inventories/exchange?inventoryId=${id}`
  );
};

export const exchange = (params) => {
  return getAxiosClientWithToken().post("inventories/exchange", params);
};

export const scheduleUsage = (params) => {
  return getAxiosClientWithToken().post("inventories/schedule-usage", params);
};

export const getCurrency = () => {
  return getAxiosClientWithToken().get("currencies");
};
