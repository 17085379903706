import { Box } from "@mui/material";
import React from "react";
import ProgressiveImage from "./ProgressiveImage";

export const AvatarRankModal = ({
  srcAvatar,
  circleFrame,
  isStaff,
  width = "46px",
  height = "44px",
  boxStyle = {},
  imgStyle = {},
  isLoading = true,
}) => {
  return (
    <Box
      sx={{
        width: width,
        height: height,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        sx={{
          border: "1px solid transparent",
          borderImage: `url(${circleFrame})`,
          backgroundSize: "auto",
          boxSizing: "border-box",
          borderImageSlice: "44 42",
          borderImageWidth: "12px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100%",
          height: "100%",
          ...boxStyle,
        }}
      >
        <ProgressiveImage
          src={srcAvatar}
          width={isStaff ? "80.5%" : "80%"}
          isLoading={isLoading}
          styleImg={{
            textAlign: "center",
            color: "transparent",
            borderRadius: "45%",
            // position: isStaff ? "relative" : "absolute",
            // top: isStaff ? 0 : "5px",
            // left: isStaff ? 1 : "5px",
            // bottom: isStaff ? 0 : '4px',
            ...imgStyle,
          }}
        />
      </Box>
    </Box>
  );
};
