import { Box, Grid, Modal } from "@mui/material";
import React from "react";
import ProgressiveImage from "../ProgressiveImage";
import { STAFF } from "../../config/constants";
import { AvatarRankModal } from "../AvatarRankModal";
import TextComponent from "../Texts/TextComponent";
import { IconTextComponent } from "../IconTextComponent";
import { TitleComponent } from "../titles/TitleComponent";
import { KeyValueComponent } from "../titles/KeyValueComponent";
import { useSelector } from "react-redux";
import {
  isModalInfoUserCenterSelector,
  tabShowInfoSelector,
} from "../../redux/popup/popup.selector";
import { compareToFirstInfoUserSelector } from "redux/users/user.selectors";

function InfoUserModal({ onClose, onOpen, infoUser }) {
  const isModalInfoUserCenter = useSelector(isModalInfoUserCenterSelector);
  const tabShowInfo = useSelector(tabShowInfoSelector);
  const compareToFirst = useSelector(compareToFirstInfoUserSelector);

  const getTransForm = () => {
    switch (tabShowInfo) {
      case 1:
        return {
          left: "25.5px",
        };
      case 2:
        return {
          left: "calc(100% / 3 + 17px)",
        };
      default:
        return {
          right: "25.5px",
        };
    }
  };

  return (
    <Modal open={onOpen} onClose={onClose} disableAutoFocus={true}>
      <Box
        sx={{
          position: "absolute",
          top: isModalInfoUserCenter ? "50%" : "60%",
          right: isModalInfoUserCenter ? "0%" : getTransForm(),
          maxHeight: "95%",
          width: "40%",
          maxWidth: isModalInfoUserCenter
            ? "100%"
            : {
                xs: "calc(100% - 48px)",
                sm: "calc(100% - 48px)",
                md: "calc(100% / 3 - 34px)",
                lg: "calc(100% / 3 - 34px)",
                xl: "calc(100% / 3 - 34px)",
              },
          transform: isModalInfoUserCenter
            ? "translate(-75%, -50%)"
            : "translate(0%, -50%)",
          bgcolor: "white",
          p: 1,
          background: "#302A20",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
          border: "1px solid #FFEEA3",
          borderRadius: "5px",
        }}
      >
        <Grid container direction={"column"}>
          <Grid xs item container>
            <Grid xs={"auto"} item>
              <AvatarRankModal
                srcAvatar={infoUser?.rank?.circleAvatar ?? ""}
                circleFrame={infoUser?.rank?.circleFrame ?? ""}
                isStaff={infoUser?.type === STAFF}
                boxStyle={{
                  borderImageSlice: "44 46",
                  borderImageWidth: "28px",
                }}
                width="100px"
                height="100px"
              />
            </Grid>
            <Grid xs item container direction={"column"}>
              <Grid xs item display={"flex"} alignItems={"center"}>
                <ProgressiveImage
                  src={infoUser?.rank?.icon ?? ""}
                  width="45px"
                />
                <Box>
                  <TextComponent
                    content={infoUser?.rank?.name ?? ""}
                    styleText={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                    maxLine="1"
                  />
                  <TextComponent
                    content={infoUser?.name ?? ""}
                    styleText={{
                      textTransform: "uppercase",
                      fontSize: "18px",
                    }}
                    maxLine="1"
                  />
                </Box>
              </Grid>
              {infoUser?.community?.name || infoUser?.system?.name ? (
                <Grid xs item display={"flex"}>
                  {infoUser?.community ? (
                    <IconTextComponent
                      icon={infoUser?.community?.rank?.icon}
                      title={infoUser?.community?.name ?? ""}
                      styleBox={{ marginRight: 1, marginLeft: 1 }}
                      styleText={{ color: "#FED01B" }}
                    />
                  ) : null}

                  {infoUser?.system ? (
                    <IconTextComponent
                      icon={infoUser?.system?.rank?.icon}
                      title={infoUser?.system?.name ?? ""}
                    />
                  ) : null}
                </Grid>
              ) : null}

              {infoUser?.household?.name ? (
                <Grid xs item>
                  <IconTextComponent
                    icon={infoUser?.household?.rank?.icon}
                    title={infoUser?.household?.name ?? ""}
                    styleBox={{ marginRight: 1, marginLeft: 1 }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <TitleComponent title="Chỉ số" />
          <KeyValueComponent
            title={"So với Top 1:"}
            value={`${compareToFirst ?? 0}%`}
          />
          <KeyValueComponent
            title={"Doanh số tích lũy:"}
            value={`${infoUser?.indexes?.revenueReceived ?? ""}`}
          />
          <KeyValueComponent
            title={"Doanh số xác nhận:"}
            value={`${infoUser?.indexes?.revenueConfirmed ?? 0}  (${
              infoUser?.indexes?.revenueConfirmedPercent
            }%)`}
          />
          <KeyValueComponent
            title={"Doanh số hoàn:"}
            value={`${infoUser?.indexes?.revenueReturned ?? 0} (${
              infoUser?.indexes?.revenueReturnedPercent
            }%)`}
          />
          <KeyValueComponent
            title={"Doanh số hủy:"}
            value={`${infoUser?.indexes?.revenueCanceled ?? 0} (${
              infoUser?.indexes?.revenueCanceledPercent
            }%)`}
          />
        </Grid>
      </Box>
    </Modal>
  );
}

export default React.memo(InfoUserModal);
