import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkAuthen,
  detailCommunity,
  detailHousehold,
  detailSystem,
  getCommunitiesUserInfo,
  getConfigsIndex,
  getHouseholdsUserInfo,
  getMusics,
  getSystemsUserInfo,
  getUser,
  getUserInfo,
  listRankUser,
  listRankUserCommunity,
  listRankUserHousehold,
  listRankUserSystem,
} from "../../api/user";
import {
  setCompareToFirstInfoUser,
  setConfigIndexs,
  setDataCommunity,
  setDataHousehold,
  setDataSystem,
  setIndexInfoUserAction,
} from "./user.slice";
import { CD, HKD, HT } from "config/constants";

export const fetchUser = createAsyncThunk(
  "users/:id",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getUser(id);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkAuthenAction = createAsyncThunk(
  "auth/checkAuth",
  async () => {
    const response = await checkAuthen();
    return response.data;
  }
);

export const getRankUsers = createAsyncThunk(
  "auth/leaderboards",
  async (params) => {
    const response = await listRankUser(params);
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return [];
  }
);

export const getRankUsersHousehold = createAsyncThunk(
  "household/leaderboards",
  async (params) => {
    const response = await listRankUserHousehold(params);
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return [];
  }
);

export const getRankUsersSystem = createAsyncThunk(
  "system/leaderboards",
  async (params) => {
    const response = await listRankUserSystem(params);
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return [];
  }
);

export const getRankUsersCommunity = createAsyncThunk(
  "community/leaderboards",
  async (params) => {
    const response = await listRankUserCommunity(params);
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return [];
  }
);

export const getDataHousehold = createAsyncThunk(
  "households/detail",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await detailHousehold();
      const { data, success } = response.data;
      if (success) {
        dispatch(setDataHousehold(data));
        return true;
      }
      return false;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataSystem = createAsyncThunk(
  "systems/detail",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await detailSystem();
      const { data, success } = response.data;
      if (success) {
        dispatch(setDataSystem(data));
        return true;
      }
      return false;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataCommunity = createAsyncThunk(
  "community/detail",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await detailCommunity();
      const { data, success } = response.data;
      if (success) {
        dispatch(setDataCommunity(data));
        return true;
      }
      return false;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncUserInfo = createAsyncThunk("user/detail", async (id) => {
  const response = await getUserInfo(id);
  if (response?.data?.success) {
    return response?.data?.data;
  }

  return null;
});

export const asyncHouseholdsUserInfo = createAsyncThunk(
  "user/households/detail",
  async (id) => {
    const response = await getHouseholdsUserInfo(id);
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return null;
  }
);

export const asyncSystemsUserInfo = createAsyncThunk(
  "user/systems/detail",
  async (id) => {
    const response = await getSystemsUserInfo(id);
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return null;
  }
);

export const asyncCommunitiesUserInfo = createAsyncThunk(
  "user/communities/detail",
  async (id) => {
    const response = await getCommunitiesUserInfo(id);
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return null;
  }
);

const callApiInfoUser = (switchCallApi, id, isEvent) => {
  switch (switchCallApi) {
    case HKD:
      return getHouseholdsUserInfo(id);
    case HT:
      return getSystemsUserInfo(id);
    case CD:
      return getCommunitiesUserInfo(id);
    default:
      return getUserInfo(id, isEvent);
  }
};

export const asyncGetUserByScope = createAsyncThunk(
  "user/scope/detail",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { id, scope, isEvent, compareToFirst } = params;
      const response = await callApiInfoUser(scope, id, isEvent);
      const { data, success } = response.data;
      if (success) {
        dispatch(setIndexInfoUserAction(data));
        dispatch(setCompareToFirstInfoUser(compareToFirst));
        return true;
      }
      return false;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncGetMusic = createAsyncThunk("music", async (params) => {
  const response = await getMusics(params);
  if (response?.data?.success) {
    return response?.data?.data;
  }
  return "";
});

export const asyncGetConfigIndex = createAsyncThunk(
  "get/index",
  async (params, { dispatch, rejectWithValue }) => {
    const response = await getConfigsIndex(params);
    if (response?.data?.success) {
      dispatch(setConfigIndexs(response?.data?.data ?? []));
    }
  }
);
