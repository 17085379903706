import { getAxiosClientWithToken } from "api";

export const challengeRoomsStore = (params) => {
  return getAxiosClientWithToken().post(`/challenge-rooms/store`, params);
};

export const challengeRoomsList = (params) => {
  return getAxiosClientWithToken().get(`/challenge-rooms`, { params });
};

export const challengeRoomConfigs = (params) => {
  return getAxiosClientWithToken().get(`/challenge-room-configs`, { params });
};

export const challengesPrize = (params) => {
  return getAxiosClientWithToken().get(`/challenges/prize`, { params });
};

export const challengesJoin = (id) => {
  return getAxiosClientWithToken().post(`/challenge-rooms/${id}/join`);
};

export const challengesRemoveParticipant = (id, participantId) => {
  return getAxiosClientWithToken().delete(`/challenge-rooms/${id}/remove/${participantId}`);
};

export const detailChallengeRoom = (id) => {
  return getAxiosClientWithToken().get(`/challenge-rooms/${id}`);
};

export const confirmChallengeRoom = ({ id, isConfirmed }) => {
  return getAxiosClientWithToken().post(
    `/challenge-rooms/${id}/change-status`,
    { isConfirmed: isConfirmed }
  );
};

export const challengesLeave = (id) => {
  return getAxiosClientWithToken().post(`/challenge-rooms/${id}/leave`);
};

export const updateBetRatio = ({ id, participants }) => {
  return getAxiosClientWithToken().post(`challenge-rooms/${id}/update`, {
    participants: participants,
  });
};

export const challengeLeaderboard = (id) => {
  return getAxiosClientWithToken().get(`challenge-rooms/${id}/leaderboard`);
};
