import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import InfoIcon from "assets/images/info.svg";
import { useEffect, useRef, useState } from "react";

import { SearchOutlined } from "@mui/icons-material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CloseIcon from "@mui/icons-material/Close";
import Backgroundimage from "assets/images/background1.svg";
import CupIcon from "assets/images/cup.svg";
import FacebookIcon from "assets/images/facebook.png";
import DuoAxe from "assets/images/ranking/duo-axe.svg";
import SaveIcon from "assets/images/save.png";
import IconWarning from "assets/images/warning-icon.png";
import ArenaRankingComponent from "components/ArenaRankingComponent";
import { AvatarRank } from "components/AvatarRank";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import InfoUserModal from "components/Modal/InfoUserModal";
import SearchArenaRankingModal from "components/Modal/SearchArenaRankingModal";
import SelectItemComponent from "components/Selects/SelectItemComponent";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { colors } from "config/colors/colors";
import { HT, STAFF } from "config/constants";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FacebookShareButton } from "react-share";
import { popupListStaffSelector } from "redux/popup/popup.selector";
import { setOpen, setOpenModalListStaff } from "redux/popup/popup.slice";
import { asyncGetMusic, getDataSystem } from "redux/users/user.actions";
import {
  configIndexsSelector,
  indexInfoRankUserSelector,
  listRankUserSelector,
  rankCurrentSelector,
  userDataSelector,
  userSelector,
} from "redux/users/user.selectors";
import {
  setCompareToFirstInfoUser,
  setIndexInfoUserAction,
} from "redux/users/user.slice";
import {
  SelectByScopeHT,
  WindowSize,
  formatNumber,
  getExpProgress,
  scrollParentToChild,
} from "utils/common";
import { getBorderBox } from "../Home";
import { setSrcMusic } from "redux/musics/music.slice";
import "../../../config/styles/jump.css";
import * as htmlToImage from "html-to-image";

export default function SystemProfile() {
  const profileUser = useSelector(userSelector);
  const isOpenModalListStaff = useSelector(popupListStaffSelector);
  const { system } = useSelector(userDataSelector);

  const dispatch = useDispatch();
  const listRef = useRef(null);
  const [listRank, setListRank] = useState([]);
  const [listStaff, setListStaff] = useState([]);
  const [valueScope, setValueScope] = useState(SelectByScopeHT[0].value);
  const [valueIndex, setValueIndex] = useState(1);
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const rankCurrent = useSelector(rankCurrentSelector);
  const [staffIndex, setStaffIndex] = useState(1);
  const infoUserSelectDetail = useSelector(indexInfoRankUserSelector);
  const windowWidth = WindowSize();
  const SelectByIndex = useSelector(configIndexsSelector);

  useEffect(() => {
    dispatch(asyncGetMusic({ page: "profile" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
    dispatch(getDataSystem());
  }, []);

  const [openModalInfo, setOpenModalInfo] = useState(false);

  const scrollIndexMyRank = () => {
    const findIdUser = listRank.findIndex(
      (value) => value.id === profileUser?.system?.id
    );
    if (findIdUser === -1) {
      listRef?.current?.props?.next(true, true);
      return;
    }

    if (
      listRef?.current &&
      listRef?.current?.el &&
      listRef?.current?.el?.children?.length > 0
    ) {
      const element =
        listRef?.current?.el?.children[findIdUser > 3 ? findIdUser - 3 : 0];
      if (element) {
        scrollParentToChild(listRef?.current?.el, element);
      }
    }
  };

  const domEl = useRef(null);

  function filter(node) {
    if (node.className) {
      return !node.className.includes("download-avatar");
    }
    return true;
  }

  const handleDownload = async () => {
    const dataUrl = await htmlToImage.toPng(domEl.current, { filter: filter });
    const link = document.createElement("a");
    link.download = "rank-he-thong.png";
    link.href = dataUrl;
    link.click();
  };

  return (
    <>
      <Modal
        open={isOpenModalListStaff}
        onClose={() => dispatch(setOpenModalListStaff(false))}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          border: "2px solid #000",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            bgcolor: "white",
            p: 4,
            background: "#302A20",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
            border: "1px solid #FFEEA3",
            borderRadius: "5px",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box display="flex">
              <img src={DuoAxe} width={70} alt="..." />
              <Box display="flex" flexDirection="column">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontFamily: "SVN-Gotham",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "25px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "#FFF8E0",
                    alignSelf: "center",
                  }}
                >
                  NHÂN VIÊN
                </Typography>
                <Typography
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                    fontFamily: "SVN-Gotham",
                    color: "white",
                  }}
                >
                  {system?.staffs?.total ?? ""}
                </Typography>
              </Box>
            </Box>
            {/* <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                marginBottom: 1,
                paddingTop: "2px",
              }}
            >
              <SelectItemComponent
                items={SelectByIndex}
                value={staffIndex}
                setValue={setStaffIndex}
              />
            </Box> */}
          </Box>

          <Button
            onClick={() => dispatch(setOpenModalListStaff(false))}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              borderBottom: "1px solid #FFEEA3",
              borderLeft: "1px solid #FFEEA3",
              borderRadius: "5px",
              height: "30px",
            }}
          >
            <CloseIcon color="warning" />
          </Button>
          <ArenaRankingComponent
            isEmployeeList={true}
            setOpenModalInfo={setOpenModalInfo}
            refList={listRef}
            isShowMyRank={false}
            listRank={listStaff}
            setListRank={setListStaff}
            paramSearchScope={2}
            // switchCallApi={HT}
            paramSearchIndex={staffIndex}
          />
        </Box>
      </Modal>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: colors.bg_black,
          flex: 1,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Grid container columnSpacing={3} sx={{}}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              headerTitle={
                <Box
                  sx={{
                    display: "grid",
                    gap: 0,
                    gridTemplateColumns: "repeat(1, 1fr)",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 10,
                      fontWeight: 700,
                      fontFamily: "SVN-Gotham",
                      color: "white",
                      textTransform: "uppercase",
                    }}
                  >
                    {system?.rank?.name ?? ""}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 700,
                      fontFamily: "SVN-Gotham",
                      color: "white",
                    }}
                  >
                    {system?.name ?? ""}
                  </Typography>
                </Box>
              }
              headerIcon={
                <img
                  src={system?.rank?.icon ?? IconWarning}
                  width={50}
                  alt="..."
                />
              }
              borderImageBox={getBorderBox()}
              headerBtnRight={undefined}
              bottomLeftBtn={
                <FacebookShareButton
                  url={system?.rank?.avatar}
                  quote={"quat"}
                  hashtag={"#OBD"}
                >
                  <img src={FacebookIcon} width={100} alt="..." />
                </FacebookShareButton>
              }
              bottomRightBtn={
                <Box onClick={handleDownload} sx={{ cursor: "pointer" }}>
                  <img src={SaveIcon} width={130} alt="..." />
                </Box>
              } // boxStyle={{ border: "1px" }}
              background={Backgroundimage}
              boxStyle={{
                borderImageSlice: "21 21",
                borderImageWidth: "20px",
                borderImageOutset: "11px",
                borderStyle: "solid",
                position: "relative",
                borderRadius: "9px",
                border: "1px solid transparent",
              }}
              arenaInfo={undefined}
              isArena={false}
              htmlRef={domEl}
            >
              {/* <Box
                sx={{
                  margin: "auto",
                  height: "100%",
                  maxHeight: 550,
                }}
              >
                {system?.rank?.circleAvatar ? (
                  <Box>
                    <AvatarRank
                      srcAvatar={system?.rank?.circleAvatar ?? ""}
                      circleFrame={system?.rank?.circleFrame ?? ""}
                      isStaff={system?.type === STAFF}
                      isHousehold={true}
                    />
                  </Box>
                ) : (
                  <div style={{ position: "relative" }}>
                    <img src={IconWarning} width={"100px"} alt="..." />
                  </div>
                )}
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    fontFamily: "SVN-Gotham",
                    color: "#FFEEB5",
                    textAlign: "center",
                  }}
                >
                  {system?.name ?? ""}
                </Typography>
                <Typography
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                    fontFamily: "SVN-Gotham",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {system?.rank?.name ?? ""}
                </Typography>
              </Box> */}
              <div id="avatar">
                <Box
                  sx={{
                    margin: "auto",
                    padding: "auto",
                    // backgroundImage: `url(${BackgroundImage})`,
                  }}
                >
                  <img
                    src={system?.rank?.avatar ?? ""}
                    style={{
                      objectFit: "inherit",
                      // width: "auto",
                      maxHeight: 520,
                      margin: "auto",
                    }}
                    className="up-down"
                    alt="..."
                  />
                </Box>
              </div>
            </BoxWrapperComponent>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              headerTitle={
                <Box paddingX={1}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 700,
                      fontFamily: "SVN-Gotham",
                      color: "white",
                    }}
                  >
                    THÔNG TIN
                  </Typography>
                </Box>
              }
              headerIcon={<img src={InfoIcon} alt="..." />}
              borderImageBox={getBorderBox(1)}
              headerBtnRight={
                system?.isShowProgress ? (
                  <Box
                    display="flex"
                    sx={{
                      alignItems:
                        windowWidth > 1240 || windowWidth < 900
                          ? "center"
                          : "start",
                      flexDirection:
                        windowWidth > 1240 || windowWidth < 900
                          ? "row"
                          : "column",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: 35,
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          padding: "5px 5px 5px 5px",
                          color: "white",
                          fontFamily: "SVN-Gotham",
                        }}
                      >
                        {system?.progress +
                          "/" +
                          formatNumber(system?.nextRank?.revenue)}
                      </Typography>
                    </Box>
                    <Box sx={{ width: "120px", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={getExpProgress(
                          system?.progress,
                          system?.nextRank?.revenue
                        )}
                        sx={{
                          borderRadius: "5px",
                          backgroundColor: "gray",
                          "& .MuiLinearProgress-bar": {
                            backgroundImage: `linear-gradient(to left, #ff7a00, #ff9200, #ffa900, #ffc000, #ffd600)`,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                ) : undefined
              }
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              boxStyle={{ maxHeight: "500px" }}
              background={undefined}
              arenaInfo={undefined}
              isArena={false}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: "repeat(2, 1fr)",
                  overflow: "scroll",
                  maxHeight: 540,
                  padding: "10px",
                  paddingBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    // backgroundColor: "gray",
                    marginBottom: 3,
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontFamily: "Be-Vietnam-Pro",
                      color: "#9C7F5C",
                      fontSize: 16,
                    }}
                  >
                    Lịch sử thành lập:
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontFamily: "Be-Vietnam-Pro",
                      color: "#9C7F5C",
                      fontSize: 16,
                    }}
                  >
                    {system?.description ?? ""}
                  </Typography>
                </Box>
                <Box>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Chủ tịch :
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.leader?.lead?.name ?? ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Phó chủ tịch:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.leader?.viceLead?.name ?? ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Số nhân viên:
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              fontWeight: 700,
                              fontFamily: "Be Vietnam Pro",
                              color: "#DBB487",
                              fontSize: 16,
                            }}
                          >
                            {system?.staffs?.total ?? ""}
                          </Typography>
                          <div
                            onClick={() =>
                              dispatch(setOpenModalListStaff(true))
                            }
                          >
                            <ArrowRightAltIcon
                              style={{ width: "20px", cursor: "pointer" }}
                              color="primary"
                            />
                          </div>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Cộng đồng:
                        </Typography>
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Grid item display="flex">
                            <img
                              src={system?.community?.rank?.icon ?? IconWarning}
                              width={20}
                              alt="..."
                            />
                            <Link to={{ pathname: "/community" }}>
                              <Typography
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",
                                  fontWeight: 700,
                                  fontFamily: "Be Vietnam Pro",
                                  color: "#DBB487",
                                  fontSize: 16,
                                  marginLeft: 1,
                                }}
                              >
                                {system?.community?.name ?? ""}
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridAutoColumns: "1fr",
                          margin: "0 20px 0 20px ",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundImage: `linear-gradient(to right, #ffffff, #f7eaf4, #f9d3da, #f4bfb2, #dbb487)`,
                            clipPath: `polygon(100% 95%, 0% 100%, 100% 100%)`,
                            marginBottom: "10px",
                            gridColumn: "span 3",
                            gridRow: "1",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            gridRow: "1",
                            gridColumn: "span 2",
                            fontWeight: 700,
                            fontFamily: "SVN-Gotham",
                            color: "#FFF8E0",
                            fontSize: 16,
                            textAlign: "center",
                          }}
                        >
                          CHỈ SỐ
                        </Typography>
                        <Box
                          sx={{
                            backgroundImage: `linear-gradient(to left, #ffffff, #f7eaf4, #f9d3da, #f4bfb2, #dbb487)`,
                            clipPath: `polygon(0 95%, 0% 100%, 100% 100%)`,
                            marginBottom: "10px",
                            gridColumn: "span 3",
                            gridRow: "1",
                          }}
                        ></Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Doanh số tích lũy:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.indexes?.revenueReceived}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Doanh số xuất đi:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.indexes?.outboundRevenue}%
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          CPQC:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.indexes?.adCost}%
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          CP nhân sự:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.indexes?.staffCost}%
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          CP giá vốn:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.indexes?.capitalCost}%
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          DS CSKH:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.indexes?.customerCareRevenue}%
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          DS thành công:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.indexes?.successRevenue}%
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Be-Vietnam-Pro",
                            color: "#9C7F5C",
                            fontSize: 16,
                          }}
                        >
                          Lợi nhuận ước chừng:
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            fontWeight: 700,
                            fontFamily: "Be Vietnam Pro",
                            color: "#DBB487",
                            fontSize: 16,
                          }}
                        >
                          {system?.indexes?.estimatedProfit}%
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </BoxWrapperComponent>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              background={undefined}
              arenaInfo={undefined}
              headerTitle={
                <Box paddingX={1}>
                  <TitleCustomComponent
                    title={"BẢNG XẾP HẠNG"}
                    fontSize="13px"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => scrollIndexMyRank()}
                  >
                    <TextComponent
                      content={`Vị trí của bạn trên bảng xếp hạng:`}
                      maxLine="1"
                    />
                    <TextComponent
                      content={`${rankCurrent?.position ?? ""}`}
                      styleText={{
                        fontSize: "20px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        marginBottom: "7px",
                      }}
                    />
                  </Box>
                </Box>
              }
              headerIcon={<img src={CupIcon} alt="..." />}
              borderImageBox={getBorderBox()}
              headerBtnRight={
                <Box
                  key={0}
                  sx={{
                    justifyContent: "center",
                    display: "grid",
                    alignContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    onClick={() => setOpenModalSearch(true)}
                  >
                    <SearchOutlined
                      style={{ color: "white", marginRight: "4px" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      marginBottom: 1,
                      paddingTop: "2px",
                    }}
                  >
                    <SelectItemComponent
                      items={SelectByScopeHT}
                      value={valueScope}
                      setValue={setValueScope}
                    />
                    <SelectItemComponent
                      items={SelectByIndex}
                      value={valueIndex}
                      setValue={setValueIndex}
                    />
                  </Box>
                </Box>
              }
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              isArena={undefined}
            >
              <ArenaRankingComponent
                refList={listRef}
                isShowMyRank={true}
                isEmployeeList={false}
                listRank={listRank}
                setListRank={setListRank}
                switchCallApi={HT}
                paramSearchScope={valueScope}
                paramSearchIndex={valueIndex}
                setOpenModalInfo={setOpenModalInfo}
              />
            </BoxWrapperComponent>
            {infoUserSelectDetail ? (
              <InfoUserModal
                onClose={() => {
                  dispatch(setIndexInfoUserAction(null));
                  dispatch(setCompareToFirstInfoUser(null));
                  setOpenModalInfo(false);
                }}
                onOpen={openModalInfo}
                infoUser={infoUserSelectDetail}
              />
            ) : null}
            <SearchArenaRankingModal
              open={openModalSearch}
              onClose={() => setOpenModalSearch(false)}
              setOpenModalInfo={setOpenModalInfo}
              valueScope={valueScope}
              valueIndex={valueIndex}
              switchCallApi={HT}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
