// @ts-nocheck
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import CheckinIcon from "assets/images/checkin/checkin-icon.svg";
import TextComponent from "components/Texts/TextComponent";
import { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/btn-close.png";
import moment from "moment";
import { useDispatch } from "react-redux";
import { asyncListAttendance } from "redux/attendances/attendance.actions";
import ProgressiveImage from "components/ProgressiveImage";
import IconDone from "assets/images/icon-done.svg";

export default function CheckinModal({ open, onClose, onClickCheckin }) {
  const [loading, setLoading] = useState(false);
  const [attendances, setAttendances] = useState([]);
  const [processCheckIn, setProcessCheckin] = useState("");
  const [isAttendance, setIsAttendance] = useState(false);
  const [checkDateAttendance, setCheckDateAttendance] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      setLoading(true);
      dispatch(asyncListAttendance())
        .unwrap()
        .then((result) => {
          setAttendances(result?.list ?? []);
          setIsAttendance(result?.isAttendance ?? false);
          if (result?.list.length > 0) {
            const isDay = result?.list.find(
              (item) => item.date === moment().format("DD/MM/YYYY")
            );
            if (isDay) {
              setCheckDateAttendance(true);
            } else {
              setCheckDateAttendance(false);
            }
          }
          setProcessCheckin(`${result?.process ?? 0}/${result?.cycle ?? 0}`);
          setTimeOutLoading();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [open]);

  const setTimeOutLoading = () => {
    const idTimeOut = setTimeout(() => {
      setLoading(false);
    }, 300);
    return () => clearTimeout(idTimeOut);
  };

  let itemIsDay;

  const isToday = (item) => {
    const isDay = moment().format("DD/MM/YYYY") === item?.date && !isAttendance;
    if (isDay) {
      itemIsDay = item;
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minHeight: "400px",
            maxHeight: "600px",
            bgcolor: "white",
            padding: "10px",
            background: "#302A20",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
            border: "1px solid #FFEEA3",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            width: "445px",
          }}
        >
          <Box
            sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
            onClick={onClose}
          >
            <img src={CloseIcon} alt="..." />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "70px",
              display: "grid",
              gridAutoColumns: "1fr",
            }}
          >
            <Box sx={{ gridRow: "1", gridColumn: "span 1" }}>
              <Box sx={{}}>
                <img src={CheckinIcon} alt="..." width="100px" />
              </Box>
            </Box>
            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 2",
                alignSelf: "center",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  fontFamily: "SVN-Gotham",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "22.9001px",
                  textAlign: "start",
                  textTransform: "uppercase",
                  color: "#FFF8E0",
                  alignSelf: "center",
                }}
              >
                BÁO DANH
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                gridRow: "1",
                gridColumn: "span 2",
                alignSelf: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "SVN-Gotham ",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "15px",
                  textTransform: "uppercase",
                  color: "#F0EAD8",
                }}
              >
                Đã nhận {`${processCheckIn ?? ""}`}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              marginTop: "10px",
              width: "fit-content",
              minHeight: "110px",
              overflowY: "scroll",
              display: "grid",
              gap: 2,
              flexDirection: "row",
              flexWrap: "wrap",
              gridTemplateColumns: "repeat(3, 1fr)",
              marginBottom: "50px",
            }}
          >
            {!loading ? (
              attendances.map((item, i) => {
                return (
                  <Box
                    key={i.toString()}
                    sx={{
                      width: "130px",
                      height: "130px",
                      boxSizing: "border-box",
                      bgcolor: "#2B2112",
                      border: isToday(item)
                        ? "2px solid #E3981B"
                        : "1px solid #443B2B",
                      borderRadius: "4px",
                      display: "flex",
                      flexDirection: "column",
                      padding: "3px",
                      alignItems: "center",
                      overflow: "hidden",
                      position: "relative",
                    }}
                    onClick={() => {}}
                  >
                    {item?.isAttendance ? (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          bgcolor: "rgba(0, 0, 0, 0.5)",
                          right: 0,
                          left: 0,
                          top: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={IconDone}
                          alt="..."
                          style={{
                            width: "75%",
                            height: "75%",
                          }}
                        />
                      </Box>
                    ) : null}

                    <Typography
                      sx={{
                        fontFamily: "SVN-Gotham",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "13px",
                        lineHeight: "17px",
                        textAlign: "center",
                        color: "#FFA200",
                      }}
                    >
                      Ngày {i + 1}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "SVN-Gotham",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "13px",
                        lineHeight: "17px",
                        textAlign: "center",
                        color: "#AF8846",
                      }}
                    >
                      {item?.date ?? ""}
                    </Typography>
                    <ProgressiveImage
                      src={item?.image ?? ""}
                      styleImg={{
                        height: "58px",
                        textAlign: "center",
                        marginTop: "4px",
                        width: "100%",
                      }}
                      styleBox={{ height: "auto", minHeight: "58px" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "SVN-Gotham",
                        fontWeight: 700,
                        fontSize: "14px",
                        lineHeight: "15px",
                        textAlign: "center",
                        color: "#FFFFFF",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "99.99%",
                        marginTop: "4px",
                      }}
                    >
                      {item?.quantity ?? ""} {item?.name ?? ""}
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "8px",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <CircularProgress
                  size={"30px"}
                  style={{ color: "rgb(255, 238, 163)" }}
                  disableShrink={true}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              height: "40px",
              bottom: "8px",
              position: "absolute",
              display: "grid",
              gridAutoColumns: "1fr",
            }}
          >
            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 2",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <TextComponent
                content="Lưu ý: Nếu bỏ qua 1 ngày bảng báo danh sẽ bị reset lại từ ngày 1."
                styleText={{
                  fontSize: "12px",
                  fontFamily: "sans-serif",
                  fontWeight: "400",
                  color: "#81838E",
                  letterSpacing: "1.1px",
                  lineHeight: "15px",
                  fontStyle: "Italic",
                }}
              />
            </Box>
            {attendances?.length > 0 && checkDateAttendance ? (
              <Box
                sx={{
                  gridRow: "1",
                  gridColumn: "span 1",
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "12px",
                }}
              >
                <Button
                  onClick={() => {
                    onClickCheckin(itemIsDay);
                  }}
                  sx={{
                    border: "1px solid #FFEEA3",
                    borderLeft: "1px solid #FFEEA3",
                    borderRadius: "5px",
                    width: isAttendance ? "100%" : "100px",
                    opacity: isAttendance ? 0.6 : 1,
                  }}
                  disabled={isAttendance}
                >
                  <Typography
                    style={{
                      fontFamily: "SVN-Gotham",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      textAlign: "start",
                      color: "#FFEEA3",
                      alignSelf: "center",
                    }}
                  >
                    {isAttendance ? "Đã báo danh" : "Báo danh"}
                  </Typography>
                </Button>
              </Box>
            ) : null}
            {!checkDateAttendance ? (
              <Box
                sx={{
                  gridRow: "1",
                  gridColumn: "span 1",
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "12px",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #FFEEA3",
                    borderLeft: "1px solid #FFEEA3",
                    borderRadius: "5px",
                    width: "100%",
                    opacity: 0.6,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "SVN-Gotham",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      textAlign: "center",
                      color: "#FFEEA3",
                      alignSelf: "center",
                    }}
                  >
                    {"Chưa đến ngày báo danh"}
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
