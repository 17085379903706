// @ts-nocheck
import {
  Box,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useImperativeHandle } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeaderboardsEvents,
  getParticipantsEvents,
} from "redux/events/events.actions";
import { setAwardedUser } from "redux/inventory/inven.slice";
import IconExpRank from "../assets/images/icon-exp-rank.svg";
import IconInfo from "../assets/images/icon-information.svg";
import IconRankCopper from "../assets/images/icon-rank-copper.svg";
import IconRankDefault from "../assets/images/icon-rank-default.svg";
import IconRankGold from "../assets/images/icon-rank-gold.svg";
import IconRankSilver from "../assets/images/icon-rank-silver.svg";
import { colors } from "../config/colors/colors";
import {
  CD,
  EVENT_LEADERBOARD,
  EVENT_PARTICIPANT,
  HKD,
  HT,
  STAFF,
} from "../config/constants";
import {
  setModalInfoUserCenter,
  setOpenModalSearchAward,
  setTabShowInfo,
} from "../redux/popup/popup.slice";
import {
  asyncGetUserByScope,
  getRankUsers,
  getRankUsersCommunity,
  getRankUsersHousehold,
  getRankUsersSystem,
} from "../redux/users/user.actions";
import {
  rankCurrentSelector,
  userSelector,
} from "../redux/users/user.selectors";
import { setRankCurrent } from "../redux/users/user.slice";
import { scrollParentToChild } from "../utils/common";
import { AvatarRankModal } from "./AvatarRankModal";
import ProgressiveImage from "./ProgressiveImage";
import TextComponent from "./Texts/TextComponent";
import "../config/styles/animation_border.css";
import InfoUserModal from "./Modal/InfoUserModal";

function ArenaRankingComponent({
  isShowMyRank = false,
  refList,
  isEmployeeList = false,
  isJoinable = false,
  setListRank,
  listRank,
  isModalCenter = false,
  setOpenModalInfo,
  paramSearchScope,
  paramSearchIndex,
  paramSearchText = "",
  switchCallApi = "",
  isSetRankCurrent = true,
  paramId = "",
  setTotalStaff,
  tabShowInfo = 3,
  isAwardInven = false,
  refRefreshData,
  startDate,
  endDate,
  isHasDate = false,
}) {
  const [hasMore, setHasMore] = useState(false);
  const [autoLoadMore, setAutoLoadMore] = useState(null);
  const [loadding, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const rankCurrent = useSelector(rankCurrentSelector);
  const dispatch = useDispatch();
  const profileUser = useSelector(userSelector);

  const IconRank = ({ srcIcon }) => {
    return (
      <Box
        sx={{
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={srcIcon} alt="..." />
      </Box>
    );
  };

  useEffect(() => {
    setPage(1);
    setLoading(true);
    if (paramSearchText) {
      const timer = setTimeout(() => {
        fetDataRankUser(1);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      fetDataRankUser(1);
    }
  }, [paramSearchScope, paramSearchIndex, paramSearchText, startDate, endDate]);

  const checkCallApiObd = (pageNow) => {
    let params = {
      page: pageNow,
      index: paramSearchIndex,
      search: paramSearchText,
    };
    if (paramSearchScope) {
      params = {
        ...params,
        ...{ scope: paramSearchScope === 4 ? null : paramSearchScope },
      };
    }

    if (paramId && switchCallApi === EVENT_PARTICIPANT) {
      params = {
        params: {
          page: pageNow,
        },
        id: paramId,
      };
    }

    if (paramId && switchCallApi === EVENT_LEADERBOARD) {
      params = {
        params: {
          ...params,
          ...{ scope: paramSearchScope === 4 ? null : paramSearchScope },
        },
        id: paramId,
      };
    }

    if (isHasDate) {
      params = {
        ...params,
        ...{
          scope: paramSearchScope === 4 ? null : paramSearchScope,
          startDate,
          endDate,
        },
      };
    }


    switch (switchCallApi) {
      case HKD:
        return getRankUsersHousehold(params);
      case HT:
        return getRankUsersSystem(params);
      case CD:
        return getRankUsersCommunity(params);
      case EVENT_PARTICIPANT:
        return getParticipantsEvents(params);
      case EVENT_LEADERBOARD:
        return getLeaderboardsEvents(params);
      default:
        return getRankUsers(params);
    }
  };

  const checkShowBorderAniamtion = (user, isShowInfo) => {
    switch (switchCallApi) {
      case HKD:
        return (
          profileUser?.household &&
          user?.id &&
          isShowInfo &&
          user?.name === profileUser?.household?.name
        );
      case HT:
        return (
          profileUser?.system &&
          user?.id &&
          isShowInfo &&
          user?.name === profileUser?.system?.name
        );
      case CD:
        return (
          profileUser?.community &&
          user?.id &&
          isShowInfo &&
          user?.name === profileUser?.community?.name
        );
      default:
        return (
          rankCurrent?.id &&
          user?.id &&
          isShowInfo &&
          user?.id === rankCurrent?.id
        );
    }
  };

  const fetDataRankUser = async (pageNow, isAutoLoad = false) => {
    await dispatch(checkCallApiObd(pageNow))
      .unwrap()
      .then((originalPromiseResult) => {
        if (isSetRankCurrent) {
          dispatch(setRankCurrent(originalPromiseResult?.current));
        }
        if (originalPromiseResult?.data?.length > 0) {
          if (pageNow === 1) {
            if (setTotalStaff) {
              setTotalStaff(originalPromiseResult?.count);
            }
            setListRank(originalPromiseResult?.data);
            if (refList?.current?.el) {
              refList?.current?.el.scrollTo({
                top: 0,
              });
            }
          } else {
            const newList = [...listRank, ...originalPromiseResult?.data];
            setListRank(newList);
            setPage(pageNow);
            if (isAutoLoad) {
              setAutoLoadMore(newList?.length);
            }
          }
        } else {
          setHasMore(false);
          if (pageNow === 1) {
            setListRank([]);
          }
        }
        if (!isAutoLoad) {
          setLoading(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
      });
  };

  const checkScreenUserId = () => {
    switch (switchCallApi) {
      case HKD:
        return profileUser?.household?.id;
      case HT:
        return profileUser?.system?.id;
      case CD:
        return profileUser?.community?.id;
      default:
        return profileUser?.id;
    }
  };

  useEffect(() => {
    if (autoLoadMore !== null) {
      if (
        refList?.current &&
        refList?.current?.el &&
        refList?.current?.el?.children?.length > 0
      ) {
        const findId = listRank.findIndex(
          (value) => value.id === checkScreenUserId()
        );
        if (findId === -1) {
          fetchMoreData(true);
          return;
        }
        let element =
          refList?.current?.el?.children[findId > 3 ? findId - 3 : 0];
        setLoading(false);
        if (element) {
          scrollParentToChild(refList?.current?.el, element);
        }
        setAutoLoadMore(null);
        setHasMore(false);
      }
    }
  }, [autoLoadMore]);

  const fetchMoreData = (isAutoLoad, isLoading = false) => {
    if (isAutoLoad) {
      const pageLoadMore = page + 1;
      setPage(pageLoadMore);
      if (isLoading) {
        setLoading(true);
      }
      fetDataRankUser(pageLoadMore, isAutoLoad);
    } else {
      setHasMore(true);
      const pageLoadMore = page + 1;
      const idTimeOut = setTimeout(() => fetDataRankUser(pageLoadMore), 500);
      return () => clearTimeout(idTimeOut);
    }
  };

  const fetchRefreshData = () => {
    setIsRefresh(true);
    fetDataRankUser(1);
    setIsRefresh(false);
  };

  useImperativeHandle(refRefreshData, () => ({
    callRefeshDataEvent() {
      fetDataRankUser(1);
    },
  }));

  const getBgColorItem = (index) => {
    switch (index) {
      case 0:
        return {
          background: `rgb(199 139 37)`,
        };
      case 1:
        return {
          background: `rgb(144 141 131)`,
        };
      case 2:
        return {
          background: `rgb(94 59 28)`,
        };
      default:
        return {
          background: `rgb(87 73 53)`,
        };
    }
  };
  const ItemRank = ({ index, user, isShowInfo = true, rankCurrentUser }) => {
    return (
      <Box
        onClick={() => {
          if (isAwardInven) {
            dispatch(setAwardedUser(user));
            dispatch(setOpenModalSearchAward(false));
          }
        }}
        sx={{
          cursor: isAwardInven ? "pointer" : "default",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: `1.8581px solid rgba(0, 0, 0, 0.52)`,
          boxShadow: `0px 4.95494px 0.619367px rgba(0, 0, 0, 0.35)`,
          borderRadius: ` 4.95494px`,
          paddingLeft: "4px",
          paddingRight: "4px",
          paddingTop: {
            xs: "5.5px",
            sm: "5.5px",
            md: "4.5px",
            lg: "5.5px",
            xl: "5.5px",
          },
          marginBottom: isShowInfo ? 2 : 0,
          paddingBottom: {
            xs: "5.5px",
            sm: "5.5px",
            md: "4.5px",
            lg: "5.5px",
            xl: "5.5px",
          },
          marginTop: index === 0 ? "16px" : 0,
          ...getBgColorItem(isEmployeeList ? 4 : user?.position - 1),
        }}
        className={`${
          checkShowBorderAniamtion(user, isShowInfo) ||
          (index < 3 && !isEmployeeList)
            ? "Box"
            : ""
        }`}
      >
        {!isEmployeeList && (
          <Grid item xs={"auto"} paddingRight={"4px"}>
            {user?.position === 1 ? (
              <IconRank srcIcon={IconRankGold} />
            ) : user?.position === 2 ? (
              <IconRank srcIcon={IconRankSilver} />
            ) : user?.position === 3 ? (
              <IconRank srcIcon={IconRankCopper} />
            ) : (
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={IconRankDefault} alt="..." />
                <TextComponent
                  content={`${rankCurrentUser ?? ""}`}
                  styleText={{
                    position: "absolute",
                    color: "#5A3A1A",
                    fontSize: "12px",
                  }}
                  maxLine="1"
                />
              </Box>
            )}
          </Grid>
        )}
        {isEmployeeList ? (
          <Grid item xs={"auto"} paddingRight={"4px"}>
            <Box
              sx={{
                width: "30px",
                height: "30px",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={IconRankDefault} alt="..." />
              <TextComponent
                content={`${index + 1 ?? ""}`}
                styleText={{
                  position: "absolute",
                  color: "#5A3A1A",
                  fontSize: "12px",
                }}
                maxLine="1"
              />
            </Box>
          </Grid>
        ) : null}
        <Grid
          item
          xs={"auto"}
          paddingRight={"4px"}
          style={{ marginLeft: isEmployeeList ? "10px" : "0px" }}
        >
          <AvatarRankModal
            srcAvatar={user?.rank?.circleAvatar ?? ""}
            circleFrame={user?.rank?.circleFrame ?? ""}
            isStaff={user?.type === STAFF}
            boxStyle={{
              borderImageSlice: "44 42",
              borderImageWidth: "12px",
            }}
            imgStyle={{}}
            isLoading={false}
          />
        </Grid>

        <Grid
          item
          xs={7}
          sm={!isEmployeeList ? 4 : 12}
          md={!isEmployeeList ? 4.2 : 3.8}
          lg={!isEmployeeList ? 5.2 : 4.5}
          xl={!isEmployeeList ? 12 : 6}
        >
          <Box sx={{ paddingRight: "14px" }}>
            <TextComponent
              content={user?.name ?? ""}
              styleText={{
                textTransform: "uppercase",
                fontSize: "14px",
              }}
              maxLine="1"
            />
            <div
              style={{
                overflowX: "auto",
                overflowY: "hidden",
                whiteSpace: "nowrap",
                WebkitOverflowScrolling: "touch",
                display: "flex",
              }}
            >
              {!isEmployeeList ? (
                <div
                  style={{
                    display: "flex",
                    width: "auto",
                    flexShrink: 0,
                    paddingRight: "8px",
                  }}
                >
                  <ProgressiveImage
                    src={user?.rank?.icon ?? ""}
                    isLoading={false}
                  />
                  <TextComponent
                    content={user?.rank?.name ?? ""}
                    styleText={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                  />
                </div>
              ) : null}

              {user?.household?.name ? (
                <div
                  style={{
                    display: "flex",
                    width: "auto",
                    flexShrink: 0,
                    paddingRight: "8px",
                  }}
                >
                  <ProgressiveImage
                    src={user?.household?.rank?.icon ?? ""}
                    isLoading={false}
                  />
                  <TextComponent
                    content={user?.household?.name ?? ""}
                    styleText={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                  />
                </div>
              ) : null}
              {user?.system?.name ? (
                <div
                  style={{
                    display: "flex",
                    width: "auto",
                    flexShrink: 0,
                    paddingRight: "8px",
                  }}
                >
                  <ProgressiveImage
                    src={user?.system?.rank?.icon ?? ""}
                    isLoading={false}
                  />
                  <TextComponent
                    content={user?.system?.name ?? ""}
                    styleText={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                  />
                </div>
              ) : null}

              {user?.community?.name ? (
                <div
                  style={{
                    display: "flex",
                    width: "auto",
                    flexShrink: 0,
                    paddingRight: "8px",
                  }}
                >
                  <ProgressiveImage
                    src={user?.community?.rank?.icon ?? ""}
                    isLoading={false}
                  />
                  <TextComponent
                    content={user?.community?.name ?? ""}
                    styleText={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Box>
        </Grid>
        <Grid
          item
          xs
          marginRight={"4px"}
          marginLeft={"4px"}
          justifyContent={"flex-end"}
          display={"flex"}
        >
          <Box
            sx={{
              display: "flex",
              position: "relative",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            {isEmployeeList ? (
              user?.initialScore ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      background: "rgba(0, 0, 0, 0.2)",
                      width: {
                        xs: "82px",
                        sm: "82px",
                        md: "auto",
                        lg: "62px",
                        xl: "82px",
                      },
                      // width: "100%",
                      height: "26px",
                      borderRadius: "7px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginRight: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "SVN-Gotham",
                        fontWeight: 700,
                        fontSize:
                          (user?.initialScore + "")?.length > 6
                            ? "12px"
                            : "14px",
                        lineHeight: "15px",
                        textAlign: "center",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%",
                        paddingLeft: "4px",
                        paddingRight: "2px",
                        color: "#FFDB80",
                      }}
                    >
                      {user?.initialScore ?? ""}
                    </Typography>
                  </Box>
                </Box>
              ) : null
            ) : (
              <Tooltip
                placement="top"
                title={`${(user?.score + "")?.length > 6 ? user?.score : ""}`}
              >
                <Box
                  sx={{
                    background: "rgba(0, 0, 0, 0.2)",
                    width: {
                      xs: "82px",
                      sm: "82px",
                      md: "62px",
                      lg: "82px",
                      xl: "82px",
                    },
                    height: "26px",
                    borderRadius: "7px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "SVN-Gotham",
                      fontWeight: 700,
                      fontSize:
                        (user?.score + "")?.length > 6 ? "12px" : "14px",
                      lineHeight: "15px",
                      textAlign: "center",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      paddingLeft: "20px",
                      paddingRight: "4px",
                      color: "#FFDB80",
                    }}
                  >
                    {user?.score ?? ""}
                  </Typography>
                </Box>
              </Tooltip>
            )}

            <Box
              sx={
                isEmployeeList
                  ? {
                      width: {
                        xs: "40px",
                        sm: "40px",
                        md: "20px",
                        lg: "40px",
                        xl: "40px",
                      },
                      position: isEmployeeList ? "relative" : "absolute",
                    }
                  : {
                      width: "30px",
                      position: isEmployeeList ? "relative" : "absolute",
                      left: -12,
                    }
              }
            >
              {isEmployeeList ? (
                <img src={user?.rank?.icon} alt="..." />
              ) : (
                <img src={user?.scoreIcon ?? IconExpRank} alt="..." />
              )}
            </Box>
          </Box>
        </Grid>
        {isShowInfo
          ? !isEmployeeList && (
              <Grid
                item
                xs={"auto"}
                justifyContent={"center"}
                display={"flex"}
                alignItems={"center"}
                marginX={"4px"}
                sx={{ cursor: "pointer" }}
                onClick={async () => {
                  await dispatch(
                    asyncGetUserByScope({
                      id: user.id,
                      scope: switchCallApi,
                      isEvent: user?.detail ?? "",
                      compareToFirst: user?.compareToFirst,
                    })
                  );
                  dispatch(setModalInfoUserCenter(isModalCenter));
                  dispatch(setTabShowInfo(tabShowInfo));
                  if (setOpenModalInfo) {
                    setOpenModalInfo(true);
                  }
                }}
              >
                <img src={IconInfo} alt="..." />
              </Grid>
            )
          : null}
      </Box>
    );
  };

  return (
    <>
      <InfiniteScroll
        dataLength={listRank?.length}
        next={fetchMoreData}
        hasMore={true}
        loader={
          hasMore ? (
            <Box
              key={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "8px",
              }}
            >
              <CircularProgress size={"16px"} style={{ color: "white" }} />
            </Box>
          ) : null
        }
        height={isShowMyRank || isJoinable ? 440 : 516}
        style={{
          paddingRight: "10px",
          paddingLeft: "10px",
          position: "relative",
          overflow: loadding ? "hidden" : "auto",
        }}
        ref={refList}
      >
        {listRank?.length ? (
          listRank.map((item, i) => {
            return (
              <Grid
                item
                key={i}
                container
                flexWrap={"nowrap"}
                sx={{ width: "100%" }}
              >
                <ItemRank
                  index={i}
                  user={item}
                  rankCurrentUser={item?.position}
                />
              </Grid>
            );
          })
        ) : (
          <TextComponent
            content="Không có dữ liệu cần tìm"
            styleText={{ textAlign: "center", marginTop: 2 }}
          />
        )}
      </InfiniteScroll>
      {isShowMyRank ? (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            bgcolor: "#564E3E",
            width: "100%",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <ItemRank
              index={rankCurrent?.position ?? ""}
              user={rankCurrent}
              isShowInfo={false}
              rankCurrentUser={rankCurrent?.position ?? ""}
            />
          </Box>
        </Box>
      ) : null}
      {loadding ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "8px",
            position: "absolute",
            width: "100%",
            height: "100%",
            background: colors.back500,
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <CircularProgress
            size={"30px"}
            style={{ color: "white" }}
            disableShrink={true}
          />
        </Box>
      ) : null}
    </>
  );
}

export default React.memo(ArenaRankingComponent);
