// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
} from "@mui/material";
import TextComponent from "../Texts/TextComponent";
import { Link } from "react-router-dom";
import IconArrow from "../../assets/images/icon-arrow.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { colors } from "config/colors/colors";
import { getListEvents } from "redux/events/events.actions";
import { TYPE_EVENT, TIME_EVENT_HAPPENNING } from "config/constants";
import "../../config/styles/ribbon.css";

const SampleEventComponent = ({ paramsQuery }) => {
  const [hasMore, setHasMore] = useState(false);
  const [loadding, setLoading] = useState(false);
  const [listEvents, setListEvents] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    asyncGetListEvent(1);
  }, []);

  const switchApiEvent = (pageNow) => {
    let params = {
      page: pageNow,
      type: paramsQuery?.type ?? TYPE_EVENT,
      time: paramsQuery?.time ?? TIME_EVENT_HAPPENNING,
    };
    return getListEvents(params);
  };

  const asyncGetListEvent = async (pageNow) => {
    await dispatch(switchApiEvent(pageNow))
      .unwrap()
      .then((originalPromiseResult) => {
        if (originalPromiseResult?.length > 0) {
          if (pageNow === 1) {
            setListEvents(originalPromiseResult);
          } else {
            const newList = [...listEvents, ...originalPromiseResult];
            setListEvents(newList);
            setPage(pageNow);
          }
        } else {
          setHasMore(false);
          if (pageNow === 1) {
            setListEvents([]);
          }
        }
        setLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
      });
  };

  const fetchMoreData = () => {
    setHasMore(true);
    const pageLoadMore = page + 1;
    const idTimeOut = setTimeout(() => asyncGetListEvent(pageLoadMore), 500);
    return () => clearTimeout(idTimeOut);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={listEvents.length}
        next={fetchMoreData}
        hasMore={false}
        loader={
          hasMore ? (
            <Box
              key={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              <CircularProgress size={"16px"} style={{ color: "white" }} />
            </Box>
          ) : null
        }
        height={516}
      >
        {listEvents.length ? (
          <Grid
            container
            spacing={2}
            paddingTop={2}
            sx={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            {listEvents.map((card, i) => (
              <Grid key={i} item xs={6} sm={6} md={6}>
                <Link
                  to={`/events/${card?.id}`}
                  style={{ position: "relative" }}
                >
                  <Card
                    sx={{
                      height: "242px",
                      bgcolor: "#2D2312",
                      border: "1px solid #4A6380",
                      boxShadow: `0px 4px 1px rgba(0, 0, 0, 0.25)`,
                      cursor: "pointer",
                      overflow: "hidden",
                    }}
                    className={`menu-item`}
                  >
                    {card?.joined ? (
                    <div style={{position: "relative", zIndex: "10000"}}>
                      <img src={card?.circleAvatar} alt={""} style={{position: "absolute", width: "25px"}}/>
                      <img src={card?.circleFrame} alt={""} style={{position: "absolute", width: "25px", top: "3px"}}/>
                    </div>
                    ) : null}
                    {card?.type === 2 ? (
                      <div className="ribbon red">
                        <span>Đăng ký</span>
                      </div>
                    ) : null}

                    {card?.type === 1 ? (
                      <div className="ribbon blue">
                        <span>Tự động</span>
                      </div>
                    ) : null}

                    {card?.type === 3 ? (
                      <div className="ribbon">
                        <span>Lời mời</span>
                      </div>
                    ) : null}
                    {card?.image ? (
                      <CardMedia
                        component="img"
                        sx={{
                          padding: "2px",
                          height: 120,
                          objectFit: "fill",
                        }}
                        image={card?.image}
                        alt={""}
                        className="background-image"
                      />
                    ) : (
                      <Box
                        sx={{
                          margin: "2px",
                          height: 120,
                          border: "1px solid gray",
                        }}
                      ></Box>
                    )}

                    <CardContent sx={{ padding: "4px" }}>
                      <TextComponent
                        content={card?.name ?? ""}
                        styleText={{
                          fontSize: "14px",
                          marginBottom: "4px",
                        }}
                        maxLine="2"
                      />
                      <TextComponent
                        content={`Thời gian diễn ra: ${card.beginAt} - ${card.endAt}`}
                        styleText={{
                          fontWeight: 300,
                          fontSize: "14px",
                          color: "#A1A1A1",
                          fontFamily: "sans-serif",
                          marginBottom: "4px",
                        }}
                        maxLine="2"
                      />
                      <TextComponent
                        content={`Điểm tối thiểu: ${card.revenueMin}`}
                        styleText={{
                          fontWeight: 300,
                          fontSize: "14px",
                          color: "#A1A1A1",
                          fontFamily: "sans-serif",
                          marginBottom: "4px",
                        }}
                        maxLine="2"
                      />
                      <Box sx={{ display: "flex" }}>
                        <TextComponent
                          content={"Xem chi tiết"}
                          styleText={{
                            fontWeight: 700,
                            fontSize: "14px",
                            color: "#59A6FD",
                            fontFamily: "sans-serif",
                            paddingRight: "4px",
                          }}
                        />

                        <img src={IconArrow} alt="..." />
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TextComponent
            content="Không có dữ liệu cần tìm"
            styleText={{ textAlign: "center", marginTop: 2 }}
          />
        )}
      </InfiniteScroll>
      {loadding ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "8px",
            position: "absolute",
            width: "100%",
            height: "100%",
            background: colors.back500,
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <CircularProgress
            size={"30px"}
            style={{ color: "white" }}
            disableShrink={true}
          />
        </Box>
      ) : null}
    </>
  );
};

export default React.memo(SampleEventComponent);
