import { Box, Modal, Typography } from "@mui/material";
import ClaimBtn from "assets/images/arena/arena-claim-btn.png";
import ArenaWin from "assets/images/arena/arena-win.svg";
import HeaderBtn from "components/Buttons/HeaderButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncReadNotification } from "redux/events/events.actions";
import WarningIcon from "assets/images/warning-icon.png";
import IconInfo from "assets/images/icon-information.svg";
import {  setOpenUpRankModal } from "redux/popup/popup.slice";
import ButtonGradientCustom from "components/Buttons/ButtonGradientCustom";
import { infoNotificationSelector } from "redux/popup/popup.selector";
import { Link } from "react-router-dom";

export default function ArenaWinModal({ open, onClose }) {
  const dispatch = useDispatch();
  const infoNotificationSlt = useSelector(infoNotificationSelector);

  useEffect(() => {
    if (open && infoNotificationSlt?.id) {
      dispatch(asyncReadNotification(infoNotificationSlt?.id));
    }
  }, [open, infoNotificationSlt?.id]);

  const setOpenModalUpRank = () => {
    dispatch(setOpenUpRankModal(true));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          border: "2px solid #000",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "400px",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            width: "500px",
            backgroundImage: `url(${ArenaWin})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "260px",
              height: "170px",
              bottom: "60px",
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            <Box
              sx={{
                overflowY: "scroll",
                height: "40px",
              }}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "20px",
                  textAlign: "center",
                  color: "#FFF2BC",
                }}
              >
                {infoNotificationSlt?.data?.title ?? ""}
              </Typography>
            </Box>
            {infoNotificationSlt?.data?.prize?.image ||
            infoNotificationSlt?.data?.prize?.quantity ||
            infoNotificationSlt?.data?.prize?.name ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "20px",
                }}
              >
                <HeaderBtn
                  onClick={undefined}
                  icon={`${
                    infoNotificationSlt?.data?.prize?.image ?? WarningIcon
                  }`}
                  label={`${infoNotificationSlt?.data?.prize?.quantity ?? ""} ${
                    infoNotificationSlt?.data?.prize?.name ?? ""
                  }`}
                  type={"arena-win"}
                  style={{
                    width: "30px",
                    maxHeight: "40px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            ) : null}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              {/* <img
                src={ClaimBtn}
                width="70px"
                alt="..."
                onClick={onClose}
                style={{ marginRight: "8px" }}
              /> */}
              {
              infoNotificationSlt?.data?.origin === 6 && infoNotificationSlt?.data?.description && (
                <ButtonGradientCustom
                  gradient="linear-gradient(89.9deg, rgba(204, 90, 41, 0.7) 0.08%, rgba(246, 175, 26, 0.7) 99.91%)"
                  lable="Chi tiết"
                  styleBox={{
                    margin: 0,
                    padding: "6.3px 9px",
                    borderRadius: "6px",
                  }}
                  styleText={{ color: "white", fontSize: "12px" }}
                  onClick={setOpenModalUpRank}
                />
              )}
              {
              infoNotificationSlt?.data?.origin === 1 && (
                <Link to={`/events/${infoNotificationSlt?.data?.eventId}`}>
                  <ButtonGradientCustom
                    gradient="linear-gradient(89.9deg, rgba(204, 90, 41, 0.7) 0.08%, rgba(246, 175, 26, 0.7) 99.91%)"
                    lable="Chi tiết"
                    styleBox={{
                      margin: 0,
                      padding: "6.3px 9px",
                      borderRadius: "6px",
                    }}
                    styleText={{ color: "white", fontSize: "12px" }}
                    onClick={onClose}
                  />
                </Link>
              )}
            </div>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  textAlign: "center",
                  color: "#FFFFFF",
                  width: "60%",
                }}
              >
                Quà tặng sẽ được chuyển vào rương đồ của bạn
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
