import { Box, CircularProgress, Modal } from "@mui/material";

export default function Loading({ open, onClose }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          border: "2px solid #000",
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: "#FFEEA3" }} />
        </Box>
      </Modal>
    </div>
  );
}
