import { Box, Modal } from "@mui/material";
import WheelBtn from "assets/images/wheel.png";
import AmbientLight from "assets/images/wheel/bg-wheel.png";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { useState } from "react";
import { Wheel } from "react-custom-roulette";
import { WheelData } from "utils/common";
import CloseIcon from "../../assets/images/btn-close.png";
import { useDispatch } from "react-redux";
import { setOpenArenaWinModal } from "redux/popup/popup.slice";
import BottomWheel from "../../assets/images/bottom-wheel.png";

// @ts-ignore
export default function WheelModal({ open, onClose }) {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [openWin, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * WheelData.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  const onFinished = () => {
    setMustSpin(false);
    dispatch(setOpenArenaWinModal(true));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          border: "2px solid #000",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "10px",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
            border: "1px solid #FFEEA3",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            width: "800px",
            alignItems: "center",
            backgroundImage: `url(${AmbientLight})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#392d1d",
            backgroundBlendMode: "screen",
          }}
        >
          <Box
            sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
            onClick={onClose}
          >
            <img src={CloseIcon} alt="..." />
          </Box>
          <TitleCustomComponent
            title={"VÒNG QUAY MAY MẮN"}
            fontSize="25px"
            style={{
              textAlign: "center",
              marginBottom: "8px",
              marginTop: 1,
            }}
          />
          {/* <WheelComponent
            segments={segments}
            segColors={segColors}
            winningSegment=''
            onFinished={(winner) => onFinished(winner)}
            primaryColor='black'
            primaryColoraround='#ffffffb4'
            contrastColor='white'
            buttonText='Spin'
            isOnlyOnce={false}
            size={190}
            upDuration={50}
            downDuration={2000}
            onRotate={undefined}
            onRotatefinish={undefined}
          /> */}
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={WheelData}
            onStopSpinning={(e) => onFinished(e)}
            outerBorderColor={"#f71b23"}
            outerBorderWidth={15}
            innerBorderColor={"#f2f2f2"}
            radiusLineColor={"tranparent"}
            radiusLineWidth={1}
            textColors={["#f5f5f5"]}
            textDistance={55}
            fontSize={15}
          />

          <Box sx={{ position: "absolute", bottom: "100px" }}>
            <img src={BottomWheel} alt="..." />
          </Box>

          <TitleCustomComponent
            title={"Bạn còn 3 lượt quay"}
            fontSize="18px"
            style={{
              textAlign: "center",
              marginBottom: "8px",
              marginTop: 7,
              color: "white",
            }}
          />

          <div
            onClick={handleSpinClick}
            style={{ width: "120px", display: "flex", alignSelf: "center" }}
          >
            <img src={WheelBtn} width="120px" alt="..." />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
