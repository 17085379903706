import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Badge,
  IconButton,
  Input,
  InputAdornment,
  Typography,
  Box,
  Tooltip,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isAttendanceSelector } from "redux/attendances/attendance.selectors";

export default function HeaderBtn({
  onClick,
  icon,
  label,
  type,
  style = {},
  containerStyle = {},
  showLabel = false,
}) {
  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(0);
  const [gradient, setGradient] = useState("");
  const isAttendanceSelect = useSelector(isAttendanceSelector);

  const handleShowData = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    setWidth(label.length);
  }, [label]);

  const gradientBtn = {
    inventory: `radial-gradient(circle, #9b4a22, #8a411d, #7a3918, #6a3014, #5b280f)`,
    checkin: `radial-gradient(circle, #ffd00d, #f2b805, #e4a103, #d58b04, #c47606)`,
    challenge: `radial-gradient(circle, #c46200, #bc5600, #b44b00, #ac3f00, #a33300)`,
    honor: `radial-gradient(circle, #c46200, #bc5600, #b44b00, #ac3f00, #a33300)`,
    arena: ` radial-gradient(circle, #d9472f, #bf3c27, #a5321f, #8c2817, #741e10)`,
    event: ` radial-gradient(circle, #6940ff, #613cea, #5938d5, #5233c0, #4a2fac)`,
    wheel: ` radial-gradient(circle, #f20000, #e10000, #d10000, #c00000, #b00000)`,
    coin: ` radial-gradient(circle, #9d5501, #ad5d01, #bc6600, #cd6e00, #dd7700, #dd7700, #dd7700, #dd7700, #cd6e00, #bc6600, #ad5d00, #9d5500)`,
  };

  const getRadient = () => {
    switch (type) {
      case "inventory":
        setGradient(gradientBtn.inventory);
        break;
      case "checkin":
        setGradient(gradientBtn.checkin);
        break;
      case "challenge":
        setGradient(gradientBtn.challenge);
        break;
      case "arena":
        setGradient(gradientBtn.arena);
        break;
      case "event":
        setGradient(gradientBtn.event);
        break;
      case "wheel":
        setGradient(gradientBtn.wheel);
        break;
      case "coin":
        setGradient(gradientBtn.coin);
        break;
      default:
        setGradient(gradientBtn.coin);
        break;
    }
  };

  const getLeft = () => {
    if (showLabel) {
      return type === "leaderboard" ? "-20px" : "-26px";
    }

    switch (type) {
      case "coin":
        return "-10px";
      case "challenge":
        return "-8px";
      default:
        return "0px";
    }
  };

  useEffect(() => {
    getRadient();
  }, [type]);

  return (
    <div
      id="header-btn"
      style={{
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        cursor: "pointer",
        position: "relative",
        marginLeft: showLabel
          ? "36px"
          : type === "arena" || type === "coin"
          ? "4px"
          : "12px",
        marginTop: "14px",
        ...containerStyle,
      }}
    >
      {icon ? (
        <img
          src={icon}
          height={"40px"}
          style={{
            position: type === "coin" || showLabel ? "absolute" : "relative",
            left: type === "arena-win" ? "22px" : getLeft(),
            width:
              type === "arena-win" || type === "challenge" ? "50px" : "auto",
            zIndex: 1,
            height: type === "arena-win" ? "60px" : "",
            ...style,
          }}
          alt="..."
        />
      ) : null}
      {type === "checkin" && !isAttendanceSelect ? (
        <Box
          sx={{
            width: "10px",
            height: "10px",
            borderRadius: "20px",
            backgroundColor: "#00FF38",
            position: "absolute",
            right: "-2px",
            top: "-3px",
            zIndex: 2,
          }}
        ></Box>
      ) : null}

      {showLabel && (
        <Grid
          sx={{
            height: "35px",
            borderRadius: "0 8px 8px 0",
            background: gradient,
            padding: type === "arena" ? "0 11px 0 35px" : "0 11px 0 20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "SVN-Gotham",
            fontSize: 15,
            // textShadow: `0px 2px 0px rgba(0,0,0,0.5)`,
            boxShadow: "0px 0px 8.745px 0px rgba(0, 0, 0, 0.75)",
            width: "200px",
            "&:hover": {
              boxShadow: "0px 0px 10px 5px rgba(255,255,255,0.75)",
            },
          }}
        >
          {label}
        </Grid>
      )}

      {type === "coin" ? (
        <Tooltip
          placement="top"
          title={`${label.length > 6 && visible ? label : ""}`}
        >
          <Input
            id="standard-adornment-password"
            type={visible ? "text" : "password"}
            value={visible ? label : "000000"}
            disableUnderline
            disabled
            sx={{
              backgroundImage: `${gradient}`,
              textDecorationColor: "white",
              borderRadius: 10,
              alignSelf: "center",
              padding: "5px 4px 4px 24px",
              width: 120,
              maxHeight: 35,
              // marginRight: 30,
              fontFamily: "SVN-Gotham",
              fontSize: 15,
              textShadow: `0px 3px 0px rgba(0,0,0,0.5)`,
              "& input": {
                paddingLeft: "10px",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleShowData}
                  sx={{ width: 20, height: 20 }}
                >
                  {visible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Tooltip>
      ) : null}
      {type === "arena-win" ? (
        <Typography
          style={{
            color: "white",
            background: "black",
            borderRadius: "20px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            alignSelf: "center",
            padding: "0px 10px 0px 32px",
            height: "41px",
            minWidth: "100px",
            fontFamily: "SVN-Gotham",
            fontSize: 15,
            textShadow: `0px 2px 0px rgba(0,0,0,0.5)`,
            border: "5px solid #594D3C",
            borderLeft: "0px",
            display: "flex",
            alignItems: "center",
            maxWidth: "200px",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Typography>
      ) : null}
    </div>
  );
}