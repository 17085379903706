// @ts-nocheck
import { Box, Grid, Modal, Typography } from "@mui/material";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailInven } from "redux/inventory/inven.action";
import { invenSelector } from "redux/inventory/inven.selector";
import { showPopup } from "redux/popup/popup.action";
import { infoEquipmentDetailSelector } from "redux/popup/popup.selector";
import {
  setBackHome,
  setChallengeRoomId,
  setMessageError,
  setOpenChallengRoomModal,
  setOpenExchange,
  setOpenModalAwarded,
  setOpenModalGiftBoxSevenDetail,
  setOpenUseItemModal,
} from "redux/popup/popup.slice";
import BgItemGold from "../../assets/images/bg-item-gold.png";
import CloseIcon from "../../assets/images/btn-close.png";
import BtnUsed from "../../assets/images/btn-use.png";
import BtnExchange from "../../assets/images/btn-quy-doi.png";
import BtnAwarded from "../../assets/images/btn-trao-tang.png";
import { Link } from "react-router-dom";

export default function EquipmentDetailsMdal(props) {
  const { open, onClose, id } = props;
  const infoEquipmentDetail = useSelector(infoEquipmentDetailSelector);
  const dispatch = useDispatch();
  const { detailInventory } = useSelector(invenSelector);
  useEffect(() => {
    if (open) {
      dispatch(getDetailInven(id));
    }
  }, [id, open]);

  const onClickAwarded = () => {
    dispatch(setOpenModalAwarded(true));
  };

  const onClickExchange = () => {
    dispatch(setOpenExchange(true));
  };

  const onClickUseItem = () => {
    dispatch(setOpenUseItemModal(true));
  };

  const getOriginName = () => {
    let textDefault = "Vật phẩm này được nhận từ";
    switch (detailInventory?.originType) {
      case 1:
        return textDefault + " sự kiện ";
      case 2:
        return textDefault + " đấu trường ";
      case 3:
        return " nhiệm vụ hằng ngày " + detailInventory?.originName ?? "";
      case 4:
        return detailInventory?.originName ?? "";
      case 5:
        return textDefault + " thách đấu ";
      case 6:
        return (
          "Vật phẩm được nhận khi thăng hạng " + detailInventory?.originName ??
          ""
        );
      default:
        return "";
    }
  };

  return (
    <div>
      <Modal open={open} onClose={onClose} disableAutoFocus={true}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            background: "#302A20",
            boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
            border: "2px solid #D0A674",
            borderRadius: "6.32136px",
            boxSizing: "border-box",
            paddingTop: 2,
            paddingBottom: 2,
            outline: 0,
            maxHeight: "95%",
          }}
        >
          <Box
            sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
            onClick={onClose}
          >
            <img src={CloseIcon} alt="..." />
          </Box>
          <Grid container direction={"column"}>
            <Grid item xs paddingLeft={2} paddingRight={2}>
              <TitleCustomComponent
                title={`${detailInventory?.name ?? ""}`}
                fontSize="22px"
                style={{ textAlign: "center" }}
              />
              <TextComponent
                content={`Bạn đang có ${detailInventory?.quantity ?? ""}`}
                styleText={{
                  textAlign: "center",
                  fontSize: "16px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={BgItemGold}
                  alt="..."
                  style={{ opacity: 0.6, mixBlendMode: "screen" }}
                />
                {detailInventory?.itemImage ? (
                  <img
                    src={detailInventory?.itemImage}
                    alt="..."
                    style={{
                      position: "absolute",
                      objectFit: "contain",
                      height: "120px",
                      maxHeight: "200px",
                      maxWidth: "200px",
                    }}
                  />
                ) : null}
              </Box>
            </Grid>
            <Grid item xs sx={{ borderTop: "1px solid #E9C896" }}>
              <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
                <TextComponent
                  content={`${detailInventory?.description ?? ""}`}
                  styleText={{
                    fontSize: "14px",
                    fontFamily: "sans-serif",
                    fontWeight: "400",
                    color: "#C9C9C9",
                    letterSpacing: "1.1px",
                    lineHeight: "17.5px",
                    whiteSpace: "pre-line",
                  }}
                />
                <Box sx={{ height: "8px" }}></Box>
                {infoEquipmentDetail?.isDetail ? (
                  <Box
                    onClick={() =>
                      dispatch(setOpenModalGiftBoxSevenDetail(true))
                    }
                  >
                    <TextComponent
                      content={`Xem chi tiết`}
                      styleText={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        color: "#FFA200",
                        letterSpacing: "1.1px",
                        lineHeight: "17.5px",
                        cursor: "pointer",
                      }}
                    />
                    <Box sx={{ height: "8px" }}></Box>
                  </Box>
                ) : null}
                {detailInventory?.originType ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        color: "#81838E",
                        letterSpacing: "1.1px",
                        lineHeight: "15px",
                        fontStyle: "Italic",
                      }}
                    >
                      {getOriginName()}
                      {detailInventory?.originType === 1 ||
                      detailInventory?.originType === 2 ? (
                        <Link
                          to={`/events/${detailInventory?.originId}`}
                          onClick={() => dispatch(setBackHome())}
                        >
                          <span
                            style={{
                              marginTop: "2px",
                              fontWeight: 500,
                              fontFamily: "sans-serif",
                              fontSize: 14,
                              cursor: "pointer",
                              color: "#FFEEA3",
                              textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                              flex: "none",
                              lineHeight: "16px",
                              order: 0,
                              flexGrow: 0,
                              fontStyle: "Italic",
                              boxShadow: "0px 1px 0px 0px #FFEEA3",
                            }}
                          >
                            {detailInventory?.originName ?? ""}
                          </span>
                        </Link>
                      ) : null}
                      {detailInventory?.originType === 5 ? (
                        <span
                          onClick={() => {
                            dispatch(
                              setChallengeRoomId(detailInventory?.originId)
                            );
                            dispatch(setOpenChallengRoomModal(true));
                          }}
                          style={{
                            marginTop: "2px",
                            fontWeight: 500,
                            fontFamily: "sans-serif",
                            fontSize: 14,
                            cursor: "pointer",
                            color: "#FFEEA3",
                            textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                            flex: "none",
                            lineHeight: "16px",
                            order: 0,
                            flexGrow: 0,
                            fontStyle: "Italic",
                            boxShadow: "0px 1px 0px 0px #FFEEA3",
                          }}
                        >
                          {detailInventory?.originName ?? ""}
                        </span>
                      ) : null}
                    </span>
                  </Box>
                ) : null}
                {/* {detailInventory?.receiverFrom ? (
                  <TextComponent
                    content={`${detailInventory?.receiverFrom ?? ""}`}
                    styleText={{
                      fontSize: "12px",
                      fontFamily: "sans-serif",
                      fontWeight: "400",
                      color: "#81838E",
                      letterSpacing: "1.1px",
                      lineHeight: "15px",
                      fontStyle: "Italic",
                      marginTop: "8px",
                    }}
                  />
                ) : null} */}
              </Box>
              {detailInventory?.permission ? (
                <Box
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {detailInventory?.awarded ? (
                    <img
                      src={BtnAwarded}
                      alt="..."
                      onClick={onClickAwarded}
                      style={{ cursor: "pointer" }}
                    />
                  ) : null}
                  {detailInventory?.exchange ? (
                    <div onClick={onClickExchange}>
                      <img
                        src={BtnExchange}
                        alt="..."
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : null}
                  {detailInventory?.use ? (
                    <img
                      src={BtnUsed}
                      alt="..."
                      style={{ cursor: "pointer" }}
                      onClick={onClickUseItem}
                    />
                  ) : (
                    // <Box sx={{ paddingTop: 2 }}></Box>
                    null
                  )}
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
