import React from "react";
import { Typography } from "@mui/material";
import { colors } from "../../config/colors/colors";

function TextComponent({ content = "", styleText = {}, maxLine = "0" }) {
  return (
    <Typography
      sx={{
        color: colors.white,
        fontWeight: 700,
        fontSize: 12,
        fontFamily: "SVN-Gotham",
        display: "-webkit-box",
        lineClamp: 1,
        textOverflow: "ellipsis",
        whiteSpace: "initial",
        overflow: "hidden",
        WebkitLineClamp: maxLine,
        WebkitBoxOrient: "vertical",
        ...styleText,
      }}
    >
      {content}
    </Typography>
  );
}

export default TextComponent;
