import { Box, Grid, Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import TextComponent from "components/Texts/TextComponent";
import BtnOpenNow from "../../assets/images/open-now.png";
import BttBackHome from "../../assets/images/btn-back-home.png";
import BtnCancel from "../../assets/images/btn-cancel.png";
import IconReceive from "assets/images/button-receive.png";
import CloseIcon from "../../assets/images/btn-close.png";
import { useDispatch, useSelector } from "react-redux";
import {asyncChallengeRoomsJoin, asyncRemoveParticipant} from "redux/challenges/challenges.actions";
import { setMessageError } from "redux/popup/popup.slice";
import { showPopup } from "redux/popup/popup.action";
import { idChallengeJoinSelector, participantIdChallengeRemove } from "redux/challenges/challenges.selectors";
import {
  setIdChallengeJoin,
  setTypeSearch,
} from "redux/challenges/challenges.slice";

export default function ConfirmRemoveParticipant(props) {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const idJoin = useSelector(idChallengeJoinSelector);
  const participantIdRemove = useSelector(participantIdChallengeRemove);
  const [disabled, setDisabled] = useState(false);
  const asyncRemoveParticipantChallenge = () => {
    setDisabled(true);
    dispatch(asyncRemoveParticipant({
      idJoin, participantIdRemove
    }))
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(setMessageError("Xoá người tham gia thành công."));
          dispatch(showPopup(true));
          dispatch(setTypeSearch(6));
          setDisabled(false);
          onClose();
        }
      })
      .catch((error) => {
        dispatch(
          setMessageError(error ?? "Đã có lỗi xảy ra, vui lòng thử lại sau.")
        );
        dispatch(showPopup(true));
        dispatch(setTypeSearch(6));
        onClose();
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          dispatch(setIdChallengeJoin(null));
          setDisabled(false);
          onClose();
        }}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            background: "#302A20",
            boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
            border: "2px solid #D0A674",
            borderRadius: "6.32136px",
            boxSizing: "border-box",
            outline: 0,
            maxHeight: "95%",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              height: "30px",
              display: "flex",
              justifyContent: "end",
            }}
            onClick={() => {
              dispatch(setIdChallengeJoin(null));
              setDisabled(false);
              onClose();
            }}
          >
            <img
              src={CloseIcon}
              alt="..."
              style={{
                position: "absolute",
                top: "-1px",
                right: "-1px",
              }}
            />
          </Box>
          <Grid container direction={"column"}>
            <Grid item xs>
              <Box
                sx={{
                  display: "gird",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 1,
                }}
              >
                <TextComponent
                  content={`Bạn có chắc chắn muốn xoá người này?`}
                  styleText={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "#FFEEA3",
                  }}
                />

                <Box
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "12px",
                  }}
                >
                  <div
                    onClick={() => {
                      asyncRemoveParticipantChallenge();
                    }}
                  >
                    <Grid
                      sx={{
                        borderRadius: '9px',
                        border: '1px solid #CC5A29',
                        background: 'radial-gradient(50% 50% at 50% 50%, #B64740 0%, #7B260B 100%)',
                        boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0.35)',
                        padding: '0 12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '38px',
                        fontFamily: "Be Vietnam Pro",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#ffffff",
                        cursor: 'pointer',
                      }}
                    >
                      Xoá
                    </Grid>
                  </div>
                  <Box sx={{ width: "42px" }}></Box>
                  <div onClick={() => {
                    dispatch(setIdChallengeJoin(null));
                    setDisabled(false);
                    onClose();
                  }}>
                    <img
                      src={BtnCancel}
                      alt="..."
                      style={{
                        cursor: "pointer",
                        height: "42px",
                        width: "100%",
                      }}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
