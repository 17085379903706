import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import LeaderboadIcon from "assets/images/arena/arena-leaderboard.svg";
import FightIcon from "assets/images/challenge/fight-icon.svg";
import GroupIcon from "assets/images/challenge/group.svg";
import ButtonGradientCustom from "components/Buttons/ButtonGradientCustom";
import TextComponent from "components/Texts/TextComponent";
import { colors } from "config/colors/colors";
import { useDispatch, useSelector } from "react-redux";
import { asyncListChallengeRooms, asyncChallengeRoomConfigs } from "redux/challenges/challenges.actions";
import { typeSearchSelector } from "redux/challenges/challenges.selectors";
import {
  setIdChallengeJoin,
  setTypeSearch,
} from "redux/challenges/challenges.slice";
import {
  setChallengeRoomId,
  setOpenABet,
  setOpenChallengRoomModal,
  setOpenLeaderboardModal,
} from "redux/popup/popup.slice";
import BtnCreateChallenge from "../../assets/images/challenge/btn-create-challenge.png";
import "../../config/styles/ribbon.css";
import DetailChallengeModal from "./DetailChallengeModal";
import LeaderboardChallenge from "./LeaderboardChallenge";
import { CANCELLED, FINISHED, NOT_STARTED, STARTED } from "config/constants";

const ChallengeItem = ({
  isList = false,
  isPersonal,
  isJoined,
  time,
  isOwner = false,
  setOpenCreateChallenge,
  isShowLeaderBoard = false,
}) => {
  const [hasMore, setHasMore] = useState(false);
  const typeSearch = useSelector(typeSearchSelector);

  const [listChallenges, setListChallenges] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [loadding, setLoading] = useState(false);
  const [openLeaderboard, setOpenLeaderboard] = useState(false);
  const [isAllowUserCreator, setIsAllowUserCreator] = useState(true);

  useEffect(() => {
    setLoading(true);
    asyncGetListChallengeRooms(1);
    getChallengeRoomConfig();
  }, []);

  useEffect(() => {
    if (typeSearch) {
      asyncGetListChallengeRooms(1);
    }
  }, [typeSearch]);

  const setIdChallenge = (id) => {
    dispatch(setChallengeRoomId(id));
  };

  const setOpenChallengeModal = () => {
    dispatch(setOpenChallengRoomModal(true));
  };

  const switchApiEvent = (pageNow) => {
    let params = {
      page: pageNow,
      isPersonal: isPersonal ?? "",
      isJoined: isJoined ?? 0,
      time: time ?? "",
    };
    return asyncListChallengeRooms(params);
  };

  const getChallengeRoomConfig = async () => {
    await dispatch(asyncChallengeRoomConfigs({})).unwrap()
    .then((originalPromiseResult) => {
      setIsAllowUserCreator(originalPromiseResult?.userCreator);
    }).catch((rejectedValueOrSerializedError) => {
    });
  };

  const asyncGetListChallengeRooms = async (pageNow) => {
    await dispatch(switchApiEvent(pageNow))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(setTypeSearch(null));
        if (originalPromiseResult?.length > 0) {
          if (pageNow === 1) {
            setListChallenges(originalPromiseResult);
          } else {
            const newList = [...listChallenges, ...originalPromiseResult];
            setListChallenges(newList);
            setPage(pageNow);
          }
        } else {
          setHasMore(false);
          if (pageNow === 1) {
            setListChallenges([]);
          }
        }
        setLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
      });
  };

  const fetchMoreData = () => {
    setHasMore(true);
    const pageLoadMore = page + 1;
    const idTimeOut = setTimeout(
      () => asyncGetListChallengeRooms(pageLoadMore),
      500
    );
    return () => clearTimeout(idTimeOut);
  };

  const ChallengeComponent = ({ items }) => {
    return (
      <Box
        sx={{
          display: "grid",
          gridAutoColumns: "1fr",
          gap: 1,
          bgcolor: "#514533",
          padding: "5px",
          borderRadius: "5px",
          alignItems: "center",
          position: "relative",
          cursor:
            items?.status === FINISHED || items?.status === CANCELLED
              ? "pointer"
              : "pointer",
        }}
        onClick={(event) => {
          if (items?.status === FINISHED || items?.status === CANCELLED) {
            dispatch(setIdChallengeJoin(items?.id));
            setIdChallenge(items?.id);
            setOpenChallengeModal();
          } else {
            setIdChallenge(items?.id);
            dispatch(setIdChallengeJoin(items?.id));
            setOpenChallengeModal();
          }
        }}
      >
        {items?.status === FINISHED ? (
          <div className="ribbon">
            <span>HOÀN THÀNH</span>
          </div>
        ) : null}

        {items?.status === CANCELLED ? (
          <div className="ribbon red">
            <span>HUỶ KÈO</span>
          </div>
        ) : null}

        <Box
          sx={{
            gridRow: "1",
            gridColumn: "span 4",
            display: "grid",
            gridTemplateRows: "repeat(3, 1fr)",
          }}
        >
          <Box
            sx={{
              fontFamily: "SVN-Gotham",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "14px",
              textTransform: "uppercase",
              color: "#FFFFFF",
            }}
          >
            {items.name ?? ""}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={items?.owner?.rank?.icon ?? ""}
                width="20px"
                alt="..."
              />
              <Typography
                sx={{
                  fontFamily: "SVN-Gotham",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "10px",
                  textTransform: "uppercase",
                  color: "#EEBA3A",
                  marginLeft: "2px",
                }}
              >
                {items?.owner?.name ?? ""}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "10px",
                alignItems: "center",
              }}
            >
              <img src={GroupIcon} width="20px" alt="..." />
              <Typography
                sx={{
                  fontFamily: "SVN-Gotham",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "10px",
                  textTransform: "uppercase",
                  color: "#FFFFFF",
                  marginLeft: "5px",
                }}
              >
                {items?.participants ?? ""} / {items?.quantity ?? ""}{" "}
                {items?.objectParticipant}
              </Typography>
            </Box>
            {(items?.status === FINISHED || items?.status === STARTED) &&
            time !== 3 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIdChallenge(items?.id);
                  dispatch(setOpenLeaderboardModal(true));
                }}
              >
                <img src={LeaderboadIcon} width={"20px"} alt="..." />
                <Typography
                  sx={{
                    fontFamily: "SVN-Gotham",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "10px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                    marginLeft: "5px",
                  }}
                >
                  BXH
                </Typography>
              </Box>
            ) : null}
          </Box>
          <div
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              WebkitOverflowScrolling: "touch",
              display: "flex",
            }}
          >
            {items?.prizes.length > 0 ? (
              items?.prizes.map((item, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    width: "auto",
                    flexShrink: 0,
                    paddingRight: "8px",
                    alignItems: "center",
                  }}
                >
                  {item?.icon ? (
                    <img src={item?.icon} width="20px" alt="..." />
                  ) : null}

                  <Typography
                    sx={{
                      fontFamily: "SVN-Gotham",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "10px",
                      textTransform: "uppercase",
                      color: "#fff",
                      marginLeft: "5px",
                    }}
                  >
                    {item?.quantity ?? 0} {item?.name ?? ""}
                  </Typography>
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "auto",
                  flexShrink: 0,
                  paddingRight: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "SVN-Gotham",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "10px",
                    textTransform: "uppercase",
                    color: "#fff",
                    marginLeft: "5px",
                  }}
                >
                  Không có phần thưởng
                </Typography>
              </div>
            )}
          </div>
        </Box>
        {items.isOwner && isJoined === 1 && items?.status === NOT_STARTED ? (
          <Box sx={{ gridRow: "1", gridColumn: "span 1", display: "flex" }}>
            <ButtonGradientCustom
              gradient="radial-gradient(50.61% 50% at 49.39% 50%, #C46200 0%, #A33300 100%)"
              lable="Set kèo"
              onClick={(event) => {
                setIdChallenge(items?.id);
                dispatch(setIdChallengeJoin(items?.id));
                setOpenChallengeModal();
              }}
            />
          </Box>
        ) : isJoined === 1 &&
          items?.status !== FINISHED &&
          items?.status !== CANCELLED ? (
          <Box
            sx={{
              gridRow: "1",
              gridColumn: "span 1",
              display: "flex",
              zIndex: 1,
            }}
          >
            <ButtonGradientCustom
              gradient="radial-gradient(50% 50% at 50% 50%, #2E97FF 0%, #275ED8 100%)"
              lable="Soi kèo"
              onClick={(event) => {
                setIdChallenge(items?.id);
                dispatch(setIdChallengeJoin(items?.id));
                setOpenChallengeModal();
              }}
            />
          </Box>
        ) : null}

        {isJoined === 0 && items?.status === NOT_STARTED ? (
          <Box
            sx={{
              gridRow: "1",
              gridColumn: "span 1",
              opacity: items?.isAllowJoin ? 1 : 0.5,
              cursor: items?.isAllowJoin ? "pointer" : "unset",
            }}
          >
            <Box
              sx={{
                bgcolor: "#3A3327",
                textAlign: "center",
                padding: "5px",
                borderRadius: "3px",
              }}
              onClick={(event) => {
                if (items?.isAllowJoin) {
                  // setIdChallenge(items?.id);
                  // dispatch(setIdChallengeJoin(items?.id));
                  // dispatch(setOpenABet(true));
                }
              }}
            >
              <img
                src={FightIcon}
                width="50px"
                alt="..."
                style={{ margin: "auto" }}
              />

              <Typography
                sx={{
                  fontFamily: "SVN-Gotham",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "10px",
                  textTransform: "uppercase",
                  color: "#EEBA3A",
                }}
              >
                {"Nhận kèo"}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
    );
  };

  return (
    <>
      <InfiniteScroll
        dataLength={listChallenges?.length}
        next={fetchMoreData}
        hasMore={true}
        style={{
          position: "relative",
        }}
        loader={
          hasMore ? (
            <Box
              key={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              <CircularProgress size={"16px"} style={{ color: "white" }} />
            </Box>
          ) : null
        }
        height={isOwner ? 458 : 516}
      >
        {listChallenges?.length > 0 ? (
          listChallenges.map((data, i) => (
            <Box sx={{ margin: "7px", maxHeight: "500px" }} key={i}>
              {/* {isList ? (
                <Link to={`/challenge/${23}`}>
                  <ChallengeComponent items={data} />
                </Link>
              ) : (
                <ChallengeComponent items={data} />
              )} */}
              <ChallengeComponent items={data} />
            </Box>
          ))
        ) : (
          <TextComponent
            content={`${
              isOwner
                ? "Bạn chưa có phòng thách đấu"
                : "Không có dữ liệu cần tìm"
            }`}
            styleText={{ textAlign: "center", marginTop: 2 }}
          />
        )}
      </InfiniteScroll>
      {isOwner && isAllowUserCreator ? (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            bgcolor: "#564E3E",
            width: "100%",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Box
              sx={{
                textAlign: "center",
                display: "grid",
                justifyContent: "center",
              }}
            >
              <img
                src={BtnCreateChallenge}
                alt="..."
                onClick={
                  setOpenCreateChallenge
                    ? () => setOpenCreateChallenge(true)
                    : () => {}
                }
                style={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
      {loadding ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "8px",
            position: "absolute",
            width: "100%",
            height: "100%",
            background: colors.back500,
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <CircularProgress
            size={"30px"}
            style={{ color: "white" }}
            disableShrink={true}
          />
        </Box>
      ) : null}
    </>
  );
};

export default React.memo(ChallengeItem);
