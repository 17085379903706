import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BorderImageBlue from "assets/images/border-blue.png";
import IconPerform from "assets/images/button-perform.png";
import IconReceive from "assets/images/button-receive.png";
import ButtonSeeMore from "assets/images/button-see-more.png";
import BorderImage from "assets/images/icon-border-1.png";
import IconDailyMission from "assets/images/icon-daily-mission.svg";
import IconDone from "assets/images/icon-done.svg";
import IconEvent from "assets/images/icon-event.svg";
import IconExp from "assets/images/icon-exp.svg";
import IconHourglass from "assets/images/icon-hourglass.svg";
import IconObdCoin from "assets/images/icon-obd-coin.svg";
import IconPrize from "assets/images/icon-prize.svg";
import IconRank from "assets/images/rank-icon.svg";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import ButtonIconComponent from "components/Buttons/ButtonIconComponent";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { colors } from "config/colors/colors";

import { useDispatch, useSelector } from "react-redux";
import ArenaRankingComponent from "components/ArenaRankingComponent";
import SampleEventComponent from "components/event/SampleEventComponent";
import { hoverBg, hoverBgBlue, hoverBgButton } from "config/styles/common";
import { setOpen } from "redux/popup/popup.slice";
import InfoUserModal from "components/Modal/InfoUserModal";
import {
  setCompareToFirstInfoUser,
  setIndexInfoUserAction,
} from "redux/users/user.slice";
import { indexInfoRankUserSelector } from "redux/users/user.selectors";
import { Link } from "react-router-dom";
import { setSrcMusic } from "redux/musics/music.slice";
import { asyncGetMusic } from "redux/users/user.actions";
import Iframe from "react-iframe";

const backgroundColor = "#1E1E1E";

function BoxProgress({ questsCompleted, totalQuests }) {
  let precentQuests = (questsCompleted / totalQuests) * 100;
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: `rgba(0, 0, 0, 0.3);`,
        borderRadius: "3px",
        height: "14px",
        display: "flex",
        justifyContent: precentQuests > 0 ? "start" : "center",
        alignItems: "center",
        maxWidth: "130px",
      }}
    >
      {precentQuests > 0 ? (
        <Box
          sx={{
            width: `${precentQuests}%`,
            maxWidth: "130px",
            borderRadius: "3px",
            bgcolor: "#00D22E",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <TextComponent
            content={`${questsCompleted}/${totalQuests}`}
            styleText={undefined}
          />
        </Box>
      ) : (
        <TextComponent
          content={`${questsCompleted}/${totalQuests}`}
          styleText={undefined}
        />
      )}
    </Box>
  );
}

function DailyQuests(params) {
  const dispatch = useDispatch();
  return (
    <>
      <Grid
        container
        rowSpacing={2}
        paddingTop={2}
        sx={{
          maxHeight: "500px",
          overflow: "hidden",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        {mockArray.map((item, i) => {
          return (
            <Grid item key={i} xs={12} container flexWrap={"nowrap"}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  bgcolor: colors.bg_item,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                  boxShadow: `0px 4.95494px 0.619367px rgba(0, 0, 0, 0.15)`,
                }}
              >
                <Grid item sx={{ flexGrow: 1 }}>
                  <Box sx={{ flexGrow: 1, marginLeft: 1 }}>
                    <TextComponent
                      content={`${item.title}`}
                      styleText={{
                        paddingBottom: "4px",
                      }}
                    />
                    <BoxProgress
                      questsCompleted={item.questsCompleted}
                      totalQuests={item.totalQuests}
                    />
                  </Box>
                </Grid>
                {item.pointObd ? (
                  <Grid
                    item
                    xs={"auto"}
                    md={"auto"}
                    lg={"auto"}
                    container
                    sx={{
                      marginLeft: 1,
                    }}
                  >
                    <ButtonIconComponent icon={IconObdCoin} expPoints={"600"} />
                  </Grid>
                ) : null}
                {item.pointExp ? (
                  <Grid
                    item
                    xs={"auto"}
                    md={"auto"}
                    lg={"auto"}
                    container
                    sx={{
                      marginLeft: 1,
                    }}
                  >
                    <ButtonIconComponent icon={IconExp} expPoints={"120"} />
                  </Grid>
                ) : null}
                {item.type === "done" ? (
                  <Grid item>
                    <img
                      src={IconDone}
                      alt='...'
                      style={{
                        margin: "14px",
                        cursor: "pointer",
                        ...hoverBg,
                      }}
                    />
                  </Grid>
                ) : null}
                {["receive"].includes(item.type) ? (
                  <Box sx={{ margin: "8px" }}>
                    <img
                      src={IconReceive}
                      alt='...'
                      style={{ cursor: "pointer" }}
                      onClick={() => dispatch(setOpen(true))}
                    />
                  </Box>
                ) : null}
                {["perform"].includes(item.type) ? (
                  <Box sx={{ margin: "8px" }}>
                    <img
                      src={IconPerform}
                      alt='...'
                      style={{ cursor: "pointer", ...hoverBg }}
                      // @ts-ignore
                      onClick={() => dispatch(setOpen(true))}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          bgcolor: colors.bg_box,
          width: "100%",
        }}
      >
        <TextComponent
          content={`Hệ thống nhiệm vụ được reset lại sau 00:00 hàng ngày`}
          styleText={{
            fontSize: "11px",
            fontColor: "#D5D5D5",
            lineHeight: "17px",
            textAlign: "center",
            paddingTop: 2,
            paddingBottom: 1,
          }}
        />
      </Box>
    </>
  );
}

const mockArray = [
  {
    id: 1,
    questsCompleted: 2,
    totalQuests: 5,
    type: "receive",
    disabledReceive: true,
    title: "Hoàn thành nhiệm vụ",
    pointExp: "600",
    pointObd: "120",
  },
  {
    id: 2,
    pointExp: "300",
    pointObd: null,
    questsCompleted: 5,
    totalQuests: 10,
    type: "receive",
    disabledReceive: false,
    title: "Nhận cơ hội",
  },
  {
    id: 3,
    pointExp: null,
    pointObd: "12/30",
    questsCompleted: 6,
    totalQuests: 10,
    type: "receive",
    disabledReceive: false,
    title: "Gọi điện chăm sóc khách hàng",
  },
  {
    id: 4,
    pointExp: 160,
    pointObd: null,
    questsCompleted: 0,
    totalQuests: 3,
    type: "perform",
    disabledReceive: false,
    title: "Tối ưu cơ hội cũ",
  },
  {
    id: 5,
    pointExp: 100,
    pointObd: null,
    questsCompleted: 1,
    totalQuests: 1,
    type: "receive",
    disabledReceive: false,
    title: "Báo cáo công việc",
  },
  {
    id: 6,
    pointExp: 60,
    pointObd: null,
    questsCompleted: 6,
    totalQuests: 10,
    type: "done",
    disabledReceive: false,
    title: "Tham gia họp",
  },
];

export const getBorderBox = (index) => {
  switch (index) {
    case 0:
      return BorderImage;
    case 1:
      return BorderImageBlue;
    default:
      return BorderImage;
  }
};

function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listRank, setListRank] = useState([]);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const infoUserSelectDetail = useSelector(indexInfoRankUserSelector);

  useEffect(() => {
    dispatch(asyncGetMusic({ page: "" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
  }, []);

  const getBorderBox = (index) => {
    switch (index) {
      case 0:
        return BorderImage;
      case 1:
        return BorderImageBlue;
      default:
        return BorderImage;
    }
  };

  const getHeaderTitleBox = (index) => {
    switch (index) {
      case 0:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"Nhiệm vụ hằng ngày"} />
            <TextComponent content={"30/01/2023"} />
          </Box>
        );
      case 1:
        return null;
      default:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent
              title={"XẾP HẠNG ĐẤU TRƯỜNG 7"}
              fontSize='13px'
              maxLine={"2"}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={IconHourglass} alt='...' />
              <TextComponent content={"3 ngày 20 giờ 59 phút"} maxLine='1' />
            </Box>
          </Box>
        );
    }
  };

  const getHeaderIconBox = (index) => {
    switch (index) {
      case 0:
        return <img src={IconDailyMission} alt='...' />;
      case 1:
        return null;
      default:
        return <img src={IconRank} alt='...' />;
    }
  };

  const getHeaderButtonRightBox = (index) => {
    switch (index) {
      case 0:
        return null;
      case 1:
        return null;
      default:
        return (
          <ButtonIconComponent
            icon={IconPrize}
            expPoints={"Giải thưởng"}
            styleBox={{
              maxWidth: "100px",
              width: "90px",
              border: "2px solid #98690B",
              background: `rgba(217, 217, 217, 0.1)`,
              borderRadius: 3,
              cursor: "pointer",
              marginTop: 0,
              ...hoverBgButton,
            }}
            styleText={{
              color: "white",
              paddingRight: "4px",
              paddingLeft: "4px",
            }}
            isBorderText={false}
            onPress={() => {
              dispatch(setOpen(true));
            }}
          />
        );
    }
  };

  const url = process.env.REACT_APP_URL;

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor, flex: 1, paddingBottom: 1 }}
    >
      <Grid container columnSpacing={3}>
        {Array.from({ length: 1 }).map((_, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={index === 0 ? 4 : 8}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              headerTitle={getHeaderTitleBox(index)}
              headerIcon={getHeaderIconBox(index)}
              borderImageBox={getBorderBox(index)}
              headerBtnRight={getHeaderButtonRightBox(index)}
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              styleHoverBg={index === 1 ? hoverBgBlue : {}}
              background={undefined}
              isArena={false}
              arenaInfo={undefined}
            >
              {index === 0 ? <DailyQuests /> : null}
            </BoxWrapperComponent>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
        >
          <Iframe
            url={url}
            width='100%'
            height='605px'
            id=''
            className=''
            styles={{
              maxHeight: "605px",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
