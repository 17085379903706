// @ts-nocheck
/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import { checkAuthenAction, getRankUsers } from "./user.actions";
import { createSlice } from "@reduxjs/toolkit";

const detailUserSlice = createSlice({
  name: "user",
  initialState: {
    error: false,
    loading: false,
    success: false,
    user: {},
    listRankUsers: [],
    indexInfoRankUser: null,
    rankCurrent: null,
    household: {},
    community: {},
    system: {},
    compareToFirstInfoUser: null,
    configIndexs: [],
  },
  reducers: {
    setUserAction: (state, { payload }) => {
      state.user = payload;
    },
    setIndexInfoUserAction: (state, { payload }) => {
      state.indexInfoRankUser = payload;
    },
    setListRankUserProfile: (state, { payload }) => {
      if (payload.page === 1) {
        state.listRankUsers = payload.data;
      } else {
        state.listRankUsers = [...state.listRankUsers, ...payload.data];
      }
    },
    setRankCurrent: (state, { payload }) => {
      state.rankCurrent = payload;
    },
    setDataHousehold: (state, { payload }) => {
      state.household = payload;
    },
    setDataSystem: (state, { payload }) => {
      state.system = payload;
    },
    setDataCommunity: (state, { payload }) => {
      state.community = payload;
    },
    setCompareToFirstInfoUser: (state, { payload }) => {
      state.compareToFirstInfoUser = payload;
    },
    setConfigIndexs: (state, { payload }) => {
      state.configIndexs = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthenAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAuthenAction.rejected, (state) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(checkAuthenAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.user = payload?.data;
      })
      .addCase(getRankUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRankUsers.rejected, (state) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(getRankUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      });
  },
});

export const {
  setUserAction,
  setIndexInfoUserAction,
  setListRankUserProfile,
  setRankCurrent,
  setDataHousehold,
  setDataCommunity,
  setDataSystem,
  setCompareToFirstInfoUser,
  setConfigIndexs,
} = detailUserSlice.actions;
export default detailUserSlice;
