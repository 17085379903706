import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { KeyValueComponent } from "components/titles/KeyValueComponent";
import { TitleComponent } from "components/titles/TitleComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postAwardInven } from "redux/inventory/inven.action";
import {
  invenSelector,
  tabInvenSelector,
} from "redux/inventory/inven.selector";
import {
  setAwardedUser,
  setDetailInven,
  setReloadInven,
} from "redux/inventory/inven.slice";
import { showPopup } from "redux/popup/popup.action";
import { popupSelector } from "redux/popup/popup.selector";
import {
  setMessageError,
  setOpenLoading,
  setOpenModalEquipmentDetail,
  setOpenModalSearchAward,
} from "redux/popup/popup.slice";
import BtnCancel from "../../assets/images/btn-cancel.png";
import CloseIcon from "../../assets/images/btn-close.png";
import BtnSearch from "../../assets/images/btn-search.png";
import BtnAwarded from "../../assets/images/btn-trao-tang.png";
import SearchArenaRankingModal from "./SearchArenaRankingModal";

export default function AwardedModal(props) {
  const { open, onClose, onClickBtnLeft } = props;

  const [value, setvalue] = useState(0);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [scope, setScope] = useState("");
  const [userName, setUserName] = useState("");
  const [type, setType] = useState("user");
  const [number, setNumber] = useState("");
  const { detailInventory, awardedUser } = useSelector(invenSelector);
  const { isOpenModalSearchAward, isOpen } = useSelector(popupSelector);
  const tabInvenSelect = useSelector(tabInvenSelector);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setNumber("");
    setUserName("");
    setvalue(event.target.value);
    switch (event.target.value) {
      case 1:
        setScope("HKD");
        setType("household");
        break;
      case 2:
        setScope("HT");
        setType("system");
        break;
      case 3:
        setScope("CD");
        setType("community");
        break;
      case 0:
        setScope("");
        setType("user");
        break;
      default:
        setScope("");
        setType("user");
        break;
    }
  };

  const switchSelectUser = () => {
    switch (tabInvenSelect) {
      case 1:
        return <MenuItem value={1}>Hộ kinh doanh</MenuItem>;
      case 2:
        return [
          <MenuItem key={1} value={1}>
            Hộ kinh doanh
          </MenuItem>,
          <MenuItem key={2} value={2}>
            Hệ thống
          </MenuItem>,
        ];
      case 3:
        return [
          <MenuItem key={1} value={1}>
            Hộ kinh doanh
          </MenuItem>,
          <MenuItem key={2} value={2}>
            Hệ thống
          </MenuItem>,
          <MenuItem key={3} value={3}>
            Cộng đồng
          </MenuItem>,
        ];
      default:
        return null;
    }
  };

  const onChangeInput = (event) => {
    setUserName(event.target.value);
  };

  useEffect(() => {
    if (awardedUser?.name !== undefined) {
      setUserName(awardedUser?.name);
    }
  }, [awardedUser]);

  const onClickAward = () => {
    if (!detailInventory?.permission) {
      dispatch(setMessageError("Bạn không có quyền trao tặng vật phẩm này"));
      dispatch(showPopup(true));
      return;
    }
    if (userName === "") {
      dispatch(setMessageError("Chọn người dùng được trao tặng"));
      dispatch(showPopup(true));
    } else {
      dispatch(setOpenLoading(true));
      dispatch(setReloadInven(false));
      dispatch(
        postAwardInven({
          type: type,
          inventoryId: detailInventory?.id,
          receiverId: awardedUser?.id,
          quantity: number,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.success) {
            dispatch(setMessageError(res?.message));
            dispatch(showPopup(true));
            if (!isOpen) {
              dispatch(setOpenModalEquipmentDetail(false));
              dispatch(setDetailInven({}));
              setNumber("");
              setType("user");
              setUserName("");
              onClose();
              dispatch(setReloadInven(true));
            }
          } else {
            dispatch(setMessageError(res?.message));
            dispatch(showPopup(true));
          }
        })
        .catch((error) => {
         dispatch(
           setMessageError(error ?? "Đã có lỗi xảy ra, vui lòng thử lại sau.")
         );
          dispatch(showPopup(true));
        });
      setTimeout(() => {
        dispatch(setOpenLoading(false));
      }, 500);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setNumber("");
          setType("user");
          setUserName("");
        }}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "white",
            background: "#302A20",
            boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
            border: "2px solid #D0A674",
            borderRadius: "6.32136px",
            boxSizing: "border-box",
            paddingTop: 2,
            paddingBottom: 2,
            outline: 0,
            maxHeight: "95%",
          }}
        >
          <SearchArenaRankingModal
            open={isOpenModalSearchAward}
            onClose={() => dispatch(setOpenModalSearchAward(false))}
            setOpenModalInfo={setOpenModalInfo}
            switchCallApi={scope}
            paramId={undefined}
            valueScope={undefined}
            valueIndex={undefined}
            isEmployeeList={true}
            keyword={userName}
            isAwardInven={true}
          />

          <Box
            sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
            onClick={() => {
              onClose();
              setNumber("");
              setType("user");
              setUserName("");
            }}
          >
            <img src={CloseIcon} alt="..." />
          </Box>
          <Grid container direction={"column"}>
            <TitleCustomComponent
              title={"Trao tặng"}
              fontSize="22px"
              style={{
                textAlign: "center",
                marginBottom: "16px",
              }}
            />
            <Grid
              item
              xs
              paddingLeft={"14px"}
              paddingRight={"14px"}
              marginBottom={2}
              container
              direction={"row"}
            >
              <Grid
                xs={7}
                item
                paddingRight={2}
                justifyContent={"nomal"}
                display={"grid"}
              >
                <TitleComponent title="Thông tin" />
                <KeyValueComponent
                  title={"Tên vật phẩm:"}
                  value={`${detailInventory?.name ?? ""}`}
                />
                <KeyValueComponent
                  title={"Tổng số lượng:"}
                  value={`${detailInventory?.quantity ?? 0}`}
                />
                <KeyValueComponent
                  title={"Được sử dụng:"}
                  value={`${detailInventory?.quantityAwarded ?? 0}`}
                />
                <KeyValueComponent
                  title={"Chờ sử dụng:"}
                  value={`${detailInventory?.quantityRequest ?? 0}`}
                />
                <KeyValueComponent
                  title={"Sắp hết hạn:"}
                  value={`${detailInventory?.quantityAwardedExpired ?? 0}`}
                />
                <KeyValueComponent
                  title={"Ngày bắt đầu:"}
                  value={`${detailInventory?.awardedStartAt ?? ""}`}
                />
                <KeyValueComponent
                  title={"Hạn sử dụng:"}
                  value={`${detailInventory?.awardedExpiredAt ?? ""}`}
                />
              </Grid>
              <Grid xs={5} item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <Box
                    sx={{
                      width: "94px",
                      height: "114px",
                      p: 2,
                      border: "1px solid #5A4A3E",
                      bgcolor: "#26221E",
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={detailInventory?.itemImage ?? WarningIcon}
                      alt="..."
                      style={{ width: "100%" }}
                    />
                  </Box> */}
                  <Box
                    sx={{
                      alignContent: "space-between",
                      display: "grid",
                      marginLeft: 1,
                    }}
                  >
                    <TextComponent
                      content="Chọn đối tượng trao tặng"
                      styleText={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        color: "#C9C9C9",
                        letterSpacing: "1px",
                        lineHeight: "17.5px",
                      }}
                    />
                    <FormControl fullWidth>
                      <Select
                        value={value}
                        defaultValue={value}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          height: "40px",
                          fontSize: 14,
                          fontWeight: 500,
                          fontFamily: "Be Vietnam Pro",
                          color: "white",
                          bgcolor: "#493E2D",
                          borderRadius: "5px",
                          border: "1px solid #D6B88A",
                          boxShadow: "none",
                          paddingLeft: "5px",
                          "& .MuiSvgIcon-root": {
                            color: "#FFF0B1",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor: "#493E2D",
                              color: "blue",
                              "& .MuiMenuItem-root": {
                                fontSize: "14px",
                                fontWeight: 500,
                                fontFamily: "Be Vietnam Pro",
                                color: "white",
                              },
                            },
                          },
                        }}
                        variant="standard"
                        disableUnderline
                      >
                        <MenuItem key={0} value={0}>
                          Nhân viên
                        </MenuItem>
                        {switchSelectUser()}
                      </Select>
                    </FormControl>
                    <TextComponent
                      content="Số lượng"
                      styleText={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        color: "#C9C9C9",
                        letterSpacing: "1px",
                        lineHeight: "17.5px",
                        marginTop: "8px",
                      }}
                    />
                    <TextField
                      required
                      placeholder="Nhập số lượng"
                      size="small"
                      autoComplete="off"
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      sx={{
                        ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "white",
                          fontSize: "15px",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          letterSpacing: "2px",
                          lineHeight: "17.5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "white",
                          "& fieldset": {
                            borderColor: "#D6B88A",
                            borderRadius: "8px",
                          },
                          "&:hover fieldset": {
                            borderColor: "#D6B88A",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D6B88A",
                          },
                        },
                      }}
                      value={number}
                      onChange={(e) =>
                        setNumber(e.target.value.replace(/\D/g, ""))
                      }
                    />
                    <TextComponent
                      content="Tìm người dùng bạn muốn trao tặng"
                      styleText={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        color: "#C9C9C9",
                        letterSpacing: "1px",
                        lineHeight: "17.5px",
                        marginTop: "8px",
                      }}
                    />
                    <TextField
                      required
                      // label='Tên người dùng'
                      autoComplete="off"
                      size="small"
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      placeholder="Tên người dùng"
                      sx={{
                        ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "white",
                          fontSize: "15px",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          letterSpacing: "2px",
                          lineHeight: "17.5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "white",
                          "& fieldset": {
                            borderColor: "#D6B88A",
                            borderRadius: "8px",
                          },
                          "&:hover fieldset": {
                            borderColor: "#D6B88A",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D6B88A",
                          },
                        },
                      }}
                      value={userName}
                      onChange={onChangeInput}
                    />
                    <div
                      onClick={() => {
                        dispatch(setOpenModalSearchAward(true));
                        dispatch(setAwardedUser());
                      }}
                      style={{
                        cursor: "pointer",
                        width: "40%",
                        marginTop: "8px",
                      }}
                    >
                      <img src={BtnSearch} alt="..." />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs sx={{ borderTop: "1px solid #E9C896" }}>
              <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
                <Box sx={{ height: "8px" }}></Box>
                <TextComponent
                  content="Vật phẩm sau khi trao tặng sẽ mất trong dương đồ của bạn, lưu ý khi trao tặng."
                  styleText={{
                    fontSize: "12px",
                    fontFamily: "sans-serif",
                    fontWeight: "400",
                    color: "#81838E",
                    letterSpacing: "1.1px",
                    lineHeight: "15px",
                    fontStyle: "Italic",
                  }}
                />
              </Box>
              <Box
                sx={{
                  paddingTop: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={BtnAwarded}
                  alt="..."
                  style={{ cursor: "pointer" }}
                  onClick={onClickAward}
                />
                <Box sx={{ width: "16px" }}></Box>
                <img
                  src={BtnCancel}
                  alt="..."
                  onClick={onClickBtnLeft}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
