import { Box, Grid } from "@mui/material";
import React from "react";
import TextComponent from "../Texts/TextComponent";

export const TitleComponent = ({ title = "" }) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Box
        sx={{
          display: "flex",
          gridAutoColumns: "1fr",
          margin: "0 20px 0 20px ",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: `linear-gradient(to right, rgba(219, 180, 135, 0), #DBB487)`,
            clipPath: `polygon(100% 80%, 0% 100%, 100% 100%)`,
            gridColumn: "span 3",
            gridRow: "1",
            flex: 1,
            height: "5px",
          }}
        ></Box>
        <TextComponent
          content={title ?? ""}
          styleText={{
            textTransform: "uppercase",
            fontSize: "16px",
            gridRow: "1",
            gridColumn: "span 2",
            fontWeight: 700,
            fontFamily: "SVN-Gotham",
            color: "#FFF8E0",
            textAlign: "center",
            marginRight: 1,
            marginLeft: 1,
          }}
        />
        <Box
          sx={{
            backgroundImage: `linear-gradient(to left, rgba(219, 180, 135, 0), #DBB487 )`,
            clipPath: `polygon(0 80%, 0% 100%, 100% 100%)`,
            gridColumn: "span 3",
            gridRow: "1",
            flex: 1,
            height: "5px",
          }}
        ></Box>
      </Box>
    </Grid>
  );
};
