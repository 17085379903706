import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Pagination,
  Typography,
} from "@mui/material";
import InfoIcon from "assets/images/icon-information.svg";
import InvenOpen from "assets/images/inventory/inven-open.svg";
import HeaderBtn from "components/Buttons/HeaderButton";
import ProgressiveImage from "components/ProgressiveImage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserCurrency, getListInven } from "redux/inventory/inven.action";
import { invenSelector } from "redux/inventory/inven.selector";
import { setDetailInven, setTabInven } from "redux/inventory/inven.slice";
import { popupEquipmentDetailSelector } from "redux/popup/popup.selector";
import { setOpenModalEquipmentDetail } from "redux/popup/popup.slice";
import IconCloseModal from "../../assets/images/close-modal.png";
import CoinExchangeModal from "./CoinExchangeModal";
import EquipmentDetailsMdal from "./EquipmentDetailsMdal";

export default function InventoryModal({ open, onClose, clickItem }) {
  const [inven, setInven] = useState(0);
  const [openCoin, setOpenCoin] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [listInven, setListInven] = useState([]);
  const invenBtn = ["NV", "HKD", "HT", "CĐ"];
  const [page, setPage] = useState(1);
  const [type, setType] = useState("user");
  const [totalPage, setTotalPage] = useState(0);
  const [id, setId] = useState(0);
  const isOpentEquipmentDetail = useSelector(popupEquipmentDetailSelector);
  const { reloadInven, currency } = useSelector(invenSelector);

  const onChangeInven = (index) => {
    setInven(index);
    dispatch(setTabInven(index));
    setPage(1);
    switch (index) {
      case 0:
        setType("user");
        break;
      case 1:
        setType("household");
        break;
      case 2:
        setType("system");
        break;
      case 3:
        setType("community");
        break;
      default:
        setType("user");
        break;
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      dispatch(getListInven({ type: type, page: page }))
        .unwrap()
        .then((result) => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
          setListInven(result?.data ?? []);
          setTotalPage(result?.recordsTotal);
        });
      dispatch(fetchUserCurrency());
    }
  }, [open, type, page, reloadInven]);

  return (
    <div>
      <CoinExchangeModal open={openCoin} onClose={() => setOpenCoin(false)} />

      <Modal
        open={open}
        onClose={() => {
          setInven(0);
          onClose();
          setPage(1);
          setType("user");
        }}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "543px",
            // maxHeight: "600px",
            bgcolor: "white",
            padding: "10px",
            background: "#302A20",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
            border: "1px solid #FFEEA3",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            width: "705px",
          }}
        >
          <EquipmentDetailsMdal
            open={isOpentEquipmentDetail}
            onClose={() => {
              dispatch(setOpenModalEquipmentDetail(false));
              dispatch(setDetailInven({}));
            }}
            id={id}
          />
          <Box
            onClick={() => {
              setInven(0);
              onClose();
              setPage(1);
              setType("user");
            }}
            sx={{
              position: "absolute",
              top: "-45px",
              right: 0,
              cursor: "pointer",
            }}
          >
            <img src={IconCloseModal} alt="..." />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "70px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{}}>
              <Box sx={{ position: "relative", top: "-60px" }}>
                <img src={InvenOpen} alt="..." width="120px" />
              </Box>
            </Box>
            <Box
              sx={{
                alignSelf: "start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "40px",
                  // marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                {invenBtn.map((data, index) => (
                  <Button
                    onClick={() => onChangeInven(index)}
                    sx={{
                      borderBottom:
                        inven === index ? "1px solid #FFEEA3" : null,
                      borderRadius: "0px",
                      width: "70px",
                      bgcolor: inven === index ? "#5b4c36" : null,
                    }}
                    key={index}
                  >
                    <Typography
                      style={{
                        fontFamily: "SVN-Gotham",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        textAlign: "start",
                        color: "#FFEEA3",
                        alignSelf: "center",
                      }}
                    >
                      {data}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",

                alignSelf: "start",
              }}
            >
              {currency && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",

                    alignSelf: "start",
                  }}
                >
                  <HeaderBtn
                    label={currency?.quantity ?? 0}
                    onClick={undefined}
                    icon={currency?.image}
                    type={"coin"}
                    style={{
                      maxWidth: "40px",
                      height: "40px",
                    }}
                    containerStyle={{
                      marginTop: "5px",
                    }}
                  />
                  <img
                    src={InfoIcon}
                    alt="..."
                    width="20px"
                    style={{ marginLeft: "5px" }}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                height: "406px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ color: "#FFEEA3" }} />
            </Box>
          ) : (
            <Box
              sx={{
                width: "fit-content",
                // overflowY: "scroll",
                display: "grid",
                gap: 1,
                flexDirection: "row",
                flexWrap: "wrap",
                gridTemplateColumns: "repeat(5, 1fr)",
                height: "406px",
              }}
            >
              {Array.from({ length: 15 }).map((item, i) => {
                return (
                  <Box
                    key={i.toString()}
                    sx={{
                      width: "130px",
                      height: "130px",
                      boxSizing: "border-box",
                      bgcolor: "#2B2112",
                      border: "1px solid #443B2B",
                      borderRadius: "4px",
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {listInven[i]?.name ? (
                      <Box
                        onClick={() => {
                          clickItem(listInven[i]);
                          setId(listInven[i]?.id);
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "62px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <ProgressiveImage
                            src={listInven[i]?.imageItem ?? ""}
                            styleImg={{
                              objectFit: "contain",
                              textAlign: "center",
                              width: "100%",
                              height: "62px",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "34px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {listInven[i]?.imageEvent ? (
                            <ProgressiveImage
                              src={listInven[i]?.imageEvent ?? ""}
                              styleImg={{
                                objectFit: "contain",
                                textAlign: "center",
                                width: "25px",
                                height: "34px",
                                position: "absolute",
                                top: "0px",
                                left: "4px",
                              }}
                            />
                          ) : null}

                          <Typography
                            sx={{
                              fontFamily: "SVN-Gotham",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "14px",
                              color: "#FFFFFF",
                              position: "absolute",
                              top: 0,
                              right: 5,
                            }}
                          >
                            {listInven[i]?.quantity ?? ""}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "SVN-Gotham",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "16px",
                              lineHeight: "17px",
                              textAlign: "center",
                              color: "#FFFFFF",
                            }}
                          >
                            {listInven[i]?.name}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          )}

          <Box
            sx={{
              height: "35px",
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            {/* <Button
              onClick={() => setOpenCoin(true)}
              sx={{
                border: "1px solid #FFEEA3",
                borderLeft: "1px solid #FFEEA3",
                borderRadius: "5px",
                width: "100px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "SVN-Gotham",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "14px",
                  textAlign: "start",
                  color: "#FFEEA3",
                  alignSelf: "center",
                }}
              >
                Đổi coin
              </Typography>
            </Button> */}
            <Pagination
              className="pagination"
              count={
                totalPage % 15 !== 0
                  ? Math.floor(totalPage / 15) + 1
                  : totalPage / 15
              }
              shape="rounded"
              page={page}
              onChange={(event, page) => {
                setPage(page);
              }}
              sx={{
                "& .MuiPaginationItem-root": {
                  backgroundColor: "#5b4c36",
                  color: "white",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#FFEEA3",
                  color: "black",
                },
              }}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
