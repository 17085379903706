// @ts-nocheck
/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import { createSlice } from "@reduxjs/toolkit";

const eventsSlice = createSlice({
  name: "events",
  initialState: {
    leaderBoard: {},
    listNotification: {},
  },
  reducers: {
    setLeaderboard: (state, { payload }) => {
      state.leaderBoard = payload;
    },
    setListNotification: (state, { payload }) => {
      state.listNotification = payload;
    },
  },
});

export const { setLeaderboard, setListNotification } = eventsSlice.actions;

export default eventsSlice;
