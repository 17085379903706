import { Box } from "@mui/material";

import { Container, Grid } from "@mui/material";
import BorderImageBlue from "assets/images/border-blue.png";
import BorderImage from "assets/images/icon-border-1.png";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";

import DuoAxe from "assets/images/challengeBtn.svg";
import ChallengeItem from "components/Challenge/ChallengeItem";
import { hoverBgBlue } from "config/styles/common";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { asyncGetMusic } from "redux/users/user.actions";
import { setSrcMusic } from "redux/musics/music.slice";

export default function DetailChallenge() {
  const backgroundColor = "#1E1E1E";
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(asyncGetMusic({ page: "challenge" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
  }, []);

  const getHeaderTitleBox = (index) => {
    switch (index) {
      case 0:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"Sắp diễn ra"} />
          </Box>
        );
      case 1:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"Đang diễn ra"} />
          </Box>
        );
      default:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"Đã kết thúc"} />
          </Box>
        );
    }
  };

  const getBorderBox = (index) => {
    switch (index) {
      case 0:
        return BorderImage;
      case 1:
        return BorderImageBlue;
      default:
        return BorderImage;
    }
  };

  const getTime = (index) => {
    switch (index) {
      case 0:
        return 3;
      case 1:
        return 2;
      default:
        return 1;
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor, flex: 1, paddingBottom: 1 }}
    >
      <Grid container columnSpacing={3}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              onClickArena={undefined}
              headerTitle={getHeaderTitleBox(index)}
              headerIcon={<img src={DuoAxe} alt='' width={"90px"} />}
              borderImageBox={getBorderBox(index)}
              headerBtnRight={undefined}
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              styleHoverBg={index === 1 ? hoverBgBlue : {}}
              background={undefined}
              isArena={false}
              arenaInfo={undefined}
              jointBtn={false}
            >
              <ChallengeItem
                time={getTime(index)}
                isJoined={1}
                isPersonal={3}
                isShowLeaderBoard={true}
              />
            </BoxWrapperComponent>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
