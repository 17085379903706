import { Box, Container, Grid, Typography } from "@mui/material";
import BackgroundNvImage from "assets/images/background-nv.svg";
import BackgroundImage from "assets/images/background.svg";
import CupIcon from "assets/images/cup.svg";
import FacebookIcon from "assets/images/facebook.png";
import InfoIcon from "assets/images/info.svg";
import SaveIcon from "assets/images/save.png";
import ClaimBtn from "assets/images/arena/arena-claim-btn.png";
import HeaderBtn from "components/Buttons/HeaderButton";
import ArenaWin from "assets/images/arena/arena-win.svg";
import {
  default as IconWarning,
  default as WarningIcon,
} from "assets/images/warning-icon.png";
import ArenaRankingComponent from "components/ArenaRankingComponent";
import { AvatarRank } from "components/AvatarRank";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { colors } from "config/colors/colors";
import { STAFF } from "config/constants";
import {
  configIndexsSelector,
  indexInfoRankUserSelector,
  rankCurrentSelector,
  userSelector,
} from "redux/users/user.selectors";
import { SelectByScope, scrollParentToChild } from "utils/common";
import { getBorderBox } from "../Home";
import { useEffect, useRef, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FacebookShareButton } from "react-share";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SearchArenaRankingModal from "components/Modal/SearchArenaRankingModal";
import InfoUserModal from "components/Modal/InfoUserModal";
import {
  setCompareToFirstInfoUser,
  setIndexInfoUserAction,
} from "redux/users/user.slice";
import SelectItemComponent from "components/Selects/SelectItemComponent";
import { setSrcMusic } from "redux/musics/music.slice";
import { setMessageError, setOpen } from "redux/popup/popup.slice";
import { asyncGetConfigIndex, asyncGetMusic } from "redux/users/user.actions";
import "../../../config/styles/jump.css";
import * as htmlToImage from "html-to-image";

export default function ProfileNextRank() {
  const profileUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const [listRank, setListRank] = useState([]);
  const rankCurrent = useSelector(rankCurrentSelector);
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [valueScope, setValueScope] = useState(SelectByScope[0].value);
  const [valueIndex, setValueIndex] = useState(1);
  const infoUserSelectDetail = useSelector(indexInfoRankUserSelector);
  const SelectByIndex = useSelector(configIndexsSelector);
  const scrollIndexMyRank = () => {
    const findIdUser = listRank.findIndex(
      (value) => value.id === profileUser?.id
    );
    if (findIdUser === -1) {
      listRef?.current?.props?.next(true, true);
      return;
    }

    if (
      listRef?.current &&
      listRef?.current?.el &&
      listRef?.current?.el?.children?.length > 0
    ) {
      const element =
        listRef?.current?.el?.children[findIdUser > 3 ? findIdUser - 3 : 0];
      if (element) {
        scrollParentToChild(listRef?.current?.el, element);
      }
    }
  };
  const domEl = useRef(null);

  const htmlImageAvatar = () => {
    return (
      <BoxWrapperComponent
        jointBtn={false}
        onClickArena={undefined}
        arenaInfo={undefined}
        background={
          profileUser?.type === STAFF ? BackgroundNvImage : BackgroundImage
        }
        htmlRef={domEl}
        headerTitle={
          <Box
            sx={{
              display: "grid",
              gap: 0,
              gridTemplateColumns: "repeat(1, 1fr)",
            }}
          >
            <Typography
              style={{
                fontSize: 10,
                fontWeight: 700,
                fontFamily: "SVN-Gotham",
                color: "white",
                textTransform: "uppercase",
              }}
            >
              {profileUser?.nextRank?.name ?? ""}
            </Typography>
            <Typography
              style={{
                fontSize: 20,
                fontWeight: 700,
                fontFamily: "SVN-Gotham",
                color: "white",
              }}
            >
              {profileUser?.name ?? ""}
            </Typography>
          </Box>
        }
        headerIcon={
          <img
            src={profileUser?.nextRank?.icon ?? IconWarning}
            width={50}
            alt="..."
          />
        }
        borderImageBox={getBorderBox()}
        headerBtnRight={undefined}
        // bottomLeftBtn={
        //   <FacebookShareButton
        //     url={profileUser?.nextRank?.avatar ?? ""}
        //     quote={"quat"}
        //     hashtag={"#OBD"}
        //   >
        //     <img src={FacebookIcon} width={100} alt="..." />
        //   </FacebookShareButton>
        // }
        // bottomRightBtn={
        //   <Box onClick={handleDownload} sx={{ cursor: "pointer" }}>
        //     <img src={SaveIcon} width={130} alt="..." />
        //   </Box>
        // }
        boxStyle={{
          borderImageSlice: "21 21",
          borderImageWidth: "20px",
          borderImageOutset: "11px",
          borderStyle: "solid",
          position: "relative",
          borderRadius: "9px",
          border: "1px solid transparent",
          minHeight: "670px",
        }}
        isArena={false}
      >
        <div id="avatar">
          <Box
            sx={{
              margin: "auto",
              padding: "auto",
            }}
          >
            <img
              src={profileUser?.nextRank?.avatar ?? ""}
              style={{
                objectFit: "inherit",
                maxHeight: 520,
                margin: "auto",
              }}
              className="up-down"
              alt="..."
            />
          </Box>
        </div>
      </BoxWrapperComponent>
    );
  };

  function filter(node) {
    if (node.className) {
      return !node.className.includes("download-avatar");
    }
    return true;
  }

  const handleDownload = async () => {
    const dataUrl = await htmlToImage.toPng(domEl.current, { filter: filter });
    const link = document.createElement("a");
    link.download = "rank-user.png";
    link.href = dataUrl;
    link.click();
  };

  useEffect(() => {
    dispatch(asyncGetMusic({ page: "profile" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: colors.bg_black,
          flex: 1,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Grid container columnSpacing={3} sx={{}}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            {htmlImageAvatar()}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              arenaInfo={undefined}
              isArena={false}
              background={undefined}
              headerTitle={
                <Box paddingX={1}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 700,
                      fontFamily: "SVN-Gotham",
                      color: "white",
                    }}
                  >
                    THÔNG TIN CẤP {profileUser?.nextRank?.name ? profileUser?.nextRank?.name.toUpperCase() : ""}
                  </Typography>
                </Box>
              }
              headerIcon={<img src={InfoIcon} alt="..." />}
              borderImageBox={getBorderBox(1)}
              headerBtnRight={undefined}
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              boxStyle={{ maxHeight: "570px", minHeight: "670px" }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: "repeat(1, 1fr)",
                  overflow: "scroll",
                  maxHeight: 570,
                  padding: "10px",
                }}
              >
                <Box
                  sx={{
                    margin: "auto",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  {profileUser?.nextRank ? (
                    <div style={{ position: "relative" }}>
                      <img
                        src={profileUser?.nextRank?.circleAvatar}
                        alt="avatar"
                        style={{
                          margin: "auto",
                          borderRadius: "50%",
                          backgroundColor: "gray",
                          position: "absolute",
                          top: 52,
                          left: 43,
                          width: 88,
                          height: 88,
                        }}
                      />
                      <img
                        src={profileUser?.nextRank?.frame ?? ""}
                        width={175}
                        style={{ maxWidth: "none" }}
                        alt="..."
                      />
                    </div>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <img src={WarningIcon} width={130} alt="..." />
                    </div>
                  )}

                  {profileUser?.nextRank ? (
                    <div style={{ position: "relative" }}>
                      <img
                        src={profileUser?.nextRank?.icon}
                        alt="avatar"
                        style={{
                          margin: "auto",
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <img src={WarningIcon} width={130} alt="..." />
                    </div>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "70%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: "400px",
                  padding: "10px",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  width: "410px",
                  backgroundImage: `url(${ArenaWin})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "260px",
                    height: "170px",
                    bottom: "50px",
                    left: 0,
                    right: 0,
                    margin: "auto",
                  }}
                >
                  <Box
                    sx={{
                      overflowY: "scroll",
                      height: "40px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                        lineHeight: "20px",
                        textAlign: "center",
                        width: "200px",
                        margin: "auto",
                        color: "#FFF2BC",
                      }}
                    >
                      {profileUser?.nextRank?.name ? `Chúc mừng bạn đã được tăng lên cấp ${profileUser?.nextRank?.name}` : ""}
                    </Typography>
                  </Box>
                  {profileUser?.nextRank?.prizeIcon ||
                  profileUser?.nextRank?.prizeName ||
                  profileUser?.nextRank?.prizeQuantity ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginRight: "20px",
                      }}
                    >
                      <HeaderBtn
                        onClick={undefined}
                        icon={`${
                          profileUser?.nextRank?.prizeIcon ?? WarningIcon
                        }`}
                        label={`${profileUser?.nextRank?.prizeQuantity ?? ""} ${
                          profileUser?.nextRank?.prizeName ?? ""
                        }`}
                        type={"arena-win"}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                  ) : null}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      src={ClaimBtn}
                      width="70px"
                      alt="..."
                      onClick={""}
                      style={{ marginRight: "8px" }}
                    />
                  </div>
                  {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "18px",
                        textAlign: "center",
                        color: "#FFFFFF",
                        width: "60%",
                      }}
                    >
                      Quà tặng sẽ được chuyển vào rương đồ của bạn
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
            </BoxWrapperComponent>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              jointBtn={false}
              onClickArena={undefined}
              arenaInfo={undefined}
              isArena={false}
              background={undefined}
              headerTitle={
                <Box paddingX={1}>
                  <TitleCustomComponent
                    title={"CHI TIẾT CHẾ ĐỘ ĐÃI NGỘ"}
                    fontSize="13px"
                  />
                  
                </Box>
              }
              headerIcon={<img src={CupIcon} alt="..." />}
              borderImageBox={getBorderBox()}
              headerBtnRight={undefined}
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              boxStyle={{ maxHeight: "570px", minHeight: "670px" }}
            >
              {profileUser?.nextRank?.prizeDescription ? (
                <div
                  style={{ color: "white", overflow: "scroll", padding: "10px", maxHeight: "590px", minHeight: "590px" }}
                  dangerouslySetInnerHTML={{
                    __html: profileUser?.nextRank?.prizeDescription,
                  }}
                />
              ) : (
                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 2,
                    fontFamily: "Be Vietnam Pro",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "22px",
                    textAlign: "center",
                    color: "#C9C9C9",
                  }}
                >
                  Thông tin chưa được cập nhật
                </Typography>
              )}
            </BoxWrapperComponent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
