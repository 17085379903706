/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import { createSlice } from "@reduxjs/toolkit";

const popupSlice = createSlice({
  name: "popup",
  initialState: {
    isOpen: false,
    isOpenModalListStaff: false,
    isOpenModalEquipmentDetail: false,
    isOpenModalAwarded: false,
    isOpenInven: false,
    isModalInfoUserCenter: false,
    isOpenExchangeModal: false,
    infoEquipmentDetail: {},
    isModalGiftBoxDetailSeven: false,
    isOpenCheckinModal: false,
    isOpenArenaWinModal: false,
    isOpenWheelModal: false,
    isOpenGiftBoxModal: false,
    isOpenGiftBoxSeventModal: false,
    infoGiftBox: {},
    isPlayMusic: false,
    messageError: null,
    tabShowInfo: 3,
    isOpenModalSearchAward: false,
    isOpenLoading: false,
    isOpenUseItemModal: false,
    isOpenNotification: false,
    notiAnchor: "",
    isOpenABet: false,
    firstClickWeb: false,
    unread: 0,
    challengeRoomId: null,
    isOpenChallengRoomModal: false,
    isOpenUpRankModal: false,
    infoNotification: {},
    isOpenLeaderboard: false,
    isOpenRemoveParticipant: false,
  },
  reducers: {
    setOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
    setModalInfoUserCenter: (state, { payload }) => {
      state.isModalInfoUserCenter = payload;
    },
    setOpenModalListStaff: (state, { payload }) => {
      state.isOpenModalListStaff = payload;
    },
    setOpenModalEquipmentDetail: (state, { payload }) => {
      state.isOpenModalEquipmentDetail = payload;
    },
    setOpenModalAwarded: (state, { payload }) => {
      state.isOpenModalAwarded = payload;
    },
    setOpenInven: (state, { payload }) => {
      state.isOpenInven = payload;
    },
    setOpenExchange: (state, { payload }) => {
      state.isOpenExchangeModal = payload;
    },
    setInfoEquipmentDetail: (state, { payload }) => {
      state.infoEquipmentDetail = payload;
    },
    setOpenModalGiftBoxSevenDetail: (state, { payload }) => {
      state.isModalGiftBoxDetailSeven = payload;
    },
    setOpenModalCheckin: (state, { payload }) => {
      state.isOpenCheckinModal = payload;
    },
    setOpenArenaWinModal: (state, { payload }) => {
      state.isOpenArenaWinModal = payload;
    },
    setOpenWheelModal: (state, { payload }) => {
      state.isOpenWheelModal = payload;
    },
    setOpenGiftBoxModal: (state, { payload }) => {
      state.isOpenGiftBoxModal = payload;
    },
    setOpenGiftBoxSeventModal: (state, { payload }) => {
      state.isOpenGiftBoxSeventModal = payload;
    },
    setInfoGiftBox: (state, { payload }) => {
      state.infoGiftBox = payload;
    },
    setBackHome: (state, { payload }) => {
      state.isOpenCheckinModal = false;
      state.isOpenGiftBoxModal = false;
      state.isOpenGiftBoxSeventModal = false;
      state.isOpenInven = false;
      state.isOpenModalEquipmentDetail = false;
    },
    setPlayMusic: (state, { payload }) => {
      state.isPlayMusic = payload;
    },
    setFirstClickWeb: (state, { payload }) => {
      state.firstClickWeb = true;
    },
    setMessageError: (state, { payload }) => {
      state.messageError = payload;
    },
    setTabShowInfo: (state, { payload }) => {
      state.tabShowInfo = payload ?? 3;
    },
    setOpenModalSearchAward: (state, { payload }) => {
      state.isOpenModalSearchAward = payload;
    },
    setOpenLoading: (state, { payload }) => {
      state.isOpenLoading = payload;
    },
    setOpenUseItemModal: (state, { payload }) => {
      state.isOpenUseItemModal = payload;
    },
    setOpenNotification: (state, { payload }) => {
      state.isOpenNotification = payload;
    },
    setNotiAnchor: (state, { payload }) => {
      state.notiAnchor = payload;
    },
    setOpenABet: (state, { payload }) => {
      state.isOpenABet = payload;
    },
    setUnread: (state, { payload }) => {
      state.unread = payload;
    },
    setChallengeRoomId: (state, { payload }) => {
      state.challengeRoomId = payload;
    },
    setOpenChallengRoomModal: (state, { payload }) => {
      state.isOpenChallengRoomModal = payload;
    },
    setOpenUpRankModal: (state, { payload }) => {
      state.isOpenUpRankModal = payload;
    },
    setInfoNotification: (state, { payload }) => {
      state.infoNotification = payload;
    },
    setOpenLeaderboardModal: (state, { payload }) => {
      state.isOpenLeaderboard = payload;
    },
    setOpenRemoveParticipant: (state, { payload }) => {
      state.isOpenRemoveParticipant = payload;
    },
  },
});

export const {
  setOpen,
  setOpenModalListStaff,
  setOpenModalEquipmentDetail,
  setOpenModalAwarded,
  setOpenInven,
  setModalInfoUserCenter,
  setOpenExchange,
  setInfoEquipmentDetail,
  setOpenModalGiftBoxSevenDetail,
  setOpenModalCheckin,
  setOpenArenaWinModal,
  setOpenWheelModal,
  setOpenGiftBoxModal,
  setInfoGiftBox,
  setOpenGiftBoxSeventModal,
  setBackHome,
  setPlayMusic,
  setMessageError,
  setTabShowInfo,
  setOpenModalSearchAward,
  setOpenLoading,
  setOpenUseItemModal,
  setOpenNotification,
  setNotiAnchor,
  setOpenABet,
  setFirstClickWeb,
  setUnread,
  setChallengeRoomId,
  setOpenChallengRoomModal,
  setOpenUpRankModal,
  setInfoNotification,
  setOpenLeaderboardModal,
  setOpenRemoveParticipant,
} = popupSlice.actions;

export default popupSlice;
