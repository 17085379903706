import { MenuItem, Select } from "@mui/material";
import React from "react";

export default function SelectItemComponent({ items, setValue, value }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (!(items?.length > 0)) {
    return null;
  }

  return (
    <Select
      value={value}
      sx={{
        fontSize: 14,
        fontWeight: 700,
        fontFamily: "SVN-Gotham",
        color: "#302A20",
        background: "linear-gradient(180deg, #FFF8E0 0%, #FFEEA3 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        boxShadow: "none",
        "& .MuiSvgIcon-root": {
          color: "#FFEEA3",
        },
      }}
      onChange={handleChange}
      MenuProps={{
        PaperProps: {
          sx: {
            bgcolor: "#302A20",
            color: "white",
            "& .MuiMenuItem-root": {
              fontSize: 14,
              fontWeight: 500,
              fontFamily: "SVN-Gotham",
              color: "white",
            },
          },
        },
      }}
      variant="standard"
      disableUnderline
    >
      {items?.length > 0
        ? items?.map((item, i) => {
            return (
              <MenuItem
                key={i}
                sx={{
                  background:
                    "linear-gradient(180deg, #FFF8E0 0%, #FFEEA3 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                value={item?.value}
              >
                {item?.title}
              </MenuItem>
            );
          })
        : null}
    </Select>
  );
}
