import { Box } from "@mui/material";
import React from "react";
import ProgressiveImage from "./ProgressiveImage";

export const AvatarRank = ({
  srcAvatar,
  circleFrame,
  isStaff,
  isHousehold,
}) => {
  const width = isStaff ? (isHousehold ? "80.5%" : "82.5%") : "75.5%";
  return (
    <Box
      sx={{
        width: isHousehold ? "190px" : "129px",
        height: isHousehold ? "190px" : "129px",
        position: "relative",
        display: "flex",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          border: "1px solid transparent",
          borderImage: `url(${circleFrame})`,
          backgroundSize: "auto",
          boxSizing: "border-box",
          borderImageSlice: isHousehold ? "42 43 42 43" : "38",
          borderImageWidth: isHousehold ? "50px" : "30px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <ProgressiveImage
          src={srcAvatar}
          width={width}
          styleImg={{
            height: isHousehold ? width : "",
            textAlign: "center",
            color: "transparent",
            borderRadius: "50%",
            position: isStaff ? "relative" : "absolute",
            // top: isStaff ? (isHousehold ? 10 : 0) : "33px",
            // left: isStaff ? 0 : "16px",
          }}
        />
      </Box>
    </Box>
  );
};
