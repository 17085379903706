const vi = {
  translation: {
    homepage: 'Trang chủ',
    about: 'Giới thiệu',
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
    dashboard: 'Bảng điều khiển',
    profile: 'Thông tin cá nhân',
  },
};

export default vi;
