import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import { loginAction } from "../../redux/auth/auth.actions";
import {
  authErrorSelector,
  authLoadingSelector,
  authUserSelector,
} from "../../redux/auth/auth.selectors";

export default function Login() {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authLoading = useSelector(authLoadingSelector);
  const authError = useSelector(authErrorSelector);
  const authUser = useSelector(authUserSelector);

  useEffect(() => {
    if (authUser) {
      navigate(location.state?.from || "/");
    }
  }, [authUser]);

  // @ts-ignore
  const onSubmit = (data) => dispatch(loginAction(data));

  return (
    <Container maxWidth='sm'>
      <Box my={4}>
        <Typography variant='h2' color='primary'>
          {t("login")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {authLoading === "failed" && (
          <Box mb={2}>
            <Typography variant='body1' color='error'>
              {authError}
            </Typography>
          </Box>
        )}
        <Box>
          <TextField
            required
            fullWidth
            id='login-user-name'
            label='User name'
            variant='filled'
            inputProps={{ ...register("name") }}
          />
        </Box>
        <Box mt={4}>
          <TextField
            required
            fullWidth
            id='login-password'
            label='Password'
            variant='filled'
            inputProps={{ ...register("password") }}
          />
        </Box>
        <Box py={4}>
          <LoadingButton
            variant='contained'
            type='submit'
            loading={authLoading === "pending"}
          >
            {t("login")}
          </LoadingButton>
        </Box>
      </form>
    </Container>
  );
}
