export function login(credential) {
  return new Promise((resolve, reject) => {
    const success = (Math.random().toFixed(2) * 100) % 3;
    setTimeout(
      () =>
        success
          ? resolve({
              data: { token: "fake_token", user: { name: "Fake" }, credential },
            })
          : reject(new Error("Login error")),
      500
    );
  });
}

export function refreshToken() {
  return new Promise((resolve, reject) => {
    const success = (Math.random().toFixed(2) * 100) % 3;
    setTimeout(
      () =>
        success
          ? resolve({ data: { token: "fake_token", user: { name: "Fake" } } })
          : reject(new Error("Refresh token error")),
      500
    );
  });
}
