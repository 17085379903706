import { createAsyncThunk } from "@reduxjs/toolkit";
import { attendances, listAttendances } from "api/attendance";
import { setIsAttendance } from "./attendance.slice";

export const asyncListAttendance = createAsyncThunk(
  "asyncListAttendance",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await listAttendances();
      const { data, success } = response.data;
      if (success) {
        dispatch(setIsAttendance(data?.isAttendance ?? false));
        return data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncAttendance = createAsyncThunk(
  "asyncListAttendance",
  async (params, { rejectWithValue }) => {
    try {
      const response = await attendances();
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
