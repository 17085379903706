import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import IconErrImage from "../assets/images/warning-icon.png";

function ProgressiveImage({
  src = "",
  width = "16px",
  styleImg = {},
  isLoading = true,
  styleBox = {},
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  function handleImageLoad() {
    setIsLoaded(true);
  }

  function handleImageError() {
    setIsError(true);
  }

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100%",
        ...styleBox,
      }}
    >
      {!isLoaded && isLoading && (
        <CircularProgress size={"16px"} style={{ color: "white" }} />
      )}
      {src ? (
        <img
          onLoad={handleImageLoad}
          onError={handleImageError}
          src={isError ? IconErrImage : src}
          alt="..."
          style={{
            width: width,
            height: "auto",
            display: "block",
            ...styleImg,
          }}
        />
      ) : null}
    </Box>
  );
}

export default ProgressiveImage;
