import { getAxiosClientWithToken } from ".";

export const getUser = (id) => {
  return getAxiosClientWithToken().get(`users/${id}`);
};

export const checkAuthen = () => {
  return getAxiosClientWithToken().get(`profile`);
};

export const listRankUser = (params) => {
  return getAxiosClientWithToken().get(`leaderboards`, { params });
};

export const listRankUserHousehold = (params) => {
  return getAxiosClientWithToken().get(`households/leaderboard`, { params });
};

export const listRankUserSystem = (params) => {
  return getAxiosClientWithToken().get(`systems/leaderboard`, { params });
};

export const listRankUserCommunity = (params) => {
  return getAxiosClientWithToken().get(`communities/leaderboard`, { params });
};

export const detailHousehold = () => {
  return getAxiosClientWithToken().get(`households/detail`);
};

export const detailSystem = () => {
  return getAxiosClientWithToken().get(`systems/detail`);
};

export const detailCommunity = () => {
  return getAxiosClientWithToken().get(`communities/detail`);
};

export const getUserInfo = (id, isEvent) => {
  return getAxiosClientWithToken().get(
    isEvent ? isEvent : `users/detail/${id}`
  );
};

export const getHouseholdsUserInfo = (id) => {
  return getAxiosClientWithToken().get(`households/detail/${id}`);
};

export const getSystemsUserInfo = (id) => {
  return getAxiosClientWithToken().get(`systems/detail/${id}`);
};

export const getCommunitiesUserInfo = (id) => {
  return getAxiosClientWithToken().get(`communities/detail/${id}`);
};

export const getMusics = (params) => {
  return getAxiosClientWithToken().get(`musics`, { params });
};

export const getConfigsIndex = (params) => {
  return getAxiosClientWithToken().get(`configs`, { params });
};
