import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Login from "containers/Auth/Login";
import Dashboard from "containers/Dashboard";
import About from "containers/StaticPages/About";
import Arena from "containers/StaticPages/Arena/Arena";
import DetailEvent from "containers/StaticPages/Event/DetailEvent";
import Event from "containers/StaticPages/Event/Event";
import Forbidden from "containers/StaticPages/Forbidden";
import Home from "containers/StaticPages/Home";
import HouseholdProfile from "containers/StaticPages/Profile/HouseholdProfile";
import Profile from "containers/StaticPages/Profile/Profile";
import ProfileNextRank from "containers/StaticPages/Profile/ProfileNextRank";
import { authTokenSelector } from "redux/auth/auth.selectors";
import CommunityProfile from "containers/StaticPages/Profile/CommunityProfile";
import SystemProfile from "containers/StaticPages/Profile/SystemProfile";
import Challenge from "containers/StaticPages/Challenge/Challenge";
import RankingTableAll from "containers/StaticPages/Profile/RankingTableAll";
import DetailChallenge from "containers/StaticPages/Challenge/DetailChallenge";

function AppRoutes() {
  const tokenAuth = useSelector(authTokenSelector);

  return tokenAuth ? (
    <Routes>
      <Route path='home' element={<Home />} />
      <Route path='about' element={<About />} />
      <Route path='login' element={<Login />} />
      <Route path='/' element={<Profile />} />
      <Route path='dashboard' element={<Dashboard />} />
      <Route path='household' element={<HouseholdProfile />} />
      <Route path='community' element={<CommunityProfile />} />
      <Route path='system' element={<SystemProfile />} />
      <Route path='ranking-all' element={<RankingTableAll />} />
      <Route path='arena' element={<Arena />} />
      <Route path='challenge'>
        <Route index={true} element={<Challenge />} />
      </Route>
      <Route path='challenge-management' element={<DetailChallenge />} />

      <Route path='/events'>
        <Route index={true} element={<Event />} />

        <Route index={false} path=':id' element={<DetailEvent />} />
      </Route>
      <Route path='next-rank' element={<ProfileNextRank />}/>
    </Routes>
  ) : (
    <Routes>
      <Route path='/forbidden' element={<Forbidden />} />
    </Routes>
  );
}

export default AppRoutes;
