import { Box, Grid, Modal } from "@mui/material";
import React from "react";
import BgItemGold from "../../assets/images/bg-item-gold.png";
import TextComponent from "components/Texts/TextComponent";
import BtnOpenNow from "../../assets/images/open-now.png";
import BttBackHome from "../../assets/images/btn-back-home.png";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { useSelector } from "react-redux";
import { infoGiftBoxSelector } from "redux/popup/popup.selector";

export default function GiftBoxSeventModal(props) {
  const { open, onClose, onClickOpenNow, onClickbackHome } = props;
  const infoGiftBox = useSelector(infoGiftBoxSelector);

  return (
    <div>
      <Modal open={open} onClose={onClose} disableAutoFocus={true}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            background: "#2C271D",
            boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
            border: "2px solid #D0A674",
            borderRadius: "6.32136px",
            boxSizing: "border-box",
            paddingTop: 2,
            paddingBottom: 2,
            outline: 0,
            height: 300,
            maxHeight: "95%",
          }}
        >
          <Grid container direction={"column"}>
            <Grid item xs>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={BgItemGold}
                  alt="..."
                  style={{ opacity: 0.6, mixBlendMode: "screen" }}
                />
                <img
                  src={`${infoGiftBox?.icon ?? ""}`}
                  alt="..."
                  style={{ position: "absolute", top: -38 }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "90px",
                  }}
                >
                  <TextComponent
                    content={`Bạn vừa nhận được`}
                    styleText={{
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  />
                  <TitleCustomComponent
                    title={`${infoGiftBox?.title ?? ""}`}
                    fontSize="22px"
                    style={{ textAlign: "center" }}
                  />
                </Box>
                <Box
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "12px",
                  }}
                >
                  <div onClick={onClickOpenNow}>
                    <img
                      src={BtnOpenNow}
                      alt="..."
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Box sx={{ width: "42px" }}></Box>
                  <div onClick={onClickbackHome}>
                    <img
                      src={BttBackHome}
                      alt="..."
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
