import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import WarningIcon from "assets/images/warning-icon.png";
import { userSelector } from "redux/users/user.selectors";
import { formatNumber, getExpProgress } from "utils/common";

function Profile() {
  const profileUser = useSelector(userSelector);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Grid container spacing={0} columns={{ xl: 6, lg: 6 }}>
        <img
          src={profileUser?.rank?.icon ?? WarningIcon}
          width={50}
          alt="..."
        />
        <Box sx={{ marginLeft: 1 }}>
          <Box
            sx={{
              display: "grid",
              gap: 0,
              gridTemplateColumns: "repeat(1, 1fr)",
            }}
          >
            <Typography
              style={{
                fontSize: 10,
                fontWeight: 700,
                fontFamily: "SVN-Gotham",
                textTransform: "uppercase",
              }}
            >
              {profileUser?.rank?.name ?? ""}
            </Typography>
            <Typography
              style={{
                fontSize: 17,
                fontWeight: 700,
                fontFamily: "SVN-Gotham",
              }}
            >
              {profileUser?.name ?? " "}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        sx={{ alignItems: "center", minWidth: 300 }}
        flexGrow={1}
      >
        <Box
          sx={{
            minWidth: 35,
            // clipPath: `polygon(15% 0, 84% 0, 100% 50%, 85% 100%, 15% 100%, 0% 50%)`,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            style={{
              fontSize: 11,
              fontWeight: 700,
              padding: "5px 5px 5px 5px",
              color: "white",
              fontFamily: "SVN-Gotham",
            }}
          >
            {(profileUser?.indexes?.revenueReceived ?? "") +
              "/" +
              formatNumber(profileUser?.nextRank?.revenue)}
          </Typography>
        </Box>
        <Box sx={{ width: "40%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={getExpProgress(
              profileUser?.indexes?.revenueReceived,
              profileUser?.nextRank?.revenue
            )}
            sx={{
              borderRadius: "5px",
              backgroundColor: "gray",
              "& .MuiLinearProgress-bar": {
                backgroundImage: `linear-gradient(to left, #ff7a00, #ff9200, #ffa900, #ffc000, #ffd600)`,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        sx={{ alignItems: "center" }}
        flexGrow={1}
      >
        <Grid container>
          {profileUser?.community?.name ? (
            <Grid item display="flex">
              <img
                src={profileUser?.community?.rank?.icon ?? WarningIcon}
                width={20}
                alt="..."
              />
              <Link to="/community">
                <Typography
                  color={"#FED01B"}
                  style={{ marginLeft: 5, fontFamily: "SVN-Gotham" }}
                >
                  {profileUser?.community?.name ?? ""}
                </Typography>
              </Link>
            </Grid>
          ) : null}

          {profileUser?.system?.name ? (
            <Grid item display="flex" style={{ marginLeft: 15 }}>
              <img
                src={profileUser?.system?.rank?.icon ?? WarningIcon}
                width={20}
                alt="..."
              />
              <Link to="/system">
                <Typography
                  color={"#59A6FD"}
                  style={{ marginLeft: 5, fontFamily: "SVN-Gotham" }}
                >
                  {profileUser?.system?.name ?? ""}
                </Typography>
              </Link>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        sx={{ alignItems: "center" }}
        flexGrow={1}
      >
        {profileUser?.household?.name ? (
          <Grid container>
            <Grid item display="flex">
              <img
                src={profileUser?.household?.rank?.icon ?? WarningIcon}
                width={20}
                alt="..."
              />
              <Link to="/household">
                <Typography
                  color={"#59A6FD"}
                  style={{ marginLeft: 5, fontFamily: "SVN-Gotham" }}
                >
                  {profileUser?.household?.name ?? " "}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </Box>
  );
}

export default Profile;
