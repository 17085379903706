import { createAsyncThunk } from "@reduxjs/toolkit";
import { setOpen } from "./popup.slice";

export const showPopup = createAsyncThunk(
  "popup",
  async (isOpen, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setOpen(isOpen));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
