import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth.slice";
import detailUserSlice from "./users/user.slice";
import popupSlice from "./popup/popup.slice";
import eventsSlice from "./events/events.slice";
import musicSlice from "./musics/music.slice";
import attendanceSlice from "./attendances/attendance.slice";
import invenSlice from "./inventory/inven.slice";
import challengesSlice from "./challenges/challenges.slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: detailUserSlice.reducer,
    popup: popupSlice.reducer,
    events: eventsSlice.reducer,
    music: musicSlice.reducer,
    attendance: attendanceSlice.reducer,
    inventory: invenSlice.reducer,
    challenges: challengesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export default store;

export const AppDispatch = typeof store.dispatch;
