import { getAxiosClientWithToken } from "api";

export const listEvents = (params) => {
  return getAxiosClientWithToken().get(`events`, { params });
};

export const detailEvent = (id) => {
  return getAxiosClientWithToken().get(`events/${id}`);
};

export const participantsEvent = (params, id) => {
  return getAxiosClientWithToken().get(`events/${id}/participants`, { params });
};

export const leaderboardsEvent = (params, id) => {
  return getAxiosClientWithToken().get(`events/${id}/leaderboards`, { params });
};

export const isReadNotification = (id) => {
  return getAxiosClientWithToken().post(`notifications/${id}/read`);
};

export const isNewestNotification = () => {
  return getAxiosClientWithToken().get(`notifications/newest`);
};

export const listNotification = (params) => {
  return getAxiosClientWithToken().get(`notifications`, { params });
};

export const readAllNotification = (params) => {
  return getAxiosClientWithToken().post(`notifications/read-all`, { params });
};

export const joinArena = (id) => {
  return getAxiosClientWithToken().post(`events/${id}/join`);
};
