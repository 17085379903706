import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import BorderImageBlue from "assets/images/border-blue.png";
import BorderImage from "assets/images/icon-border-1.png";
import IconEvent from "assets/images/icon-event.svg";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";

import CupIcon from "assets/images/cup.svg";
import ArenaRankingComponent from "components/ArenaRankingComponent";
import { hoverBgBlue } from "config/styles/common";
import { useParams } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import { scrollIndexMyRank } from "utils/common";
import SearchArenaRankingModal from "components/Modal/SearchArenaRankingModal";
import InfoUserModal from "components/Modal/InfoUserModal";
import {
  setCompareToFirstInfoUser,
  setIndexInfoUserAction,
} from "redux/users/user.slice";
import { useDispatch, useSelector } from "react-redux";
import TextComponent from "components/Texts/TextComponent";
import {
  indexInfoRankUserSelector,
  rankCurrentSelector,
  userSelector,
} from "redux/users/user.selectors";
import { asyncJoinArena, getDetailEvents } from "redux/events/events.actions";
import { EVENT_LEADERBOARD, EVENT_PARTICIPANT } from "config/constants";
import { setSrcMusic } from "redux/musics/music.slice";
import { setMessageError } from "redux/popup/popup.slice";
import { showPopup } from "redux/popup/popup.action";

export default function DetailEvent() {
  const backgroundColor = "#1E1E1E";
  const [listRank, setListRank] = useState([]);
  const [listStaff, setListStaff] = useState([]);
  const [totalStaff, setTotalStaff] = useState(0);
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [detailEvent, setDetailEvent] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();
  const rankCurrent = useSelector(rankCurrentSelector);
  const listRef = useRef(null);
  const profileUser = useSelector(userSelector);
  const infoUserSelectDetail = useSelector(indexInfoRankUserSelector);
  const childRef = useRef(null);
  const childRefRanking = useRef(null);

  useEffect(() => {
    if (params?.id) {
      dispatch(getDetailEvents(params?.id ?? ""))
        .unwrap()
        .then((result) => {
          dispatch(setSrcMusic(result?.music));
          setDetailEvent(result);
        });
      childRef?.current?.callRefeshDataEvent();
      childRefRanking?.current?.callRefeshDataEvent();
    }
  }, [params?.id]);

  const getJoinArena = () => {
    if (detailEvent?.joined) {
      return false;
    } else {
      if (detailEvent?.canJoin) {
        return true;
      }
      return false;
    }
  };

  const getHeaderTitleBox = (index) => {
    switch (index) {
      case 0:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"SỰ KIỆN"} />
          </Box>
        );
      case 1:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent
              title={"THÀNH VIÊN THAM GIA"}
              fontSize="13px"
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextComponent content={`Số người tham gia:`} maxLine="1" />
              <TextComponent
                content={`${totalStaff ?? ""}`}
                styleText={{
                  fontSize: "20px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  marginBottom: "7px",
                  width: "100px",
                }}
                maxLine="1"
              />
            </Box>
          </Box>
        );
      default:
        return rankCurrent?.position ? (
          <Box paddingX={1}>
            <TitleCustomComponent title={"BẢNG XẾP HẠNG"} fontSize="13px" />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                scrollIndexMyRank(listRef, listRank, rankCurrent?.id)
              }
            >
              <TextComponent
                content={`Vị trí của bạn trên bảng xếp hạng:`}
                maxLine="1"
              />
              <TextComponent
                content={`${rankCurrent?.position ?? ""}`}
                styleText={{
                  fontSize: "20px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  marginBottom: "7px",
                  width: "100px",
                }}
                maxLine="1"
              />
            </Box>
          </Box>
        ) : (
          <Box paddingX={1}>
            <TitleCustomComponent title={"BẢNG XẾP HẠNG"} />
          </Box>
        );
    }
  };

  const getBorderBox = (index) => {
    switch (index) {
      case 0:
        return BorderImage;
      case 1:
        return BorderImageBlue;
      default:
        return BorderImage;
    }
  };

  const getIconHeader = (index) => {
    switch (index) {
      case 0:
        return <img src={IconEvent} alt="..." />;
      case 2:
        return <img src={CupIcon} alt="..." />;
      default:
        return false;
    }
  };

  const getRightHeader = (index) => {
    switch (index) {
      case 2:
        return (
          <Box
            key={0}
            sx={{
              justifyContent: "center",
              display: "grid",
              alignContent: "space-between",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
              }}
              onClick={() => setOpenModalSearch(true)}
            >
              <SearchOutlined style={{ color: "white", marginRight: "4px" }} />
            </Box>
          </Box>
        );

      default:
        return false;
    }
  };

  const checkJoin = () => {
    return detailEvent?.canJoin ? detailEvent?.joined : false;
  };

  const InfoEvent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "95%",
          flexDirection: "column",
          margin: "auto",
          height: "520px",
          overflow: "scroll",
        }}
      >
        <p className="event-title">{detailEvent?.name ?? ""}</p>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ fontSize: "12px", color: "white" }}>
            {detailEvent?.remaining ?? ""}
          </Typography>
          <Typography style={{ fontSize: "12px", color: "white" }}>
            {/* Dành cho tất cả mọi người */}
          </Typography>
        </Box>
        {detailEvent?.image ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={`${detailEvent?.image ?? ""}`}
              width="100%"
              style={{
                marginTop: "10px",
                maxHeight: "350px",
                width: "fit-content",
              }}
              alt="..."
            />
          </Box>
        ) : (
          <Box sx={{ marginTop: "10px" }}></Box>
        )}

        <Box>
          <Typography
            style={{
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              fontSize: "14px",
              textTransform: "uppercase",
              color: "#FFF1B5",
              marginTop: "10px",
              marginBottom: "8px",
            }}
          >
            THỜI GIAN DIỄN RA
          </Typography>
          <p style={{ fontSize: "12px", color: "white" }}>{detailEvent?.beginAt} - {detailEvent?.endAt}</p>
        </Box>
        <Box>
          <Typography
            style={{
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              fontSize: "14px",
              textTransform: "uppercase",
              color: "#FFF1B5",
              marginTop: "10px",
              marginBottom: "8px",
            }}
          >
            ĐIỂM TỐI THIỂU
          </Typography>
          <p style={{ fontSize: "12px", color: "white" }}>{detailEvent?.revenueMin}</p>
        </Box>
        <Box>
          <Typography
            style={{
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              fontSize: "14px",
              textTransform: "uppercase",
              color: "#FFF1B5",
              marginTop: "10px",
              marginBottom: "8px",
            }}
          >
            GIẢI THƯỞNG
          </Typography>
          {detailEvent?.prizes && detailEvent?.prizes?.length > 0
            ? detailEvent?.prizes.map((value, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <p className="event-prize-title">{value.name}</p>
                    <p className="event-prize">
                      {value?.quantity + " " + value?.prizeName}
                    </p>
                  </div>
                );
              })
            : null}
        </Box>
        <Box>
          <Typography
            style={{
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              fontSize: "14px",
              textTransform: "uppercase",
              color: "#FFF1B5",
            }}
          >
            LUẬT CHƠI
          </Typography>
          <p className="event-rule">{detailEvent?.rule ?? ""}</p>
        </Box>
      </Box>
    );
  };

  const LeaderBoard = (index) => {
    switch (index) {
      case 1:
        return (
          <ArenaRankingComponent
            refList={undefined}
            isEmployeeList={true}
            isJoinable={checkJoin() ? true : false}
            listRank={listStaff}
            setListRank={setListStaff}
            isSetRankCurrent={false}
            switchCallApi={EVENT_PARTICIPANT}
            paramId={params.id}
            setTotalStaff={setTotalStaff}
            refRefreshData={childRef}
          />
        );
      case 2:
        return (
          <ArenaRankingComponent
            refList={listRef}
            isEmployeeList={false}
            isJoinable={false}
            listRank={listRank}
            setListRank={setListRank}
            setOpenModalInfo={setOpenModalInfo}
            switchCallApi={EVENT_LEADERBOARD}
            paramId={params.id}
            isShowMyRank={rankCurrent?.position ? true : false}
            refRefreshData={childRefRanking}
          />
        );
      default:
        return <InfoEvent />;
    }
  };

  const clickJoinArena = () => {
    dispatch(asyncJoinArena(detailEvent?.id))
      .unwrap()
      .then(async (result) => {
        if (result) {
          dispatch(setMessageError("Tham gia sự kiện thành công"));
          dispatch(showPopup(true));
          childRef?.current?.callRefeshDataEvent();
          childRefRanking?.current?.callRefeshDataEvent();
          dispatch(getDetailEvents(detailEvent?.id ?? ""))
            .unwrap()
            .then((result) => {
              setDetailEvent(result);
            });
        }
      });
  };

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor, flex: 1, paddingBottom: 1 }}
    >
      <Grid container columnSpacing={3}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
          >
            <BoxWrapperComponent
              onClickArena={undefined}
              headerTitle={getHeaderTitleBox(index)}
              headerIcon={getIconHeader(index)}
              borderImageBox={getBorderBox(index)}
              headerBtnRight={getRightHeader(index)}
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              styleHoverBg={index === 1 ? hoverBgBlue : {}}
              background={undefined}
              isArena={false}
              arenaInfo={undefined}
              jointBtn={index === 1 ? getJoinArena() : false}
              onClickJoin={index === 1 ? clickJoinArena : false}
              boxStyle={{ height: "605px" }}
            >
              {LeaderBoard(index)}
            </BoxWrapperComponent>
          </Grid>
        ))}
      </Grid>
      <SearchArenaRankingModal
        open={openModalSearch}
        onClose={() => setOpenModalSearch(false)}
        setOpenModalInfo={setOpenModalInfo}
        switchCallApi={EVENT_LEADERBOARD}
        paramId={params.id}
      />
      {infoUserSelectDetail ? (
        <InfoUserModal
          onClose={() => {
            dispatch(setIndexInfoUserAction(null));
            dispatch(setCompareToFirstInfoUser(null));
            setOpenModalInfo(false);
          }}
          onOpen={openModalInfo}
          infoUser={infoUserSelectDetail}
        />
      ) : null}
    </Container>
  );
}
