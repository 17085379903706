export const colors = {
  white: "#ffffff",
  black: "#000000",
  transparent: "transparent",
  error: "#FF5A5F",
  gray01: "#223e4b",
  gray_text: "#909090",
  primary: "#71BF44",
  gray: "#888888",
  gray02: "#E1E5EA",
  gray03: "#313131",
  gray04: "#E3E3E3",
  gray05: "#F9F9F9",
  danger: "#EB5757",
  blue: "#2F80ED",
  star: "#FFB800",
  bg_box: "#302A20",
  textTitle: "#FFEEA3",
  bg_item: "rgba(255, 212, 149, 0.3)",
  back200: "rgba(0, 0, 0, 0.2)",
  back500: "rgba(0, 0, 0, 0.3)",
  bg_black: "#1E1E1E",
};
