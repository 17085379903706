import axios from "axios";
import store from "../redux/store";
import { setTokenAction } from "../redux/auth/auth.slice";
import {
  LOCALE_KEY,
  setStorageValue,
} from "../redux/localStorage/useLocalStorage";

const BASE_URL = process.env.REACT_APP_API_URL;

/**
 * Call axios.create without token on the header
 **/
export const getAxiosClient = () => {
  return axios.create({
    baseURL: BASE_URL,
  });
};

/**
 * Call axios.create and append header with token
 **/
export const getAxiosClientWithToken = () => {
  const client = getAxiosClient();
  client.defaults.headers.common["Authorization"] =
    "Bearer " + store.getState().auth.token;
  client.defaults.headers.common["Content-Type"] = "application/json";
  client.defaults.headers.common["Accept"] = "application/json";
  client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { config, response } = error;
      if (response?.status === 403 && !config?._retry) {
        config._retry = true;
        store.dispatch(setTokenAction(""));
        setStorageValue(LOCALE_KEY.token, "");
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export const getErrorMessage = (error) => {
  const { status } = error?.response || {};
  const {
    data: errors,
    errors: validateErrors,
    message,
  } = error?.response?.data;

  if (status && status === 422) {
    if (validateErrors) {
      return validateErrors;
    }
    if (errors) {
      return errors;
    }

    if (message?.length > 0) {
      if (typeof message === "string") {
        return message;
      }
      return message[0]?.msg ?? "";
    }
    return "Đã có lỗi xảy ra, vui lòng thử lại sau.";
  }
  if (status && status < 500) {
    return message;
  }

  console.error(error);
  return "Server Error";
};
