import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "assets/images/btn-close.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncChallengeRoomsLeave,
  asyncDetailRoom,
  asyncRoomConfirm,
  asyncUpdateBetRatio,
} from "redux/challenges/challenges.actions";
import {
  challengeSelector,
  idChallengeJoinSelector,
} from "redux/challenges/challenges.selectors";
import {
  setIdChallengeJoin,
  setTypeSearch,
  setParticipantIdChallenge,
} from "redux/challenges/challenges.slice";
import { showPopup } from "redux/popup/popup.action";
import {
  setChallengeRoomId,
  setMessageError,
  setOpen,
  setOpenLeaderboardModal,
  setOpenABet,
  setOpenRemoveParticipant,
} from "redux/popup/popup.slice";
import ConfirmIcon from "assets/images/icon-done.svg";
import {
  challengeRoomIdSelector,
  popupSelector,
} from "redux/popup/popup.selector";
import { CANCELLED, FINISHED, NOT_STARTED, STARTED } from "config/constants";
import LeaderboadIcon from "assets/images/arena/arena-leaderboard.svg";
import RemoveIcon from "assets/images/close-icon.svg";

export default function DetailChallengeModal({ open, onClose }) {
  const { detailRoom } = useSelector(challengeSelector);
  const [number, setNumber] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const idJoin = useSelector(idChallengeJoinSelector);
  const [confirm, setConfirm] = useState(true);
  const { isOpen } = useSelector(popupSelector);
  const challengeId = useSelector(challengeRoomIdSelector);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  useEffect(() => {
    if (open) {
      dispatch(asyncDetailRoom(challengeId))
        .then((res) => {
          let itemParticipant = [];
          if (res?.payload?.participants?.length > 0) {
            res?.payload?.participants?.map((data) => {
              itemParticipant.push({ id: data?.id, odds: data?.odds });
            });
          }
          setNumber(itemParticipant.length > 0 ? itemParticipant : []);
          setTimeOutLoading();
          // setNumber(
          //   res?.payload?.participants?.map((data) => {
          //     return data?.odds;
          //   })
          // );
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [open, isOpen]);

  const setTimeOutLoading = () => {
    const idTimeOut = setTimeout(() => {
      setIsLoading(false);
    }, 300);
    return () => clearTimeout(idTimeOut);
  };

  const getPrize = (index) => {
    switch (index) {
      case 0:
        return "Giải nhất";
      case 1:
        return "Giải nhì";
      default:
        return "Giải ba";
    }
  };

  const handleUpdateBetRatio = () => {
    dispatch(asyncUpdateBetRatio({ id: challengeId, participants: number }));
  };

  const asyncChallengeLeave = () => {
    if (idJoin) {
      dispatch(asyncChallengeRoomsLeave(idJoin))
        .unwrap()
        .then((res) => {
          if (res) {
            dispatch(setMessageError("Rời khỏi phòng thách đấu thành công"));
            dispatch(showPopup(true));
            dispatch(setTypeSearch(6));
            onClose();
          }
        })
        .catch((error) => {
          dispatch(
            setMessageError(error ?? "Đã có lỗi xảy ra, vui lòng thử lại sau.")
          );
          dispatch(showPopup(true));
        });
    } else {
      dispatch(setMessageError("Đã có lỗi xảy ra, vui lòng thử lại sau."));
      dispatch(showPopup(true));
    }
  };

  function updateValue(key, value) {
    const newValueStep1 = [...number];
    const fieldChanged = newValueStep1[key];
    newValueStep1[key] = { id: fieldChanged.id, odds: value };
    setNumber(newValueStep1);
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        dispatch(setIdChallengeJoin(null));
        dispatch(setChallengeRoomId(null));
        onClose();
        setIsLoading(true);
        setNumber([]);
      }}
      disableAutoFocus={true}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        border: "2px solid #000",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "white",
          p: 4,
          background: "#302A20",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
          border: "1px solid #FFEEA3",
          borderRadius: "5px",
        }}
      >
        <Box
          onClick={() => {
            dispatch(setIdChallengeJoin(null));
            dispatch(setIdChallengeJoin(null));
            onClose();
            setIsLoading(true);
          }}
          sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
        >
          <img src={CloseIcon} alt="..." />
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{
            fontFamily: "SVN-Gotham",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "22.9001px",
            lineHeight: "32px",
            textAlign: "center",
            textTransform: "uppercase",
            color: "#FFF8E0",
          }}
        >
          {detailRoom?.name}
        </Typography>
        <Box sx={{ marginTop: "20px" }}></Box>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "8px",
              width: "100%",
              height: "300px",
              top: 0,
              left: 0,
              right: 0,
            }}
          >
            <CircularProgress
              size={"30px"}
              style={{ color: "rgb(255, 238, 163)" }}
              disableShrink={true}
            />
          </Box>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Be-Vietnam-Pro",
                    color: "#9C7F5C",
                    fontSize: 16,
                  }}
                >
                  Chủ phòng:
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: 700,
                    color: "#DBB487",
                    fontSize: 16,
                    textAlign: "right",
                  }}
                >
                  {detailRoom?.owner?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Be-Vietnam-Pro",
                    color: "#9C7F5C",
                    fontSize: 16,
                  }}
                >
                  Loại phòng:
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: 700,
                    color: "#DBB487",
                    fontSize: 16,
                  }}
                >
                  {detailRoom?.objectParticipant}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Be-Vietnam-Pro",
                    color: "#9C7F5C",
                    fontSize: 16,
                  }}
                >
                  Số lượng tham gia:
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: 700,
                    color: "#DBB487",
                    fontSize: 16,
                  }}
                >
                  {detailRoom?.participants?.length}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Be-Vietnam-Pro",
                    color: "#9C7F5C",
                    fontSize: 16,
                  }}
                >
                  Ngày thách đấu:
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: 700,
                    color: "#DBB487",
                    fontSize: 16,
                  }}
                >
                  {detailRoom?.beginAt} - {detailRoom?.endAt}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontFamily: "Be-Vietnam-Pro",
                  color: "#9C7F5C",
                  fontSize: 16,
                }}
              >
                Giải thưởng
              </Typography>
            </Grid>
            {detailRoom?.prizes?.map((item, index) => {
              return (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      marginLeft: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontFamily: "Be-Vietnam-Pro",
                        color: "#9C7F5C",
                        fontSize: 16,
                      }}
                    >
                      {getPrize(index)}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        fontWeight: 700,
                        color: "#DBB487",
                        fontSize: 16,
                      }}
                    >
                      {item?.quantity} {"\t"}
                      {item?.name}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

            {detailRoom?.participants?.length ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Be-Vietnam-Pro",
                      color: "#9C7F5C",
                      fontSize: 16,
                      marginBottom: "4px",
                    }}
                  >
                    Tỷ lệ kèo
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box sx={{ marginTop: "" }}>
                    <TableContainer
                      component={Paper}
                      sx={{ maxHeight: "209px" }}
                    >
                      <Table
                        sx={{ minWidth: 430, bgcolor: "#6E5D43" }}
                        aria-label="simple table"
                        size="small"
                        stickyHeader={true}
                      >
                        <TableHead
                          sx={{
                            "& .MuiTableCell-stickyHeader": {
                              backgroundColor: "#6E5D43",
                              color: "white",
                              fontFamily: "Be-Vietnam-Pro",
                            },
                          }}
                        >
                          <TableRow>
                            <TableCell>Tên nhân viên</TableCell>
                            <TableCell align="center">Tỷ lệ kèo</TableCell>
                            <TableCell align="center">Xác nhận</TableCell>
                            <TableCell align="center"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody
                          sx={{
                            "& .MuiTableCell-body": {
                              color: "white",
                            },
                          }}
                        >
                          {detailRoom?.participants?.map((data, index) => {
                            return (
                              <TableRow
                                key={data?.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {data?.name}
                                </TableCell>
                                <TableCell align="center">
                                  {detailRoom?.isOwner ? (
                                    <TextField
                                      size="small"
                                      type="number"
                                      InputLabelProps={{
                                        style: { color: "white" },
                                      }}
                                      InputProps={{
                                        inputProps: { min: 0.01 },
                                      }}
                                      sx={{
                                        width: "100px",
                                        height: "30px",
                                        justifyContent: "center",
                                        ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root":
                                          {
                                            color: "white",
                                            fontSize: "14px",
                                            fontFamily: "sans-serif",
                                            fontWeight: "400",
                                          },
                                        "& .MuiOutlinedInput-root": {
                                          color: "white",
                                          "& input": {
                                            padding: "7px 13px",
                                          },
                                          "& input::placeholder": {
                                            color: "#9ca3af",
                                          },
                                          "& fieldset": {
                                            // border: "1px solid #D6B88A",
                                            border: 0,
                                          },
                                          "&:hover fieldset": {
                                            border: 0,
                                          },
                                          "&.Mui-focused fieldset": {
                                            border: 0,
                                          },
                                        },
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                          {
                                            display: "none",
                                          },
                                      }}
                                      value={number[index]?.odds}
                                      onChange={(e) => {
                                        updateValue(
                                          index,
                                          e.target.value < 0
                                            ? (e.target.value = Math.abs(
                                                e.target.value
                                              ))
                                            : e.target.value
                                        );
                                      }}
                                    />
                                  ) : (
                                    data?.odds
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ textAlign: "-webkit-center" }}
                                >
                                  {data?.isConfirmed ? (
                                    <img
                                      src={ConfirmIcon}
                                      width={"30px"}
                                      alt="..."
                                      style={{ alignContent: "center" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {
                                    detailRoom?.isOwner && detailRoom?.owner?.id !== data?.userId &&
                                    <img src={RemoveIcon} style={{cursor: 'pointer'}} alt="close"
                                         onClick={() => {
                                           dispatch(setOpenRemoveParticipant(true));
                                           dispatch(setParticipantIdChallenge(data.id));
                                           dispatch(setIdChallengeJoin(detailRoom?.id));
                                         }}
                                    />
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </>
            ) : null}
            {detailRoom?.status === FINISHED ||
            detailRoom?.status === STARTED ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setChallengeRoomId(detailRoom?.id));
                    dispatch(setOpenLeaderboardModal(true));
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      padding: "7px 17px",
                      height: "36px",
                      background:
                        "radial-gradient(50% 50% at 50% 50%, #EB4C4C 0%, #AD0606 100%)",
                      boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.35)",
                      borderRadius: "9px",
                      fontFamily: "SVN-Gotham",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "14px",
                      textAlign: "right",
                      color: "#FFEEA3",
                      border: "1px solid #AC4120",
                      textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    Bảng xếp hạng
                  </Typography>
                </Box>
              </Box>
            ) : null}

            {detailRoom?.status === NOT_STARTED ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  marginTop: "20px",
                }}
              >
                {!detailRoom?.isOwner && detailRoom?.isAllowJoin && !detailRoom?.isJoined ? (
                  <div
                    onClick={() => {
                      if (detailRoom?.isAllowJoin) {
                        dispatch(setChallengeRoomId(detailRoom?.id));
                        dispatch(setIdChallengeJoin(detailRoom?.id));
                        dispatch(setOpenABet(true));
                      }
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "7px 17px",
                        height: "36px",
                        background:
                          "radial-gradient(50% 50% at 50% 50%, #EB4C4C 0%, #AD0606 100%)",
                        boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.35)",
                        borderRadius: "9px",
                        fontFamily: "SVN-Gotham",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "14px",
                        textAlign: "right",
                        color: "#FFEEA3",
                        border: "1px solid #AC4120",
                        textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Nhận kèo
                    </Typography>
                  </div>
                ) : null}

                {detailRoom?.isOwner && detailRoom?.permission ? (
                  <div
                    onClick={() => {
                      handleUpdateBetRatio();
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "7px 17px",
                        height: "36px",
                        background:
                          "radial-gradient(50% 50% at 50% 50%, #EB4C4C 0%, #AD0606 100%)",
                        boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.35)",
                        borderRadius: "9px",
                        fontFamily: "SVN-Gotham",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "14px",
                        textAlign: "right",
                        color: "#FFEEA3",
                        border: "1px solid #AC4120",
                        textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Cập nhật tỷ lệ
                    </Typography>
                  </div>
                ) : null}
                {
                  detailRoom?.isJoined && detailRoom?.permission ? (
                    <div
                      onClick={() => {
                        dispatch(
                          asyncRoomConfirm({
                            id: challengeId,
                            isConfirmed: !detailRoom?.isConfirmed,
                          })
                        ).then((res) => {
                          if (res?.payload?.success) {
                            dispatch(setMessageError(res?.payload?.message));
                            dispatch(setOpen(true));
                          }
                        });
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          padding: "7px 17px",
                          height: "36px",
                          background:
                            "radial-gradient(50% 50% at 50% 50%, #EB4C4C 0%, #AD0606 100%)",
                          boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.35)",
                          borderRadius: "9px",
                          fontFamily: "SVN-Gotham",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "14px",
                          textAlign: "right",
                          color: "#FFEEA3",
                          border: "1px solid #AC4120",
                          textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {detailRoom?.isConfirmed ? "Bỏ xác nhận" : "Xác nhận"}
                      </Typography>
                    </div>
                    ) : null }

                  {
                    detailRoom?.isJoined && detailRoom?.permission ? (
                    <div
                      onClick={() => asyncChallengeLeave()}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          padding: "7px 17px",
                          height: "36px",
                          background:
                            "radial-gradient(50% 50% at 50% 50%, #EB4C4C 0%, #AD0606 100%)",
                          boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.35)",
                          borderRadius: "9px",
                          fontFamily: "SVN-Gotham",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "14px",
                          textAlign: "right",
                          color: "#FFEEA3",
                          border: "1px solid #AC4120",
                          textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        Rời bỏ
                      </Typography>
                    </div>
                    ) : null }
              </Box>
            ) : null}
            {detailRoom?.status === CANCELLED ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#ff1313",
                      fontSize: 16,
                    }}
                  >
                    {detailRoom?.cancelReason ?? detailRoom?.notification?.data?.title ?? ""}
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </>
        )}
      </Box>
    </Modal>
  );
}
