import { Box, Grid } from "@mui/material";
import React from "react";
import TextComponent from "../Texts/TextComponent";

export const KeyValueComponent = ({ title = "", value = "" }) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Box
        sx={{
          display: "flex",
          gridTemplateColumns: "repeat(2, 1fr)",
          justifyContent: "space-between",
        }}
      >
        <TextComponent
          content={title ?? ""}
          styleText={{
            fontWeight: 500,
            fontFamily: "Be-Vietnam-Pro",
            color: "#9C7F5C",
            fontSize: 16,
            marginRight: 2,
          }}
          maxLine="1"
        />
        <Box sx={{ display: "flex" }}>
          <TextComponent
            content={value ?? ""}
            styleText={{
              fontWeight: 700,
              fontFamily: "Be Vietnam Pro",
              color: "#DBB487",
              fontSize: 16,
              marginRight: "2px",
              textTransform: "uppercase",
            }}
            maxLine="1"
          />
        </Box>
      </Box>
    </Grid>
  );
};
