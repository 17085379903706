import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { login, refreshToken } from "./auth.service";

export const logoutAction = createAction("auth/logout");

export const loginAction = createAsyncThunk(
  "auth/login",
  async (credential) => {
    const response = await login(credential);
    return response.data;
  }
);

export const refreshTokenAction = createAsyncThunk(
  "auth/refreshToken",
  async (credential) => {
    // @ts-ignore
    const response = await refreshToken(credential);
    return response.data;
  }
);

