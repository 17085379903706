import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "assets/images/btn-close.png";
import { useSelector } from "react-redux";
import { infoNotificationSelector } from "redux/popup/popup.selector";

export default function InfoUpRank({ open, onClose }) {
  const infoUpRankSlt = useSelector(infoNotificationSelector);
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          border: "2px solid #000",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            p: 4,
            background: "#302A20",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.45)",
            border: "1px solid #FFEEA3",
            borderRadius: "5px",
          }}
        >
          <Box
            onClick={onClose}
            sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
          >
            <img src={CloseIcon} alt="..." />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              fontFamily: "SVN-Gotham",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "22.9001px",
              lineHeight: "32px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "#FFF8E0",
            }}
          >
            Chi tiết lên hạng
          </Typography>
          {infoUpRankSlt?.data?.description ? (
            <div
              style={{ maxHeight: "350px", color: "white", overflow: "scroll" }}
              dangerouslySetInnerHTML={{
                __html: infoUpRankSlt?.data?.description,
              }}
            />
          ) : (
            <Typography
              id="modal-modal-description"
              sx={{
                mt: 2,
                fontFamily: "Be Vietnam Pro",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "22px",
                textAlign: "center",
                color: "#C9C9C9",
              }}
            >
              Thông tin chưa được cập nhật
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
}
