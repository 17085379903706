// @ts-nocheck
/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import { createSlice } from "@reduxjs/toolkit";

const challengesSlice = createSlice({
  name: "challenges",
  initialState: {
    error: false,
    loading: false,
    success: false,
    typeSearch: null,
    idChallengeJoin: null,
    detailRoom: {},
    leaderboard: {},
    participantIdChallengeRemove: null,
  },
  reducers: {
    setTypeSearch: (state, { payload }) => {
      state.typeSearch = payload;
    },
    setIdChallengeJoin: (state, { payload }) => {
      state.idChallengeJoin = payload;
    },
    setDetailRoom: (state, { payload }) => {
      state.detailRoom = payload;
    },
    setLeaderboard: (state, { payload }) => {
      state.leaderboard = payload;
    },
    setParticipantIdChallenge: (state, { payload }) => {
      state.participantIdChallengeRemove = payload;
    }
  },
  extraReducers: (builder) => {},
});

export const {
  setTypeSearch,
  setIdChallengeJoin,
  setDetailRoom,
  setLeaderboard,
  setParticipantIdChallenge,
} = challengesSlice.actions;
export default challengesSlice;
