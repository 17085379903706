import { Box, Button, Container, Grid, Typography } from "@mui/material";
import BorderImageBlue from "assets/images/border-blue.png";
import BorderImage from "assets/images/icon-border-1.png";
import BoxWrapperComponent from "components/BoxWrapperComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";

import DuoStaff from "assets/images/challenge/duo-staff.svg";
import DuoAxe from "assets/images/challengeBtn.svg";
import ChallengeItem from "components/Challenge/ChallengeItem";
import { hoverBgBlue } from "config/styles/common";
import AddRoomModal from "components/Modal/AddRoomModal";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSrcMusic } from "redux/musics/music.slice";
import { asyncGetMusic } from "redux/users/user.actions";
import { typeSearchSelector } from "redux/challenges/challenges.selectors";
import SeemoreBtn from "assets/images/button-see-more.png";
import { Link } from "react-router-dom";

export default function Challenge() {
  const backgroundColor = "#1E1E1E";
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetMusic({ page: "challenge" }))
      .unwrap()
      .then((result) => {
        dispatch(setSrcMusic(result?.music));
      });
  }, []);

  const getHeaderTitleBox = (index) => {
    switch (index) {
      case 0:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"Phòng thách đấu cá nhân"} />
          </Box>
        );
      case 1:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"Phòng thách đấu tập thể"} />
          </Box>
        );
      default:
        return (
          <Box paddingX={1}>
            <TitleCustomComponent title={"Phòng thách đấu của bạn"} />
          </Box>
        );
    }
  };

  const getBorderBox = (index) => {
    switch (index) {
      case 1:
        return BorderImage;
      case 0:
        return BorderImageBlue;
      default:
        return BorderImageBlue;
    }
  };

  const getHeaderIcon = (index) => {
    switch (index) {
      case 0:
        return <img src={DuoAxe} alt="..." width="90px" />;
      case 1:
        return <img src={DuoStaff} alt="..." width="90px" />;

      default:
        return null;
    }
  };

  const getBoxContent = (index) => {
    switch (index) {
      case 0:
        return (
          <ChallengeItem isList={true} isPersonal={1} time={3} isJoined={0} />
        );
      case 1:
        return (
          <ChallengeItem isList={true} isPersonal={0} isJoined={0} time={3} />
        );

      default:
        return (
          <ChallengeItem
            isList={true}
            isPersonal={3}
            isJoined={1}
            isOwner={true}
            setOpenCreateChallenge={setOpen}
          />
        );
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor, flex: 1, paddingBottom: 1 }}
    >
      <AddRoomModal onClose={() => setOpen(false)} open={open} />;
      <Grid container columnSpacing={3}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <BoxWrapperComponent
              onClickArena={undefined}
              headerTitle={getHeaderTitleBox(index)}
              headerIcon={getHeaderIcon(index)}
              borderImageBox={getBorderBox(index)}
              headerBtnRight={
                index === 2 ? (
                  <Link to="/challenge-management">
                    <img src={SeemoreBtn} width="100px" alt="" />{" "}
                  </Link>
                ) : null
              }
              bottomLeftBtn={undefined}
              bottomRightBtn={undefined}
              styleHoverBg={index === 1 ? hoverBgBlue : {}}
              background={undefined}
              isArena={false}
              arenaInfo={undefined}
              jointBtn={false}
            >
              {getBoxContent(index)}
            </BoxWrapperComponent>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
