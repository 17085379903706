import React from "react";
import { Box } from "@mui/material";
import { colors } from "../../config/colors/colors";
import TextComponent from "../Texts/TextComponent";

function ButtonIconComponent({
  icon,
  expPoints,
  styleBox = {},
  styleText = {},
  isBorderText = true,
  onPress = () => {},
}) {
  return (
    <Box
      sx={{
        bgcolor: colors.back200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: 1,
        maxWidth: "68px",
        minWidth: "48px",
        marginTop: 1,
        marginBottom: 1,
        ...styleBox,
      }}
      onClick={() => onPress?.()}
    >
      <img src={icon} alt="..." style={{ paddingTop: 2 }} />
      {isBorderText ? (
        <TextComponent
          content={`${expPoints}`}
          styleText={{
            paddingLeft: 1,
            paddingRight: 1,
            display: "-webkit-box",
            lineClamp: 1,
            wordBreak: "break-all",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
            overflow: "hidden",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            textShadow: `0px 1.23587px 1.23587px rgba(0, 0, 0, 0.25)`,
            // WebkitTextStroke: "0.617933px transparent",
            // WebkitTextStrokeColor: "#381903",
            color: "#FFDB80",
            ...styleText,
          }}
          maxLine="1"
        />
      ) : (
        <TextComponent
          content={`${expPoints}`}
          styleText={{ ...styleText }}
          maxLine="1"
        />
      )}
    </Box>
  );
}

export default ButtonIconComponent;
