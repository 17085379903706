import moment from "moment";
import IconErrImage from "../assets/images/warning-icon.png";
import { useLayoutEffect, useState } from "react";
export const onErrorImage = (ev) => {
  ev.target.src = IconErrImage;
};

export function formatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}

export const percentRevenue = (numerator, denominator) => {
  if (!numerator || numerator < 0 || !denominator || denominator <= 0) {
    return 0;
  }
  return ((numerator / denominator) * 100).toFixed(2);
};

export const getIndexRankUser = (arrayData, id) => {
  const rankCurrent = arrayData.findIndex((element) => element?.id === id);
  return rankCurrent >= 0 ? rankCurrent + 1 : "";
};

export const scrollParentToChild = (parent, child) => {
  var parentRect = parent?.getBoundingClientRect();
  var childRect = child?.getBoundingClientRect();

  parent.scrollTo({
    top: childRect.top + parent.scrollTop - parentRect.top,
    behavior: "smooth",
  });
};

export const scrollIndexMyRank = (listRef, listRank, id) => {
  if (!listRank?.length && !id) {
    return;
  }
  const findIdUser = listRank.findIndex((value) => value.id === id);
  if (findIdUser === -1) {
    listRef?.current?.props?.next(true, true);
    return;
  }

  if (
    listRef?.current &&
    listRef?.current?.el &&
    listRef?.current?.el?.children?.length > 0
  ) {
    const element =
      listRef?.current?.el?.children[findIdUser > 3 ? findIdUser - 3 : 0];
    if (element) {
      scrollParentToChild(listRef?.current?.el, element);
    }
  }
};

export function WindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export const SelectByIndex = [
  {
    title: "Doanh thu",
    value: 2,
  },
];

export const SelectByScope = [
  {
    title: "HKD",
    value: 1,
  },
  {
    title: "HT",
    value: 2,
  },
  {
    title: "CĐ",
    value: 3,
  },
  {
    title: "OBD",
    value: 4,
  },
];

export function formatNumber(number) {
  if (!number || number === 0) {
    return 0;
  }
  return Math.ceil(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const SelectByScopeHKD = [
  {
    title: "HT",
    value: 2,
  },
  {
    title: "CĐ",
    value: 3,
  },
  {
    title: "OBD",
    value: 4,
  },
];

export const SelectByScopeHT = [
  {
    title: "CĐ",
    value: 3,
  },
  {
    title: "OBD",
    value: 4,
  },
];

export const SelectByScopeCD = [
  {
    title: "OBD",
    value: 4,
  },
];

export const availableDate = (start, end) => {
  const today = moment().format("YYYYMMDD");
  const avaiAt = moment(start, "DD/MM/YYYY").format("YYYYMMDD");
  const expireAt = moment(end, "DD/MM/YYYY").format("YYYYMMDD");
  if (today >= avaiAt && today <= expireAt) {
    return "available";
  } else if (today < avaiAt) {
    return "unavailable";
  } else {
    return "expired";
  }
};

export const segments = [
  "Ăn trưa với cáp trên",
  "1 Kỳ nghỉ",
  "100 OBD Coin",
  "Mất lượt",
  "Cup",
  "1500 OBD Coin",
  "2 Ngày nghỉ",
  "1 Box",
  "Mất lượt",
  "500 OBD Coin",
  "3 Box",
  "1 buổi Party",
  "Tượng vàng",
  "Huy chương",
  "Mất lượt",
  "1 Ngày nghỉ",
];

export const segColors = [
  "#009dc2",
  "#ff9200",
  "#ff4e0f",
  "#f1f1f1",
  "#7c218f",
  "#0fa3c6",
  "#ff980e",
  "#c51414",
  "#f2f2f2",
  "#ff6027",
  "#7e2490",
  "#1ea9c9",
  "#ff9a12",
  "#ff5619",
  "#f1f1f1",
  "#c00000",
];

export const WheelData = [
  {
    option: "Ăn trưa với cấp trên",
    style: {
      backgroundColor: "#009dc2",
      textColor: "white",
    },
  },
  {
    option: "1 kỳ nghỉ",
    style: { backgroundColor: "#ff9200", textColor: "white" },
  },
  {
    option: "100 OBD Coin",
    style: { backgroundColor: "#ff4e0f", textColor: "white" },
  },
  {
    option: "Mất lượt",
    style: { backgroundColor: "#f1f1f1", textColor: "#4E4E4E" },
  },
  {
    option: "Cup",
    style: { backgroundColor: "#791c8c", textColor: "white" },
  },
  {
    option: "1500 OBD Coin",
    style: { backgroundColor: "#0ba1c5", textColor: "white" },
  },
  {
    option: "2 ngày nghỉ",
    style: { backgroundColor: "#ff9910", textColor: "white" },
  },
  {
    option: "1 Box",
    style: { backgroundColor: "#c51616", textColor: "white" },
  },
  {
    option: "Mất lượt",
    style: { backgroundColor: "#f2f2f2", textColor: "#4E4E4E" },
  },
  {
    option: "500 OBD Coin",
    style: { backgroundColor: "#ff5d23", textColor: "white" },
  },
  {
    option: "3 Box",
    style: { backgroundColor: "#7f2691", textColor: "white" },
  },
  {
    option: "1 buổi Party",
    style: { backgroundColor: "#16a5c7", textColor: "white" },
  },
  {
    option: "Tượng vàng",
    style: { backgroundColor: "#ff9a12", textColor: "white" },
  },
  {
    option: "Huy chương",
    style: { backgroundColor: "#ff5518", textColor: "white" },
  },
  {
    option: "Mất lượt",
    style: { backgroundColor: "#f1f1f1", textColor: "#4E4E4E" },
  },
  {
    option: "1 Ngày nghỉ",
    style: { backgroundColor: "#c00202", textColor: "white" },
  },
];

export const getExpProgress = (t, v) => {
  const indexes = Number(t?.toString().replaceAll(".", ""));
  const nextRank = Number(v?.toString().replaceAll(".", ""));
  if (indexes && nextRank !== 0) {
    if (indexes > nextRank) {
      return 100;
    } else {
      return (indexes / nextRank) * 100;
    }
  } else {
    return 0;
  }
};
