import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import TextComponent from "components/Texts/TextComponent";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { useState } from "react";
import BtnCancel from "../../assets/images/btn-cancel.png";
import CloseIcon from "../../assets/images/btn-close.png";
import BtnExchange from "../../assets/images/inventory/btn-exchange.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncExchange,
  asyncExchangeDetail,
} from "redux/inventory/inven.action";
import { invenSelector } from "redux/inventory/inven.selector";
import {
  setMessageError,
  setOpenModalEquipmentDetail,
} from "redux/popup/popup.slice";
import { showPopup } from "redux/popup/popup.action";
import { setDetailInven, setReloadInven } from "redux/inventory/inven.slice";
import { TitleComponent } from "components/titles/TitleComponent";
import { KeyValueComponent } from "components/titles/KeyValueComponent";

export default function InvenExchangeModal({ open, onClose }) {
  const [idChooseChange, setIdChooseChange] = useState("");
  const [number, setNumber] = useState("");
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setIdChooseChange(event.target.value);
  };
  const { detailInventory } = useSelector(invenSelector);
  const [listExchange, setListExchange] = useState([]);
  const [loadding, setLoadding] = useState(false);

  useEffect(() => {
    if (open) {
      setLoadding(true);
      dispatch(asyncExchangeDetail(detailInventory?.id))
        .unwrap()
        .then((result) => {
          if (result?.length > 0) {
            setListExchange(result);
            setIdChooseChange(result[0]?.exchangePrize?.id);
          } else {
            setListExchange([]);
          }
          setTimeOutLoading();
        })
        .catch((rejectedValueOrSerializedError) => {
          setLoadding(false);
        });
    }
  }, [open]);

  const setTimeOutLoading = () => {
    const idTimeOut = setTimeout(() => {
      setLoadding(false);
    }, 300);
    return () => clearTimeout(idTimeOut);
  };

  const postExchange = () => {
    if (!number) {
      return;
    }
    if (!detailInventory?.permission) {
      dispatch(setMessageError("Bạn không có quyền quy đổi vật phẩm này"));
      dispatch(showPopup(true));
      return;
    }
    const params = {
      itemId: idChooseChange,
      quantity: number,
      inventoryId: detailInventory?.id ?? "",
    };
    dispatch(setReloadInven(false));
    dispatch(asyncExchange(params))
      .unwrap()
      .then((result) => {
        if (result) {
          dispatch(setOpenModalEquipmentDetail(false));
          onClose();
          dispatch(setMessageError("Quy đổi thành công"));
          dispatch(showPopup(true));
          dispatch(setReloadInven(true));
          dispatch(setDetailInven({}));
          setNumber("");
          setIdChooseChange("");
        }
      })
      .catch((error) => {
        dispatch(
          setMessageError(error ?? "Đã có lỗi xảy ra, vui lòng thử lại sau.")
        );
        dispatch(showPopup(true));
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setNumber("");
      }}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "white",
          background: "#302A20",
          boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
          border: "2px solid #D0A674",
          borderRadius: "6.32136px",
          boxSizing: "border-box",
          paddingTop: 2,
          paddingBottom: 2,
          outline: 0,
          maxHeight: "95%",
        }}
      >
        <Box
          sx={{ position: "absolute", top: -1, right: -1, cursor: "pointer" }}
          onClick={() => {
            onClose();
            setNumber("");
          }}
        >
          <img src={CloseIcon} alt="..." />
        </Box>
        <Grid container direction={"column"}>
          <TitleCustomComponent
            title={"QUY ĐỔI"}
            fontSize="22px"
            style={{
              textAlign: "center",
              marginBottom: "4px",
            }}
          />
          <Grid
            item
            xs
            paddingLeft={"14px"}
            paddingRight={"14px"}
            marginBottom={2}
            container
            direction={"row"}
          >
            <Grid
              xs={7}
              item
              paddingRight={2}
              justifyContent={"nomal"}
              display={"grid"}
            >
              <TitleComponent title="Thông tin" />
              <KeyValueComponent
                title={"Tên vật phẩm:"}
                value={`${detailInventory?.name ?? ""}`}
              />
              <KeyValueComponent
                title={"Tổng số lượng:"}
                value={`${detailInventory?.quantity ?? 0}`}
              />
              <KeyValueComponent
                title={"Được sử dụng:"}
                value={`${detailInventory?.quantityExchange ?? 0}`}
              />
              <KeyValueComponent
                title={"Chờ sử dụng:"}
                value={`${detailInventory?.quantityRequest ?? 0}`}
              />
              <KeyValueComponent
                title={"Sắp hết hạn:"}
                value={`${detailInventory?.quantityExchangeExpired ?? 0}`}
              />
              <KeyValueComponent
                title={"Ngày bắt đầu:"}
                value={`${detailInventory?.exchangeStartAt ?? ""}`}
              />
              <KeyValueComponent
                title={"Hạn sử dụng:"}
                value={`${detailInventory?.exchangeExpiredAt ?? ""}`}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextComponent
                  content={"Tỷ lệ:"}
                  styleText={{
                    fontWeight: 500,
                    fontFamily: "Be-Vietnam-Pro",
                    color: "#9C7F5C",
                    fontSize: 16,
                    marginRight: 2,
                  }}
                  maxLine="2"
                />
                <Box
                  sx={{
                    maxHeight: "70px",
                    overflow: "scroll",
                    marginTop: "6px",
                    textAlign: "right",
                  }}
                >
                  {listExchange?.map((item, index) => (
                    <TextComponent
                      content={`${item?.value + " " + item?.prize?.name} = ${
                        item?.exchangeValue + " " + item?.exchangePrize?.name
                      }`}
                      styleText={{
                        fontSize: "12px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        color: "#DBB487",
                        letterSpacing: "1.1px",
                        lineHeight: "15px",
                      }}
                      maxLine="2"
                      key={index}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid xs={5} item>
              {listExchange?.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      alignContent: "space-between",
                      display: "grid",
                      marginLeft: 1,
                      width: "100%",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextComponent
                        content="Số lượng"
                        styleText={{
                          fontSize: "14px",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          color: "#C9C9C9",
                          letterSpacing: "1px",
                          lineHeight: "17.5px",
                          marginBottom: "2px",
                        }}
                      />
                      <TextField
                        size="small"
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        placeholder="Nhập số lượng"
                        id="mui-theme-provider-outlined-input"
                        sx={{
                          ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root":
                            {
                              color: "white",
                              fontSize: "15px",
                              fontFamily: "sans-serif",
                              fontWeight: "400",
                              letterSpacing: "2px",
                              lineHeight: "17.5px",
                            },
                          "& .MuiOutlinedInput-root": {
                            color: "white",
                            "& fieldset": {
                              borderColor: "#D6B88A",
                              borderRadius: "8px",
                            },
                            "&:hover fieldset": {
                              borderColor: "#D6B88A",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#D6B88A",
                            },
                          },
                        }}
                        value={number}
                        onChange={(e) =>
                          setNumber(e.target.value.replace(/\D/g, ""))
                        }
                      />
                      <TextComponent
                        content="Lựa chọn"
                        styleText={{
                          fontSize: "14px",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          color: "#C9C9C9",
                          letterSpacing: "1px",
                          lineHeight: "17.5px",
                          marginTop: "8px",
                        }}
                      />
                      <Select
                        value={idChooseChange}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          height: "40px",
                          fontSize: 14,
                          fontWeight: 500,
                          fontFamily: "Be Vietnam Pro",
                          color: "white",
                          bgcolor: "#493E2D",
                          borderRadius: "5px",
                          border: "1px solid #D6B88A",
                          boxShadow: "none",
                          paddingLeft: "5px",
                          "& .MuiSvgIcon-root": {
                            color: "#FFF0B1",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor: "#493E2D",
                              color: "blue",
                              "& .MuiMenuItem-root": {
                                fontSize: "14px",
                                fontWeight: 500,
                                fontFamily: "Be Vietnam Pro",
                                color: "white",
                              },
                            },
                          },
                        }}
                        variant="standard"
                        disableUnderline
                      >
                        {listExchange?.map((item, index) => (
                          <MenuItem key={index} value={item?.exchangePrize?.id}>
                            {item?.exchangePrize?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box
                      sx={{
                        paddingTop: "12px",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        onClick={() => {
                          postExchange();
                        }}
                        sx={{
                          cursor: number ? "pointer" : "auto",
                          opacity: number ? 1 : 0.5,
                        }}
                      >
                        <img src={BtnExchange} alt="..." />
                      </Box>

                      <Box sx={{ width: "16px" }}></Box>
                      <div onClick={onClose} style={{ cursor: "pointer" }}>
                        <img src={BtnCancel} alt="..." />
                      </div>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <TextComponent
                  content={`Không có vật phẩm khả dụng để quy đổi`}
                  styleText={{
                    fontSize: "12px",
                    fontFamily: "sans-serif",
                    fontWeight: "400",
                    color: "#fbeea3",
                    letterSpacing: "1.1px",
                    lineHeight: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    display: "grid",
                  }}
                  maxLine="2"
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs sx={{ borderTop: "1px solid #E9C896" }}>
            <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <Box sx={{ height: "8px" }}></Box>
              <TextComponent
                content="Vật phẩm sau khi quy đổi sẽ mất trong dương đồ của bạn, không lấy lại được. lưu ý khi quy đổi."
                styleText={{
                  fontSize: "12px",
                  fontFamily: "sans-serif",
                  fontWeight: "400",
                  color: "#81838E",
                  letterSpacing: "1.1px",
                  lineHeight: "15px",
                  fontStyle: "Italic",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
