import { Box, Grid, Modal } from "@mui/material";
import React from "react";
import BgItemGold from "../../assets/images/bg-item-gold.png";
import TuongVang from "../../assets/images/tuong-vang.png";
import BoxQua7 from "../../assets/images/box-qua-7.png";
import TextComponent from "components/Texts/TextComponent";
import BtnAwarded from "../../assets/images/btn-trao-tang.png";
import BtnExchange from "../../assets/images/btn-quy-doi.png";
import BtnEquipment from "../../assets/images/btn-equipment.png";
import BtnBackHome from "../../assets/images/btn-back-home.png";
import TitleCustomComponent from "components/Texts/TitleCustomComponent";
import { useSelector } from "react-redux";
import { infoGiftBoxSelector } from "redux/popup/popup.selector";

export default function GiftBoxModal(props) {
  const { open, onClose, onClickbackHome, onClickEquipment } = props;
  const infoGiftBox = useSelector(infoGiftBoxSelector);

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        disableAutoFocus={true}
        disableEscapeKeyDown={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            background: "#2C271D",
            boxShadow: "0px 0px 15.8034px rgba(0, 0, 0, 0.45)",
            border: "2px solid #D0A674",
            borderRadius: "6.32136px",
            boxSizing: "border-box",
            paddingTop: 2,
            paddingBottom: 2,
            outline: 0,
            height: 360,
            maxHeight: "95%",
          }}
        >
          <Grid container direction={"column"}>
            <Grid item xs>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={BgItemGold}
                  alt='...'
                  style={{ opacity: 0.6, mixBlendMode: "screen" }}
                />
                <img
                  src={`${infoGiftBox?.image ?? ""}`}
                  alt='...'
                  style={{
                    position: "absolute",
                    objectFit: "contain",
                    maxHeight: "175px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "90px",
                  }}
                >
                  <TextComponent
                    content={`Bạn vừa nhận được`}
                    styleText={{
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  />
                  <TitleCustomComponent
                    title={`${infoGiftBox?.quantity ?? ""} ${
                      infoGiftBox?.name ?? ""
                    }`}
                    fontSize='22px'
                    style={{ textAlign: "center" }}
                  />
                </Box>
                <Box
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "12px",
                  }}
                >
                  <div onClick={onClickbackHome}>
                    <img
                      src={BtnBackHome}
                      alt='...'
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Box sx={{ width: "42px" }}></Box>
                  <div onClick={onClickEquipment}>
                    <img
                      src={BtnEquipment}
                      alt='...'
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
