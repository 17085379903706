// @ts-nocheck
import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import { colors } from "../config/colors/colors";
import { hoverBg } from "../config/styles/common";
import BorderImage1 from "../assets/images/group-icon.png";
import Line1 from "../assets/images/line-1.png";
import Line2 from "../assets/images/line-2.png";

function BoxWrapperComponent({
  children,
  headerIcon,
  headerTitle,
  borderImageBox,
  headerBtnRight = null,
  boxStyle = {},
  bottomLeftBtn,
  bottomRightBtn,
  styleHoverBg = {},
  background = "",
  isArena,
  arenaInfo,
  onClickArena,
  jointBtn,
  onClickJoin,
  htmlRef,
}) {
  const checkBackground = () => {
    return background
      ? {
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          "&:hover": {
            boxSizing: "border-box",
            boxShadow: `0px 0px 28px rgba(255, 6, 6, 0.65)`,
            // borderRadius: "10px",
          },
        }
      : hoverBg;
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          // width: {
          // xs: 100, // theme.breakpoints.up('xs')
          // sm: 200, // theme.breakpoints.up('sm')
          // md: 300, // theme.breakpoints.up('md')
          // lg: 400, // theme.breakpoints.up('lg')
          // xl: 500, // theme.breakpoints.up('xl')
          // },
          minWidth: "250px",
          minHeight: "605px",
          border: "0.1px solid transparent",
          bgcolor: colors.bg_box,
          borderImage: `url(${borderImageBox}) 70 stretch`,
          backgroundSize: "auto",
          boxSizing: "border-box",
          borderImageSlice: "21 22",
          borderImageWidth: "19px",
          borderImageOutset: "12px",
          borderStyle: "solid",
          position: "relative",
          borderRadius: "4px",
          //for avatar only
          overflow: isArena ? "visible" : "hidden",
          zIndex: 1,
          ...checkBackground(),
          ...styleHoverBg,
          ...boxStyle,
        }}
        ref={htmlRef}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: isArena ? "center" : "space-between",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: headerIcon || headerTitle ? "unset" : "10px",
              maxHeight: "100px",
            }}
          >
            {headerIcon}
            {headerTitle}
          </Box>
          {headerBtnRight}
          {isArena ? (
            <Box
              sx={{
                position: "absolute",
                right: "0px",
                alignSelf: "flex-start",
              }}
            >
              <div onClick={onClickArena}>{arenaInfo}</div>
            </Box>
          ) : null}
        </Box>
        {children}

        <Box
          className="download-avatar"
          sx={{
            width: "95%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "absolute",
            bottom: "5px",
            marginLeft: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {bottomLeftBtn}
          </Box>
          {bottomRightBtn}
        </Box>
        {jointBtn ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              bgcolor: "#564E3E",
              height: "70px",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              bottom: "0px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Be Vietnam Pro",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                color: "white",
              }}
            >
              Bạn chưa tham gia sự kiện, tham gia ngay để nhận quà lớn
            </Typography>
            <div
              onClick={() => {
                if (onClickJoin) {
                  onClickJoin();
                }
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "7px 17px",
                  height: "36px",
                  background:
                    "radial-gradient(50% 50% at 50% 50%, #EB4C4C 0%, #AD0606 100%)",
                  boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.35)",
                  borderRadius: "9px",
                  fontFamily: "SVN-Gotham",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "14px",
                  textAlign: "right",
                  color: "#FFEEA3",
                  border: "1px solid #AC4120",
                  textShadow: "0px 2px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                Tham gia ngay
              </Typography>
            </div>
          </Box>
        ) : null}
      </Box>

      <img
        src={Line1}
        alt="..."
        style={{
          position: "absolute",
          marginTop: -20,
          right: "30%",
          top: 0,
        }}
      />
      <img
        src={Line2}
        alt="..."
        style={{
          position: "absolute",
          marginTop: -20,
          right: "5%",
          top: 0,
        }}
      />
    </Box>
  );
}

export default BoxWrapperComponent;
