import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  detailEvent,
  isNewestNotification,
  isReadNotification,
  joinArena,
  leaderboardsEvent,
  listEvents,
  listNotification,
  readAllNotification,
  participantsEvent,
} from "api/event";
import { setListNotification } from "./events.slice";
import { getErrorMessage } from "api";
import { setMessageError } from "redux/popup/popup.slice";
import { showPopup } from "redux/popup/popup.action";

export const getListEvents = createAsyncThunk("event/list", async (params) => {
  const response = await listEvents(params);
  if (response?.data?.success) {
    return response?.data?.data;
  }

  return [];
});

export const getDetailEvents = createAsyncThunk("event/detail", async (id) => {
  const response = await detailEvent(id);
  if (response?.data?.success) {
    return response?.data?.data;
  }
  return null;
});

export const getParticipantsEvents = createAsyncThunk(
  "event/participants",
  async (paramsSearch) => {
    const { params, id } = paramsSearch;
    const response = await participantsEvent(params, id);
    if (response?.data?.success) {
      return response?.data?.data;
    }
    return [];
  }
);

export const getLeaderboardsEvents = createAsyncThunk(
  "event/leaderboards",
  async (paramsSearch) => {
    const { params, id } = paramsSearch;
    const response = await leaderboardsEvent(params, id);
    if (response?.data?.success) {
      return response?.data?.data;
    }
    return [];
  }
);

export const asyncReadNotification = createAsyncThunk(
  "notification/read",
  async (id) => {
    const response = await isReadNotification(id);
    if (response?.data?.success) {
      return true;
    }
    return null;
  }
);

export const asyncNewestNotification = createAsyncThunk(
  "notification/newest",
  async (id) => {
    const response = await isNewestNotification();
    if (response?.data?.success) {
      return response?.data?.data;
    }
    return null;
  }
);

export const getListNotification = createAsyncThunk(
  "notifications",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await listNotification({ page });
      const { data, success } = response?.data;
      if (success) {
        dispatch(setListNotification(data));
        return data;
      }
      return false;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const asyncJoinArena = createAsyncThunk(
  "event/join",
  async (id, { dispatch, rejectWithValue }) => {
    const response = await joinArena(id);
    if (response?.data?.success) {
      return response?.data?.success;
    } else {
      dispatch(
        setMessageError(
          response?.data?.message ?? "Đã có lỗi xảy ra vui lòng thử lại sau."
        )
      );
      dispatch(showPopup(true));
    }
    return null;
  }
);

export const setReadAllNotification = createAsyncThunk(
  "notifications/readAll",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await readAllNotification({});
      const { data, success } = response?.data;
      if (success) {
        return data;
      }
      return false;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
