import React from "react";
import { Box, Typography } from "@mui/material";

function ButtonGradientCustom({
  lable = "",
  gradient = "",
  border = "",
  textStrokeColor = "",
  paddingTop = 0 || "0px",
  onClick = () => {},
  styleBox = {},
  styleText = {},
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundImage: gradient,
        boxShadow: `0px 2px 0px rgba(0, 0, 0, 0.35)`,
        borderRadius: "8px",
        border: border,
        borderImageSlice: 2,
        gap: 1,
        margin: `8px`,
        padding: "8px 8px",
        alignSelf: "center",
        cursor: "pointer",
        ...styleBox,
      }}
    >
      <Typography
        sx={{
          paddingTop: paddingTop,
          fontWeight: 700,
          fontFamily: "SVN-Gotham",
          color: `#FFEEA3`,
          fontSize: "14px",
          textShadow: `0px 1px 0px rgba(0, 0, 0, 0.25)`,
          WebkitTextStroke: "1px transparent",
          WebkitTextStrokeColor: textStrokeColor,
          letterSpacing: "0.07em",
          textAlign: "center",
          lineHeight: "1.2em",
          textRendering: "optimizeLegibility",
          ...styleText,
        }}
      >
        {lable}
      </Typography>
    </Box>
  );
}

export default ButtonGradientCustom;
