export const userSelector = (state) => state.user.user;

export const loadingSelector = (state) => state.user.loading;

export const errorSelector = (state) => state.user.error;

export const listRankUserSelector = (state) => state.user.listRankUsers;

export const indexInfoRankUserSelector = (state) =>
  state.user.indexInfoRankUser;

export const rankCurrentSelector = (state) => state.user.rankCurrent;

export const userDataSelector = (state) => state.user;

export const compareToFirstInfoUserSelector = (state) =>
  state.user.compareToFirstInfoUser;

export const configIndexsSelector = (state) => state.user.configIndexs;
