export const LOCALE_KEY = {
  token: "token",
};

export function getStorageValue(key) {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : "";
    return initial;
  }

  return "";
}

export function setStorageValue(key, value) {
   localStorage.setItem(key, JSON.stringify(value));
}

