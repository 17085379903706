import { getAxiosClientWithToken } from "api";

export const listAttendances = () => {
  return getAxiosClientWithToken().get(`/attendances`);
};

export const attendances = () => {
  return getAxiosClientWithToken().post(`/attendances/attendance`);
};

