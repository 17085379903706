import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { Box, ThemeProvider, Container } from "@mui/material";
import NavBar from "./components/NavBar";
import AppRoutes from "./routes";
import { ThemeDispatchProvider, useThemeContext } from "./contexts/theme";
import "./contexts/i18n";
import "./App.css";
import IconTopLeft from "./assets/images/icon-top-left.svg";
import IconTopRight from "./assets/images/icon-top-right.svg";
import IconTopCenter from "./assets/images/icon-top-center.svg";
import LogoObd from "./assets/images/logo-obd.svg";
import TextComponent from "./components/Texts/TextComponent";

function App() {
  const [themeContext, themeDispatch] = useThemeContext();

  return (
    <Provider store={store}>
      <ThemeProvider theme={themeContext}>
        <ThemeDispatchProvider value={themeDispatch}>
          <Router>
            <Box
              className={`full-viewport`}
              sx={{
                border: "1px solid #FFA200",
                bgcolor: "#1E1E1E",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  height: "15px",
                }}
              >
                <img src={IconTopLeft} alt="..." />
                <img src={IconTopCenter} alt="..." />
                <img src={IconTopRight} alt="..." />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: 5,
                  zIndex: 0,
                }}
              >
                <img src={LogoObd} alt="..." />
              </Box>
              <NavBar />
              <AppRoutes />
              <TextComponent
                content={"Copyright © 2023 One Big Dream"}
                styleText={{
                  fontWeight: 300,
                  fontSize: "14px",
                  color: "#9A9A9A",
                  fontFamily: "sans-serif",
                  paddingBottom: "24px",
                  textAlign: "center",
                  marginTop: "16px",
                }}
              />
            </Box>
          </Router>
        </ThemeDispatchProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
